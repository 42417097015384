import React, {Children} from 'react';
import {Typography} from "@mui/material";
import {purple} from "@mui/material/colors";


const Heading2 = ({children}) => {
    return (
        <Typography variant={'h5'} component={'h4'} sx={{paddingY: 2}} color={purple[400]}>
            {children}
        </Typography>
    );
};

export default Heading2;