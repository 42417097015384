export const REQUEST_EVENTS_SUCCESS = "REQUEST_EVENTS_SUCCESS";
export const REQUEST_EVENTS_ERROR = "REQUEST_EVENTS_ERROR";
export const REQUEST_EVENTS_STARTED = "REQUEST_EVENTS_STARTED";
export const RESET_STATE_EVENTS = "RESET_STATE_EVENTS";
export const REQUEST_PATCH_EVENT_SUCCESS = "REQUEST_PATCH_EVENT_SUCCESS";
export const REQUEST_NEW_EVENT_SUCCESS = "REQUEST_NEW_EVENT_SUCCESS";
export const REQUEST_DELETE_EVENT_SUCCESS = "REQUEST_DELETE_EVENT_SUCCESS";
export const REQUEST_VALIDATE_EVENT_SUCCESS = "REQUEST_VALIDATE_EVENT_SUCCESS";
export const REQUEST_INVALIDATE_EVENT_SUCCESS = "REQUEST_INVALIDATE_EVENT_SUCCESS";
export const REQUEST_EVENTS_TO_CUSTOMER_SUCCESS = "REQUEST_EVENTS_TO_CUSTOMER_SUCCESS"
export const RESET_STATE_EVENTS_CUSTOMER = "RESET_STATE_EVENTS_CUSTOMER"