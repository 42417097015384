import React from 'react';
import {Box, Card, CardContent, CardHeader, Grid, Typography} from "@mui/material";
import {blueGrey} from "@mui/material/colors";
import H2 from "../../Components/Typographies/H2";

function MyCommitmentsCard({data}) {
    const style = {
        maxWidth: 350
    }

    return (
        <Grid item>
            <Card sx={style}>
                <CardContent>
                    <CardHeader title={<H2 color={'blueGrey.dark'}>{data.title}</H2>} align={'center'} sx={{height: 100}}/>
                    <Box paddingY={2} minHeight={300}>
                        {data.content.map((txt, index) =>
                            <Typography align={'justify'}
                                key={index}
                                color={blueGrey[500]}
                            >
                                {txt}
                            </Typography>
                        )}
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    );
}

export default MyCommitmentsCard;
