import React, {useEffect, useState} from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, {Draggable} from "@fullcalendar/interaction";
import {useDispatch, useSelector} from "react-redux";
import {apiGetCustomer} from "../../../redux/customers/customersAction";
import {
    deleteEvent,
    getAdminEvents,
    invalidateEvent,
    newEvent,
    patchEvent,
    validateEvent
} from "../../../redux/events/eventsActions";
import frLocale from '@fullcalendar/core/locales/fr';

import useModal from "../../../Hooks/useModal/useModal";
import Modal from "../../../components/Modal/ModalComponent";
import "./Calendar.scss"
import {
    Box,
    Button,
    FormControl,
    IconButton, InputLabel,
    MenuItem,
    Select,
    Stack,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import NavBar from "../../../components/NavBar/NavBar";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import useDateTime from "../../../Hooks/useDateTime/useTime";
import Loading from "../../../components/Loading/Loading";
import LoadingError from "../../../components/Loading/LoadingError";
import FormNewCustomer from "../Customers/FormCustomers/FormNewCustomer";
import useAuth from "../../../Hooks/useAuth/useAuth";
import {RepeatOutlined} from "@mui/icons-material";
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import EventsService from "../../../services/events.service";

const Calendar = (props) => {

    const dispatch = useDispatch();
    const {decimalTimeToHours, toMonthName} = useDateTime()
    const customersData = useSelector((state) => state.customersReducer)
    // const eventsData = useSelector((state) => state.eventsReducer);
    const admin = useSelector((state) => state.auth).user.user.id;
    const {authId} = useAuth()
    const [userSelected, setUserSelected] = useState(null);
    const [eventSelected, setEventSelected] = useState(null);
    const [selectedModal, setSelectedModal] = useState("")
    const {isOpen, toggle} = useModal();
    const [searchCustomer, setSearchCustomer] = useState("");
    const [events, setEvents] = useState({start: null, end: null, events: []});
    const {editable, user, defaulView, phoneMode} = props;


    useEffect(() => {

        customersData.customers.length === 0 && dispatch(apiGetCustomer())
        // eventsData.events.length === 0 && dispatch(getAdminEvents(admin));

        if (customersData.customers.length > 0) {
            const userListContainer = document.getElementById('external-events');
            let draggable = new Draggable(userListContainer, {
                itemSelector: '.fc-event',
                eventData: function (eventEl) {

                    const data = {
                        title: eventEl.innerText,
                        color: eventEl.dataset.color,
                        create: true,
                        extendedProps: {
                            colorId: eventEl.dataset.colorId,
                            user: eventEl.dataset.id,
                        },
                        duration: {
                            hours: 1,
                        },
                        classNames: "event_isValidated"
                    };
                    return data
                }
            });

            return () => draggable.destroy();
        }

    }, [customersData.customers]);


    const handleNewEvent = async (info) => {
        console.log(info.event.start);
        console.log(info)
        dispatch(newEvent({
            start: new Date(info.event.start),
            end: new Date(info.event.end),
            user: '/api/users/' + info.event._def.extendedProps.user,
            color: info.event._def.extendedProps.colorId,
            admin: '/api/users/' + admin,
            address: '/api/addresses/' + customersData.customers.find((customer) => customer.id === parseInt(info.event._def.extendedProps.user)).profile.addresses.find((address) => address.isDefault === true)['id'],
            isValidated: false
        }, info ))
        const calendarApi = info.view.calendar;
        // const response = await EventsService.addEvent({
        //     start: new Date(info.event.start),
        //     end: new Date(info.event.end),
        //     user: '/api/users/' + info.event._def.extendedProps.user,
        //     color: info.event._def.extendedProps.colorId,
        //     admin: '/api/users/' + admin,
        //     address: '/api/addresses/' + customersData.customers.find((customer) => customer.id === parseInt(info.event._def.extendedProps.user)).profile.addresses.find((address) => address.isDefault === true)['id'],
        //     isValidated: false
        // })


        // dispatch(newEvent({}, info, response));
    }

    const handlePatchEvent = async (info) => {
        dispatch(patchEvent(info.event._def.publicId, {
            start: info.event.start,
            end: info.event.end,
        }));
    }

    const handleDeleteEvent = async () => {
        dispatch(deleteEvent(eventSelected.eventData.id));
        eventSelected.event.remove();
        eventSelected.calendarApi.refetchEvents();
        toggle();
        setEventSelected(null);
    }

    const handleValidEvent = async (e) => {
        if (e.target.checked) {
            dispatch(validateEvent(eventSelected.eventData.id));
            eventSelected.event.remove();
        } else if (!e.target.checked) {
            dispatch(invalidateEvent(eventSelected.eventData.id));
            eventSelected.event.remove();
        }
        toggle();
        setEventSelected(null);
    }

    const eventsToDisplay = (events) => {
        if (events.length === 0) {
            return [];
        }

        if (userSelected === null) {
            return events.filter(event => event.user.enabled === true).map(event => {
                if (!event.isValidated) {
                    return {
                        ...event,
                        className: "event_isValidated"
                    }
                }
                return event
            });
        }

        return events.filter((event) => event.user['@id'] === `/api/users/${userSelected}`).map(event => {
            if (!event.isValidated) {
                return {
                    ...event,
                    className: "event_isValidated"
                }
            }
            return event
        });
    }

    const [valueDatePicker, setValueDatePicker] = useState(React.useState(dayjs.now));

    const handleDatePickerChange = (newValue) => {
        setValueDatePicker(newValue['$d']);
    };


    const [repeatMode, setRepeatMode] = useState('weekly');
    const handleRepeatModeChange = (event) => {
        setRepeatMode(event.target.value);
    };

    function handleRepeatEvent() {
        // let repeatEnd = new Date('2023-01-07T00:00:00');
        let repeatEnd = new Date(valueDatePicker);
        let repeatStart = new Date(eventSelected.eventData.start.split('+')[0]);
        // repeat modes : daily, weekly, 2-weekly , monthly, yearly
        // let reapeatMode = 'weekly';

        let events = [];
        const growDate = (date, mode) => {
            switch (mode) {
                case 'daily':
                    return new Date(date.setDate(date.getDate() + 1));
                case 'weekly':
                    return new Date(date.setDate(date.getDate() + 7));
                case '2-weekly':
                    return new Date(date.setDate(date.getDate() + 14));
                case 'monthly':
                    return new Date(date.setMonth(date.getMonth() + 1));
                case 'yearly':
                    return new Date(date.setFullYear(date.getFullYear() + 1));
            }
        }
        repeatStart = growDate(repeatStart, repeatMode);
        while (repeatStart < repeatEnd) {
            let start = new Date(repeatStart);
            let end = new Date(eventSelected.eventData.end.split('+')[0]);
            console.log(end)
            let finalEnd = new Date(start);
            finalEnd.setHours(end.getHours());
            finalEnd.setMinutes(end.getMinutes());
            finalEnd.setSeconds(end.getSeconds());

            events.push({
                start: start,
                end: finalEnd,
                isValidated: false,
                user: eventSelected.eventData.user['@id'],
                color: customersData.customers.find((customer) => customer.id === parseInt(eventSelected.eventData.user['@id'].split('/')[3])).profile.color['@id'],
                admin: '/api/users/' + admin,
                address: '/api/addresses/' + eventSelected.eventData.address.id,

            });
            repeatStart = growDate(repeatStart, repeatMode);
        }

        for (let event of events) {
            dispatch(newEvent(event));
        }
    }

    // console.log(customersData)
    // console.log(eventSelected)
    return (
        <>
            {customersData.isLoading ? (
                <Loading/>
            ) : customersData.error ? (
                <LoadingError error={customersData.error} txt={"Section Planning"}/>
            ) : customersData.customers && (
                <>
                    <NavBar index={2}/>
                    {customersData.customers.length > 0 && (
                        <Stack component={"main"} direction={"row"} spacing={2} sx={{marginTop: 10, marginX: 2}}>
                            <Stack id={"external-events"} spacing={1}>
                                <Stack direction={"row"} spacing={1}>
                                    {/*search customer*/}
                                    <TextField
                                        id="search-customer"
                                        label="Rechercher un client"
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => setSearchCustomer(e.target.value)}
                                    />
                                </Stack>
                                <Typography variant={"title2"} align={"center"}>Draggable Events</Typography>
                                <Stack spacing={1}>
                                    {customersData.customers.map((customer) => {
                                        if (!customer.enabled) return null;
                                        if (searchCustomer !== "" && !customer.profile.firstname.toLowerCase().includes(searchCustomer.toLowerCase()) && !customer.profile.lastname.toLowerCase().includes(searchCustomer.toLowerCase())) return null;
                                        return (
                                            <Button variant={"contained"}
                                                    sx={{
                                                        backgroundColor: customer.profile.color.color,
                                                        border: `1px solid ${customer.profile.color.color}`,
                                                        cursor: 'pointer',
                                                        fontSize: 11,
                                                        ':hover': {
                                                            bgcolor: customer.profile.color.color,
                                                            border: `1px solid white`
                                                        },
                                                    }}
                                                    className={"fc-event fc-daygrid-event fc-daygrid-block-event"}
                                                    title={customer.profile.lastname + " " + customer.profile.firstname}
                                                    data-id={customer.id}
                                                    data-color={customer.profile.color.color}
                                                    data-color-id={customer.profile.color['@id']}
                                                    key={customer.id}
                                                    onClick={(e) => {
                                                        if (e.target.dataset.id === userSelected) {
                                                            setUserSelected(null);
                                                        } else {
                                                            setUserSelected(e.target.dataset.id)
                                                        }
                                                    }}
                                            >{customer.profile.lastname + " " + customer.profile.firstname}</Button>
                                        )
                                    })}
                                </Stack>

                            </Stack>
                            <Box sx={{width: '100%'}}>
                                <FullCalendar
                                    initialView="timeGridWeek"
                                    headerToolbar={{
                                        left: 'prev,next today',
                                        center: 'title',
                                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                                    }}
                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                    locale={frLocale}
                                    allDaySlot={false}
                                    slotMinTime={"06:00:00"}
                                    slotMaxTime={"20:00:00"}
                                    slotDuration={"00:15:00"}

                                    droppable={true}
                                    editable={true}
                                    eventDurationEditable={true}
                                    selectable={false}
                                    selectMirror={true}
                                    dayMaxEvents={true}
                                    weekends={false}
                                    height={"90vh"}
                                    aspectRatio={"0.8"}
                                    handleWindowResize={true}
                                    lazyFetching={true}


                                    select={(info) => {
                                    }}

                                    eventClick={(info) => {
                                        setEventSelected({
                                            eventData: events.events.find((event) => event.id === parseInt(info.event._def.publicId)),
                                            event: info.event,
                                            calendarApi: info.view.calendar
                                        });
                                        // console.log(eventSelected);
                                        let year = new Date(events.events.find((event) => event.id === parseInt(info.event._def.publicId)).start).getFullYear()
                                        let month = new Date(events.events.find((event) => event.id === parseInt(info.event._def.publicId)).start).getMonth()
                                        let nbDays = new Date(year, month + 1, 0).getDate()
                                        let endRepeat = new Date(year, month, nbDays, 23, 59)
                                        setValueDatePicker(endRepeat)
                                        setRepeatMode('weekly')
                                        toggle();
                                    }}
                                    events={async (info, successCallback, failureCallback) => {
                                        // console.log(info);
                                        if(events.start != info.start && events.end != info.end) {
                                            events.start = info.start;
                                            events.end = info.end;
                                            try {
                                                let ev = await EventsService.getEventsBetweenDates(admin, info.start, info.end)
                                                const eventsArray = [];
                                                ev.data['hydra:member'].forEach(event => {
                                                    const res = {
                                                        id: event.id,
                                                        title: event.user.profile.lastname + ' ' + event.user.profile.firstname,
                                                        start: event.start,
                                                        end: event.end,
                                                        color: event.color.color,
                                                        user: event.user,
                                                        address: event.address,
                                                        isValidated: event.isValidated,
                                                    }
                                                    eventsArray.push(res);

                                                });
                                                events.events = eventsArray;
                                                // console.log(eventsArray);
                                                return successCallback(eventsToDisplay(eventsArray));
                                            } catch (e) {
                                                return failureCallback(e);
                                            }
                                        } else {
                                            return successCallback(eventsToDisplay(events.events));
                                        }

                                    }}
                                    eventReceive={async (info) => {
                                        await handleNewEvent(info)
                                    }}
                                    eventChange={async (info) => {
                                        await handlePatchEvent(info);
                                    }}

                                />
                            </Box>
                            <Modal isOpen={isOpen} hide={toggle} header={
                                <Stack>
                                    <Stack direction={'row'} spacing={3}>
                                        <Typography>
                                            {eventSelected !== null && new Date(eventSelected.eventData.start).getDate()}-{toMonthName(eventSelected !== null && new Date(eventSelected.eventData.start).getMonth() + 1)}-{eventSelected !== null && new Date(eventSelected.eventData.start).getFullYear()}
                                        </Typography>
                                        <Typography>
                                            {eventSelected !== null && new Date(eventSelected.eventData.start.split('+')[0]).getHours()}h{eventSelected !== null && (new Date(eventSelected.eventData.start.split('+')[0]).getMinutes() <= 9 ? '0' + new Date(eventSelected.eventData.start.split('+')[0]).getMinutes() : new Date(eventSelected.eventData.start.split('+')[0]).getMinutes())}-
                                            {eventSelected !== null && new Date(eventSelected.eventData.end.split('+')[0]).getHours()}h{eventSelected !== null && (new Date(eventSelected.eventData.end.split('+')[0]).getMinutes() <= 9 ? '0' + new Date(eventSelected.eventData.end.split('+')[0]).getMinutes() : new Date(eventSelected.eventData.end.split('+')[0]).getMinutes())}
                                        </Typography>
                                        <span>
                                            {eventSelected !== null && eventSelected.title}
                                        </span>
                                    </Stack>
                                    <Stack direction={'row'} spacing={1}>
                                        {/*<LocationOnOutlinedIcon color="secondary"/>*/}
                                        <Stack direction={'row'} spacing={1}>
                                            <Typography className={"mr-2"}>
                                                {eventSelected !== null && eventSelected.eventData.address !== undefined && eventSelected.eventData.address.address}
                                            </Typography>
                                            <Typography className={"mr-2"}>
                                                {eventSelected !== null && eventSelected.eventData.address !== undefined && eventSelected.eventData.address.zip}
                                            </Typography>
                                            <Typography className={"mr-2"}>
                                                {eventSelected !== null && eventSelected.eventData.address !== undefined && eventSelected.eventData.address.city}
                                            </Typography>
                                            <Typography className={"mr-2"}>
                                                {eventSelected !== null && eventSelected.eventData.address !== undefined && eventSelected.eventData.address.country}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>

                            }>
                                <Stack spacing={3}>
                                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                        {eventSelected && eventSelected.eventData.isValidated ? (
                                            <>
                                                <Switch defaultChecked={true} color={"success"}
                                                        onChange={handleValidEvent}/>
                                                <Typography>Activité validée</Typography>
                                            </>
                                        ) : (
                                            <>
                                                <Switch defaultChecked={false} color={"warning"}
                                                        onChange={handleValidEvent}/>
                                                <Typography>Activité à valider</Typography>
                                            </>
                                        )}
                                    </Stack>

                                    <Box maxWidth={300}>
                                        <Stack spacing={2}>
                                            <Typography>Répéter</Typography>
                                            <FormControl>
                                                <Select
                                                    value={repeatMode}
                                                    onChange={handleRepeatModeChange}
                                                >
                                                    <MenuItem value={'weekly'}>Toutes les semaines</MenuItem>
                                                    <MenuItem value={'2-weekly'}>Toutes les deux semaines</MenuItem>
                                                    <MenuItem value={'daily'}>Tous les jours</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <Typography>Jusqu'au</Typography>
                                            <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                                <DesktopDatePicker
                                                    inputFormat="DD/MM/YYYY"
                                                    value={valueDatePicker}
                                                    onChange={handleDatePickerChange}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                                <IconButton sx={{padding: 0, height: 40, width: 40}} aria-label="repeat"
                                                            color="secondary"
                                                            onClick={() => handleRepeatEvent()}>
                                                    <RepeatOutlined/>
                                                </IconButton>
                                            </Stack>
                                        </Stack>
                                    </Box>

                                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                        <IconButton sx={{padding: 0}} aria-label="delete" color="secondary"
                                                    onClick={() => handleDeleteEvent()}>
                                            <DeleteForeverOutlinedIcon/>
                                        </IconButton>
                                        <Typography>Supprimer</Typography>
                                    </Stack>
                                </Stack>
                            </Modal>
                        </Stack>
                    )}
                </>
            )}
        </>
    )
};

export default Calendar;
