import React from 'react';
import {Box} from "@mui/material";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Main from "../Main/Main";

const TemplatePage = ({children}) => {
    return (
        <Box component={'div'}>
            <Header/>
            <Main>
                {children}
            </Main>
            <Footer/>
        </Box>
    );
};

export default TemplatePage;