import React from 'react';
import {Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Grid, Typography} from "@mui/material";
import {blueGrey} from "@mui/material/colors";
import {Link} from "react-router-dom";
import H2 from "../../Components/Typographies/H2";

function MyServicesCard({data}) {
    const style = {
        maxWidth: 350
    }
    return (
        <Grid item>
            <Card sx={style}>
                <CardMedia
                    component="img"
                    image={data.img}
                    alt="image"
                />
                <CardContent>
                    <CardHeader title={<H2 color={'blueGrey.dark'}>{data.title}</H2>} align={'center'} sx={{height: 100}}/>
                    <Box height={100}>
                        {data.content.map((txt, index) =>
                            <Typography
                                key={index}
                                color={blueGrey[500]}
                            >
                                {txt}
                            </Typography>
                        )}
                    </Box>
                </CardContent>
                <CardActions sx={{paddingY: 2, display: 'flex', justifyContent: 'center'}}>
                    <Link to={data.link}>
                        <Button variant={"empty"} color={'purple'}>En savoir plus</Button>
                    </Link>
                </CardActions>
            </Card>
        </Grid>
    );
}

export default MyServicesCard;
