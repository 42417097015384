import React from "react";
import ListCustomerComponent from "./Components/ListCustomerComponent";
import ButtonNewCustomerComponent from "./Components/ButtonNewCustomerComponent";
import WrapperApp from "../Components/WrapperApp";

const CustomerPage = () => {
    return (
        <WrapperApp indexItemMenu={1}>
            <ButtonNewCustomerComponent/>
            <ListCustomerComponent/>
        </WrapperApp>
    );
};

export default CustomerPage;
