import React, {useEffect} from 'react';
import TemplatePage from "../../Template/TemplatePage";
import Container from "./Container";
import Title from "./Title";
import H2 from "../../Components/Typographies/H2";
import H3 from "../../Components/Typographies/H3";
import {Stack} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Paragraph from "./Paragraph";

const AssistanceWellBeingPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
       <TemplatePage>
           <Container title={'Assistance à Domicile'}>
               <Title>
                   <H2 color={'pink.light'}>Aide au bien être</H2>
               </Title>
               <Title>
                   <H3 color={'blueGrey.dark'}>
                       <Stack direction={'row'} alignItems={'center'} component={'span'} lineHeight={'25px'}>
                           <FiberManualRecordIcon sx={{verticalAlign: 'middle', marginRight: 1, fontSize: '16px'}} />
                           Pédiluve ou lavage des pieds
                       </Stack>
                   </H3>
               </Title>
               <Paragraph>
                   C’est un soin et un confort d’hygiène pour assurer la propreté des pieds d’une personne dépendante.
               </Paragraph>
               <Paragraph>
                  Je propose cette intervention  pour le confort psychologique de la personne.C’est un moment privilégié, d’échange avec la personne.
               </Paragraph>
               <Paragraph>
                   Je vérifie l’état des pieds, mais je ne coupe pas les ongles et ne touche pas au cors ou durillons ( rôle du pédicure). Je masse les pieds avec de la crème hydratante.
                   Je transmets toutes les observations et constatations au niveau de l’état cutané ( irritation, rougeur, coupures…).
                   En fin d’intervention, j’évacue les déchets, le linge sale. Je nettoie et range le matériel et les produits.
               </Paragraph>
               <Title>
                   <H3 color={'blueGrey.dark'}>
                       <Stack direction={'row'} alignItems={'center'} component={'span'} lineHeight={'25px'}>
                           <FiberManualRecordIcon sx={{verticalAlign: 'middle', marginRight: 1, fontSize: '16px'}} />
                           Mise en beauté (Brushing, maquillage, application de crèmes...)
                       </Stack>
                   </H3>
               </Title>
               <Paragraph>
                   J’effectue après la toilette, une mise en beauté de la personne aidée, afin qu'elle ai un sentiment de bien-être. Je tiens compte des goûts et des attentes de la personne.<br/>
               </Paragraph>
               <Paragraph>
                   Je peux effectuer un brushing simple (suivant mes capacités), maquillage en fonction du choix de la personne, parfum...
               </Paragraph>
               <Paragraph>
                   La mise en beauté permet aux personnes fragiles de se sentir pleinement vivantes et de retrouver confiance en elles. Cela a un impact particulièrement positif sur leur moral.
               </Paragraph>
           </Container>
       </TemplatePage>
    )
}

export default AssistanceWellBeingPage