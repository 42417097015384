import React from 'react';
import NavBar from "../../../components/NavBar/NavBar";
import {Box} from "@mui/material";

const WrapperApp = ({children, indexItemMenu}) => {
    return (
        <>
            <NavBar indexItemMenu={indexItemMenu}/>
            <Box sx={{marginY: 10, marginX: 'auto', maxWidth: '1600px', paddingX: 2}}>
                {children}
            </Box>
        </>
    );
};

export default WrapperApp;