import React from 'react';
import {Button, Stack, TextField} from "@mui/material";
import FormField from "../../../../components/FormField/FormField";
import {FormProvider, useForm} from "react-hook-form";
import {apiCustomerProfilePatch} from "../../../../redux/customers/customersAction";
import {useDispatch} from "react-redux";

const FormName = ({customer, handleEditName}) => {
    const dispatch = useDispatch();
    const form = useForm({
        defaultValues: {
            lastname: customer.profile.lastname,
            firstname: customer.profile.firstname,
        }
    })

    const formSubmit = (data, e) => {
        e.preventDefault()
        dispatch(apiCustomerProfilePatch(customer.profile.id, data));
        handleEditName()
    }
    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(formSubmit)}>
                <Stack direction={'row'} justifyContent={'right'} marginBottom={1}>
                    <Button type={"submit"} size={'small'}>Enregistrer</Button>
                    <Button size={'small'} onClick={handleEditName}>Annuler</Button>
                </Stack>
                <Stack direction={'row'}>
                    <FormField
                        Component={TextField}
                        name={"firstname"}
                        label={'Prénom'}
                        rules={{required: "Veuillez renseigner le prénom du client"}}
                        sx={{
                            "& .MuiInputBase-root": {
                                fontSize: '13px',
                            },
                            '& .MuiFormLabel-root': {
                                fontSize: '13px',
                            },
                            width: '50%'
                        }}
                    />
                    <FormField
                        Component={TextField}
                        name={"lastname"}
                        label={'Nom'}
                        rules={{required: "Veuillez renseigner le nom du client"}}
                        sx={{
                            "& .MuiInputBase-root": {
                                fontSize: '13px',
                            },
                            '& .MuiFormLabel-root': {
                                fontSize: '13px',
                            },
                            width: '50%'
                        }}
                    />
                </Stack>
            </form>
        </FormProvider>
    );
};

export default FormName;