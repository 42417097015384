import {createTheme} from "@mui/material";
import {blueGrey, green, grey, indigo, lightBlue, lightGreen, pink, purple, red, yellow} from "@mui/material/colors";


const breakP = createTheme({
    breakpoints: {
        values: {
            bp0: 0,
            mw0: 0,
            bp1: 560,
            mw560: 560,
            mw780: 780,
            bp2: 780,
            mw1025: 1025,
            mw1250: 1250
        },
    }
})


export const Theme = createTheme(breakP, {

    typography: {
        cardTitle: {
            color: lightBlue[700],
            fontWeight: "bold",
            fontSize: 16,
            display: "block",
            margin: '10px 0'
        },
        title1: {
            fontSize: 20,
            padding: '10px 0',
            color: blueGrey[900],
            display: 'block'
        },
        title2: {
            fontSize: 14,
            fontWeight: 700,
            color: blueGrey[600],
            display: 'block'
        },
        title3: {
            fontSize: 16,
            fontWeight: 700,
            color: blueGrey[600],
            display: 'block'
        },
        txt1: {
            fontSize: 14,
            color: blueGrey[400],
            display: 'block'
        },
        txt2: {
            fontSize: 16,
            color: '#FFFFFF',
            display: 'block'
        },
        loading: {
            fontSize: 20,
            padding: '10px 0',
            margin: '0 auto',
            color: green[800],
            display: 'block'
        },
        addrDefault: {
            color: yellow[700],
            fontWeight: 'bold',
            fontSize: 14,
            display: 'block',
        }
    },
    components: {
        MuiTypography: {
          variants: [
              {
                  props: {variant: 'H1'},
                  style: {
                      fontWeight: '700',
                      fontSize: '20px',
                      [breakP.breakpoints.up("mw780")]: {
                          fontSize: "22px",
                      },
                      [breakP.breakpoints.up("mw1025")]: {
                          fontSize: "24px",
                      }
                  }
              },
              {
                  props: {variant: 'H1', shadow: 'true'},
                  style: {
                      textShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
                  }
              },
              {
                  props: {variant: 'H2'},
                  style: {
                      fontWeight: '500',
                      fontSize: '20px',
                      [breakP.breakpoints.up("mw780")]: {
                          fontSize: "22px",
                      }
                  }
              },
              {
                  props: {variant: 'H2', shadow: 'true'},
                  style: {
                      textShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
                  }
              },
              {
                  props: {variant: 'H3'},
                  style: {
                      fontWeight: '400',
                      fontSize: '19px',
                  }
              },
              {
                  props: {variant: 'tableHead'},
                  style: {
                      fontWeight: '600',
                      fontSize: '13px',
                      color: blueGrey[800]
                  }
              },
              {
                  props: {variant: 'tableRows'},
                  style: {
                      fontWeight: '400',
                      fontSize: '13px',
                      color: blueGrey[700]
                  }
              },
          ]
        },
        MuiButton: {
            variants: [
                {
                    props: {variant: "empty"},
                    style: {
                        border: `1px solid ${indigo[300]}`,
                        backgroundColor: '#ffffff',
                        color: indigo[300],
                        fontWeight: 'normal',
                        fontSize: 12,
                        padding: '5px 10px',
                        width: 'max-content',
                        ':hover': {
                            backgroundColor: indigo[300],
                            color: '#ffffff',
                        }
                    }
                },
                {
                    props: {variant: "empty", color: "purple"},
                    style: {
                        border: `1px solid ${purple[300]}`,
                        color: purple[300],
                        ':hover': {
                            backgroundColor: purple[300],
                            color: '#ffffff',
                            border: `1px solid ${purple[300]}`
                        }
                    }
                },
                {
                    props: {variant: "empty", color: "red"},
                    style: {
                        border: `1px solid ${red[300]}`,
                        color: red[300],
                        ':hover': {
                            backgroundColor: red[300],
                            color: '#ffffff',
                            border: `1px solid ${red[300]}`
                        }
                    }
                },
                {
                    props: {variant: "empty", color: "green"},
                    style: {
                        border: `1px solid ${green[300]}`,
                        color: green[300],
                        ':hover': {
                            backgroundColor: green[300],
                            color: '#ffffff',
                            border: `1px solid ${green[300]}`
                        }
                    }
                },
                {
                    props: {variant: "dark"},
                    style: {
                        backgroundColor: indigo[400],
                        color: '#ffffff',
                        fontWeight: 'normal',
                        padding: '5px 10px 5px 5px',
                        boxShadow: `1px 2px 4px ${grey[400]}`,
                        width: 'max-content',
                        ':hover': {
                            backgroundColor: indigo[500],
                        }
                    }
                },
                {
                    props: {variant: "dark", color: "purple"},
                    style: {
                        backgroundColor: purple[400],
                        ':hover': {
                            backgroundColor: purple[500]
                        }
                    }
                },
                {
                    props: {variant: "dark", disabled: true},
                    style: {
                        backgroundColor: grey[400],
                        color: '#ffffff',
                        fontWeight: 'normal',
                        fontSize: 12,
                        padding: '5px 10px',
                        boxShadow: `1px 2px 4px ${grey[400]}`
                    }
                },
            ]
        }
    },
    palette: {
        white: {
            main: '#ffffff'
        },
        black: {
            main: '#000000'
        },
        blueGrey: {
            light: blueGrey[400],
            primary: blueGrey[600],
            medium: blueGrey[700],
            dark: blueGrey[800]
        },
        pink: {
            light: pink[400],
            primary: pink[600],
            medium: pink[700],
            dark: pink[800]
        },
        purple: {
            light: purple[400],
            primary: purple[600],
            medium: purple[700],
            dark: purple[800]
        },
        green: {
            light: green[400],
            primary: green[600],
            medium: green[700],
            dark: green[800]
        },
        lightGreen: {
            light: lightGreen[400],
            primary: lightGreen[600],
            medium: lightGreen[700],
            dark: lightGreen[800]
        },
    },
})



