import axios from 'axios';

const apiURL = process.env.REACT_APP_API_URL;


const api = axios.create({
    baseURL: apiURL,
    headers: {
        'Content-Type': 'application/json',
    },
});
export default api;

export const otherApi = axios.create({
    baseURL: apiURL,
    headers: {
        'Content-Type': 'application/json',
    },
});

