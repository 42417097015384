import {
    REQUEST_DELETE_EVENT_SUCCESS,
    REQUEST_EVENTS_ERROR,
    REQUEST_EVENTS_SUCCESS, REQUEST_INVALIDATE_EVENT_SUCCESS, REQUEST_NEW_EVENT_SUCCESS,
    REQUEST_PATCH_EVENT_SUCCESS,
    REQUEST_EVENTS_STARTED, REQUEST_VALIDATE_EVENT_SUCCESS,
    RESET_STATE_EVENTS, REQUEST_EVENTS_TO_CUSTOMER_SUCCESS, RESET_STATE_EVENTS_CUSTOMER
} from "./eventsTypes";

const initialState = {
    events: [],
    eventsCustomer: [],
    loading: false,
    error: null,
}

const eventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_EVENTS_STARTED:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_EVENTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case REQUEST_EVENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                events: action.payload,
                error: "",
            };
        case RESET_STATE_EVENTS:
            return {
                ...state,
                loading: false,
                events: [],
                error: "",
            };
        case RESET_STATE_EVENTS_CUSTOMER:
            return {
                ...state,
                loading: false,
                eventsCustomer: [],
                error: "",
            };
        case REQUEST_PATCH_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                events: state.events.map(event => event.id === action.payload.id ? action.payload : event),
                error: "",
            };
        case REQUEST_NEW_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                events: [...state.events, action.payload],
                error: "",
            }
        case REQUEST_DELETE_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                events: state.events.filter(event => event.id !== action.payload),
                error: "",
            }
        case REQUEST_VALIDATE_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                eventsCustomer: state.eventsCustomer.map(event => event.id === action.payload.id ? action.payload : event),
                events: state.events.map(event => event.id === action.payload.id ? action.payload : event),
                error: "",
            }
        case REQUEST_INVALIDATE_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                eventsCustomer: state.eventsCustomer.map(event => event.id === action.payload.id ? action.payload : event),
                events: state.events.map(event => event.id === action.payload.id ? action.payload : event),
                error: "",
            }
        case REQUEST_EVENTS_TO_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                eventsCustomer: action.payload,
                error: "",
            }
        default:
            return state;
    }

}

export default eventsReducer
