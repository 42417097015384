import useString from "../useString/useString";

export default function useDateTime() {
    const {firstLetterUpperCase} = useString()

    const decimalTimeToHours = (decimalTime) => {
        const hrs = parseInt(Number(decimalTime));
        const min = ((Math.round((Number(decimalTime) - hrs) * 60)).toString()).padStart(2, '0');

        return hrs + 'h' + min;
    }

    const toMonthName = (monthNumber) => {
        const months = [
            'Janvier',
            'Février',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Août',
            'Septembre',
            'Octobre',
            'Novembre',
            'Décembre',
        ]
        return months[monthNumber - 1]

    }

    return {decimalTimeToHours, toMonthName}
}