import React, {Children} from 'react';
import {Typography} from "@mui/material";
import {blueGrey, purple} from "@mui/material/colors";

const Heading1 = ({children}) => {
    return (
        <Typography variant={'h4'} component={'h3'} sx={{paddingY: 2}} color={purple[600]}>
            {children}
        </Typography>
    );
};


export default Heading1;