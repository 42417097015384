import React from 'react';
import MyCommitmentsCard from "./MyCommitmentsCard";
import contentCards from './MyCommitmentsData'
import {Box, Grid, Stack} from "@mui/material";
import {grey} from "@mui/material/colors";
import H1 from "../../Components/Typographies/H1";

function MyCommitments() {
    return (
        <Box component={"section"} sx={{paddingY: 3, paddingX: 4, marginBottom: 4}}>
            <Stack direction={'row'} sx={{marginBottom: 5, paddingBottom: 1 ,borderBottom: '1px solid',borderColor: 'purple.light'}}>
                <H1 color={'pink.light'}>Mes Engagements</H1>
            </Stack>
            <Grid container spacing={2} alignItems={'stretch'} justifyContent={'center'}
            >
                {contentCards.map(data => <MyCommitmentsCard key={data.id} data={data}/>)}
            </Grid>
        </Box>
    );
}

export default MyCommitments;