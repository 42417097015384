import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {apiGetCustomer} from "../../../../redux/customers/customersAction";
import Loading from "../../../../components/Loading/Loading";
import LoadingError from "../../../../components/Loading/LoadingError";
import {Box, Grid, IconButton, Paper, Stack, Typography} from "@mui/material";
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import FormName from "../FormCustomers/FormName";
import FormAddressPatch from "../FormCustomers/FormAddressPatch";
import FormMail from "../FormCustomers/FormMail";
import FormPhoneNumber from "../FormCustomers/FormPhoneNumber";
import FormPrice from "../FormCustomers/FormPrice";
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import EuroIcon from '@mui/icons-material/Euro';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import StopIcon from "@mui/icons-material/Stop";
import BadgeIcon from '@mui/icons-material/Badge';

const BoxInfo = ({children}) => {
    return (
        <Box paddingX={2} marginBottom={4} paddingBottom={1} sx={{'&:hover':{'& .iconButton': {visibility: 'visible'} }}}>
            {children}
        </Box>
    );
};
const ContactComponent = ({customerId}) => {
    const dispatch = useDispatch();
    const customersData = useSelector((state) => state.customersReducer)
    const [customer, setCustomer] = useState([])
    const [mainAddress, setMainAddress] = useState([])
    const [editName, setEditName]=useState(false)
    const [editAddress, setEditAddress]=useState(false)
    const [editMail, setEditMail]=useState(false)
    const [editPhoneNumber, setEditPhoneNumber]=useState(false)
    const [editPrice, setEditPrice]=useState(false)
    const handleEditName = () => {
        setEditName(!editName)
    }
    const handleEditAddress = () => {
        setEditAddress(!editAddress)
    }
    const handleEditMail = () => {
        setEditMail(!editMail)
    }
    const handleEditPhoneNumber = () => {
        setEditPhoneNumber(!editPhoneNumber)
    }
    const handleEditPrice = () => {
        setEditPrice(!editPrice)
    }


    const handleCustomer = (data) => {
        if (data.customers.length > 0) {
            data.customers.forEach(element => {
                if (element.id === parseInt(customerId)) {
                    setCustomer(element)
                    handleMainAddress(element)
                }
            })
        }

    }
    const handleMainAddress = (customer) => {
        if (customer.profile) {
            customer.profile.addresses.forEach(address => {
                if (address.isPrincipal === true) {
                    setMainAddress(address)
                }
            })
        }
    }

    useEffect(() => {
        if (customersData.customers.length === 0) {
            dispatch(apiGetCustomer())
        } else if (customersData.customers.length > 0) {
            handleCustomer(customersData)
        }
    }, [])

    useEffect(() => {
        handleCustomer(customersData)
    }, [customersData])


    return (
        <>
            {customersData.loading ? (
                <Loading/>
            ) : customersData.error ? (
                <LoadingError error={customersData.error} txt={"Section Informations"}/>
            ) : customer.profile && (
                <Grid container alignItems={'flex-start'} rowSpacing={1}>
                    <Grid item mw0={12}>
                        <Stack direction={'row'} alignItems={'center'}>
                            <StopIcon fontSize={'small'} sx={{color: 'purple.light'}} /><Typography variant={"cardTitle"}>Contact</Typography>
                        </Stack>
                    </Grid>
                    <Grid item mw0={12}>
                        <Paper sx={{padding: {mw0: 1, mw1025: 2}}}>
                            <Grid container>
                                <Grid item mw0={12} mw1025={7}>
                                    <BoxInfo>
                                        <Stack direction={'row'} marginBottom={2}>
                                            <BadgeIcon fontSize={'small'} sx={{marginRight: 1, color: 'lightGreen.dark'}}/><Typography variant={"title2"}>Indentifiant</Typography>
                                        </Stack>
                                        <Typography variant={"txt1"}>
                                            {customer.uuid}-{customer.id}
                                        </Typography>
                                    </BoxInfo>
                                    <BoxInfo>
                                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                            <Stack direction={'row'}>
                                                <PersonIcon fontSize={'small'} sx={{marginRight: 1, color: 'lightGreen.dark'}}/><Typography variant={"title2"}>Nom</Typography>
                                            </Stack>
                                            <IconButton sx={{visibility: 'hidden', display: editName ? 'none' : 'block' }} className={'iconButton'} onClick={handleEditName}>
                                                <CreateOutlinedIcon fontSize={'small'}/>
                                            </IconButton>
                                        </Stack>
                                        <Typography variant={"txt1"} sx={{display: editName ? 'none' : 'block' }}>
                                            {customer.profile.firstname} {customer.profile.lastname}
                                        </Typography>
                                        <Box sx={{display: editName ? 'block' : 'none' }}>
                                            <FormName customer={customer} handleEditName={handleEditName} />
                                        </Box>
                                    </BoxInfo>
                                    <BoxInfo>
                                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                            <Stack direction={'row'}>
                                                <LocationOnIcon fontSize={'small'} sx={{marginRight: 1, color: 'lightGreen.dark'}}/><Typography variant={"title2"}>Adresse de facturation</Typography>
                                            </Stack>
                                            <IconButton sx={{visibility: 'hidden', display: editAddress ? 'none' : 'block' }} className={'iconButton'} onClick={handleEditAddress}>
                                                <CreateOutlinedIcon fontSize={'small'}/>
                                            </IconButton>
                                        </Stack>
                                        <Typography variant={"txt1"} sx={{display: editAddress ? 'none' : 'block' }}>
                                            {mainAddress.address}
                                        </Typography>
                                        <Typography variant={"txt1"} sx={{display: editAddress ? 'none' : 'block' }}>
                                            {mainAddress.addressComplement}
                                        </Typography>
                                        <Stack direction={"row"} spacing={1}>
                                            <Typography variant={"txt1"} sx={{display: editAddress ? 'none' : 'block' }}>
                                                {mainAddress.zip}
                                            </Typography>
                                            <Typography variant={"txt1"} sx={{display: editAddress ? 'none' : 'block' }}>
                                                {mainAddress.city}
                                            </Typography>
                                        </Stack>
                                        <Box sx={{display: editAddress ? 'block' : 'none' }}>
                                            <FormAddressPatch address={mainAddress} handleEditAddress={handleEditAddress} />
                                        </Box>
                                    </BoxInfo>
                                </Grid>
                                <Grid item mw0={12} mw1025={5}>
                                    <BoxInfo>
                                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                            <Stack direction={'row'}>
                                                <ContactMailIcon fontSize={'small'} sx={{marginRight: 1, color: 'lightGreen.dark'}}/><Typography variant={"title2"}>Email</Typography>
                                            </Stack>
                                            <IconButton sx={{visibility: 'hidden', display: editMail ? 'none' : 'block' }} className={'iconButton'} onClick={handleEditMail}>
                                                <CreateOutlinedIcon fontSize={'small'}/>
                                            </IconButton>
                                        </Stack>
                                        <Typography variant={"txt1"} sx={{display: editMail ? 'none' : 'block' }}>
                                            {customer.profile.email === '' ? 'Inconnu' : customer.profile.email}
                                        </Typography>
                                        <Box sx={{display: editMail ? 'block' : 'none' }}>
                                            <FormMail customer={customer} handleEditMail={handleEditMail} />
                                        </Box>
                                    </BoxInfo>
                                    <BoxInfo>
                                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                            <Stack direction={'row'}>
                                                <ContactPhoneIcon fontSize={'small'} sx={{marginRight: 1, color: 'lightGreen.dark'}}/><Typography variant={"title2"}>Téléphones</Typography>
                                            </Stack>
                                            <IconButton sx={{visibility: 'hidden', display: editPhoneNumber ? 'none' : 'block' }} className={'iconButton'} onClick={handleEditPhoneNumber}>
                                                <CreateOutlinedIcon fontSize={'small'}/>
                                            </IconButton>
                                        </Stack>
                                        <Typography variant={"txt1"} sx={{display: editPhoneNumber ? 'none' : 'block' }}>
                                            <Stack direction={'row'} alignItems={'center'}>
                                                <PhoneIcon fontSize={'small'} sx={{marginRight: 1}}/>{customer.profile.fixNumber === '' ? 'Inconnu' : customer.profile.fixNumber}
                                            </Stack>
                                        </Typography>

                                        <Typography variant={"txt1"} sx={{display: editPhoneNumber ? 'none' : 'block' }}>
                                            <Stack direction={'row'} alignItems={'center'}>
                                                <PhoneAndroidIcon fontSize={'small'} sx={{marginRight: 1}} />{customer.profile.mobileNumber === '' ? 'Inconnu' : customer.profile.mobileNumber}
                                            </Stack>
                                        </Typography>
                                        <Box sx={{display: editPhoneNumber ? 'block' : 'none' }}>
                                            <FormPhoneNumber customer={customer} handleEditPhoneNumber={handleEditPhoneNumber} />
                                        </Box>
                                    </BoxInfo>
                                    <BoxInfo>
                                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                            <Stack direction={'row'}>
                                                <EuroIcon fontSize={'small'} sx={{marginRight: 1, color: 'lightGreen.dark'}}/><Typography variant={"title2"}>Taux horaire</Typography>
                                            </Stack>
                                            <IconButton sx={{visibility: 'hidden', display: editPrice ? 'none' : 'block' }} className={'iconButton'} onClick={handleEditPrice}>
                                                <CreateOutlinedIcon fontSize={'small'}/>
                                            </IconButton>
                                        </Stack>
                                        <Typography variant={"txt1"} sx={{display: editPrice ? 'none' : 'block' }}>{customer.profile.price}&euro;</Typography>
                                        <Box sx={{display: editPrice ? 'block' : 'none' }}>
                                            <FormPrice customer={customer} handleEditPrice={handleEditPrice}/>
                                        </Box>
                                    </BoxInfo>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                </Grid>
            )}
        </>
    );
};
export default ContactComponent;
