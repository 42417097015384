import React, {useEffect} from 'react';
import TemplatePage from "../../Template/TemplatePage";
import Container from "./Container";
import Title from "./Title";
import H2 from "../../Components/Typographies/H2";
import Paragraph from "./Paragraph";
import H3 from "../../Components/Typographies/H3";
import {Stack} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const AssistanceChildrenPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <TemplatePage>
            <Container title={'Assistance à Domicile'}>
                <Title>
                    <H2 color={'pink.light'}>Prise en charge des enfants à domicile</H2>
                </Title>
                <Paragraph>
                    Dans le respect des consignes parentales et des habitudes de la famille,des règles d’ hygiène et de sécurité, j’assure la garde des enfants au domicile des parents ou lors des sorties.
                </Paragraph>
                <Paragraph>
                    Lors du 1 er rendez vous, je recueille auprès des parents les informations nécessaires pour instaurer avec eux et leurs enfants une relation de confiance.<br/>
                    Je définis avec les parents les autorisations nécessaires.
                </Paragraph>
                <Paragraph>
                    Je vérifie la conformité du matériel, des équipements disponibles au domicile. Je contribue activement à la prévention des risques. Je reste toujours vigilante pour assurer la sécurité des enfants dans leur environnement et je préserve ma propre sécurité en appliquant les gestes et les postures adaptés.
                </Paragraph>
                <Paragraph>
                    En situation d’ urgence, j’ applique les gestes de premier secours et alerte les services d’ interventions ou d’urgence concernés.
                </Paragraph>
                <Paragraph>
                    En fin d’ intervention, je rend compte aux parents soit oralement, soit par écrit par l’ intermédiaire du cahier de transmission. Cette transmission permet une meilleure prise en charge de l’enfant ainsi qu’une cohérence éducative et une stabilité affective. Je note la santé de l’ enfant, son sommeil, son alimentation, ses activités et les écarts de comportement.
                </Paragraph>
                <Title>
                    <H3 color={'blueGrey.dark'}>
                        <Stack direction={'row'} alignItems={'center'} component={'span'} lineHeight={'25px'}>
                            <FiberManualRecordIcon sx={{verticalAlign: 'middle', marginRight: 1, fontSize: '16px'}} />
                            Lever et toilette
                        </Stack>
                    </H3>
                </Title>
                <Paragraph>
                    Selon les consignes des parents, en fonction de l'âge de l’ enfant, j’interviens pour le lever.
                    J’effectue la toilette du jeune enfant ( lavabo ou bain), je le change et l'habille. J'apporte aux enfants plus âgés une aide si nécessaire.
                </Paragraph>
                <Title>
                    <H3 color={'blueGrey.dark'}>
                        <Stack direction={'row'} alignItems={'center'} component={'span'} lineHeight={'25px'}>
                            <FiberManualRecordIcon sx={{verticalAlign: 'middle', marginRight: 1, fontSize: '16px'}} />
                            Apprentissage de l’enfant
                        </Stack>
                    </H3>
                </Title>
                <Paragraph>
                    J’accompagne l’enfant vers l’autonomie, dans ses apprentissages (par exemple l’acquisition de la propreté) et dans sa sociabilisation. Tout cela se pratique dans le respect des principes éducatifs des parents. Pour permettre un bon apprentissage de la propreté et d’autres acquisitions  de l’enfant, il est important que toutes les personnes qui s'occupent de l’enfant agissent selon les mêmes méthodes.
                </Paragraph>
                <Title>
                    <H3 color={'blueGrey.dark'}>
                        <Stack direction={'row'} alignItems={'center'} component={'span'} lineHeight={'25px'}>
                            <FiberManualRecordIcon sx={{verticalAlign: 'middle', marginRight: 1, fontSize: '16px'}} />
                            Sommeil de l’enfant
                        </Stack>
                    </H3>
                </Title>
                <Paragraph>
                    Je demande aux parents lors du premier rendez-vous les habitudes de leurs enfants.
                    Je respecte le rythme de l’enfant et ses rituels et je l’accompagne pour aller se coucher. Je surveille son sommeil.
                </Paragraph>
                <Title>
                    <H3 color={'blueGrey.dark'}>
                        <Stack direction={'row'} alignItems={'center'} component={'span'} lineHeight={'25px'}>
                            <FiberManualRecordIcon sx={{verticalAlign: 'middle', marginRight: 1, fontSize: '16px'}} />
                            Repas
                        </Stack>
                    </H3>
                </Title>
                <Paragraph>
                    Je prépare les repas et j'accompagne les enfants dans la prise des repas.
                </Paragraph>
                <Paragraph>
                    L’autonomie de l’enfant pour les repas s'acquiert au fur et à mesure que l’enfant grandit. De la naissance jusqu'à 2 ans, j'installe l'enfant pour se nourrir selon son age : nourrisson dans mes bras, dans le transat, dans la chaise haute et enfin à table avec le réhausseur. Selon l'age, je fais manger les enfants dans le souci de contribuer aux apprentissages de base.<br/>
                    J'ai un rôle éducatif.
                </Paragraph>
                <Paragraph>
                    Je respecte le régime alimentaire et les principes éducatifs des parents. En cas du dérèglement du transit (vomissement, constipation,diarrhée...) j'adapte l’alimentation avec l’ accord des parents ou suivant les prescriptions médicales.
                </Paragraph>
                <Title>
                    <H3 color={'blueGrey.dark'}>
                        <Stack direction={'row'} alignItems={'center'} component={'span'} lineHeight={'25px'}>
                            <FiberManualRecordIcon sx={{verticalAlign: 'middle', marginRight: 1, fontSize: '16px'}} />
                            Accompagnement aux devoirs
                        </Stack>
                    </H3>
                </Title>
                <Paragraph>
                    J’accompagne l’enfant dans l’organisation et la réalisation des ses devoirs.<br/>
                    Je veille à l’installation de l’enfant (confortablement assis, au calme, dans un endroit bien éclairé…).<br/>
                    Je guide et je motive l’enfant sans me substituer au rôle d’enseignant afin de développer sa réflexion et son autnomie.
                </Paragraph>
                <Title>
                    <H3 color={'blueGrey.dark'}>
                        <Stack direction={'row'} alignItems={'center'} component={'span'} lineHeight={'25px'}>
                            <FiberManualRecordIcon sx={{verticalAlign: 'middle', marginRight: 1, fontSize: '16px'}} />
                            Activités
                        </Stack>
                    </H3>
                </Title>
                <Paragraph>
                    Je propose des activités aux enfants, tel que jeux de socièté, jeux éducatifs, travaux manuel, patisserie...


                </Paragraph>
                <Paragraph>
                    Lorsque j’organise une activité manuelle avec les enfants, je préviens à l’avance les parents et je leur demande leur accord.
                </Paragraph>
                <Paragraph>
                    Sorties en extérieur: J'emmène les enfants au parc de jeux avec l'autorisation des parents.
                </Paragraph>
            </Container>
        </TemplatePage>
    )
}

export default AssistanceChildrenPage