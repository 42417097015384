import React, {useState} from "react";
import {FormControl, Grid, MenuItem, Select} from "@mui/material";
import WrapperApp from "../Components/WrapperApp";
import FinancialReportComponent from "../Customers/Components/FinancialReportComponent";

const FinancialReportsPage = () => {
    const [year, setYear] = useState(new Date().getFullYear());
    const handleYearChange = (event) => {
        setYear(event.target.value)
    }

    return (
        <WrapperApp indexItemMenu={3}>
            <Grid container spacing={2}>
                <Grid item mw0={12}>
                    <FormControl>
                        <Select
                            value={year}
                            onChange={handleYearChange}
                        >
                            <MenuItem value={'2022'}>2022</MenuItem>
                            <MenuItem value={'2023'}>2023</MenuItem>
                            <MenuItem value={'2024'}>2024</MenuItem>
                            <MenuItem value={'2025'}>2025</MenuItem>
                        </Select>
                    </FormControl>

                </Grid>
                <Grid item container mw0={12} spacing={2} justifyContent={{mw0: 'center', mw780: 'start'}}>
                    <FinancialReportComponent year={year}/>
                </Grid>
            </Grid>
        </WrapperApp>
    );
};

export default FinancialReportsPage;
