import api from "./api";

export default new class EventsService {

    getAdminEvents = async (adminId) => {
        const events = await api.get(`/events?admin.id=${adminId}`);
        const eventsArray = [];
        events.data['hydra:member'].forEach(event => {
            const res = {
                id: event.id,
                title: event.user.profile.lastname + ' ' + event.user.profile.firstname,
                start: event.start,
                end: event.end,
                color: event.color.color,
                user: event.user,
                address: event.address,
                isValidated: event.isValidated,
            }
            eventsArray.push(res);
        })
        return eventsArray;
    }

    addEvent = async (event) => {
        return await api.post(`/events`, event);
    }

    patchEvent = async (id, data) => {
        return await api.patch(`/events/${id}`, data, {
            headers: {
                "Content-Type": "application/merge-patch+json",
            },
        });
    }

    deleteEvent = async (id) => {
        return await api.delete(`/events/${id}`);
    }

    getValidateEvent = async (id) => {
        return await api.get(`/events/${id}/validate`);
    }

    getInvalidateEvent = async (id) => {
        return await api.get(`/events/${id}/invalidate`);
    }

    getYearReport = async (adminId, year, isValidated) => {
        return await api.get(`/events/report/${year}`, {
            params: {
                "admin.id": adminId,
                isValidated: isValidated,
            },
            headers: {
                "Content-Type": "application/json"
            }
        });
    }

    getEventsCustomer = async (customerId) => {
        return await api.get(`/events`, {
            params: {
                "user.id": customerId
            },
            headers: {
                "Content-Type": "application/json"
            }
        });
    }

    getEventsBetweenDates = async (adminId, start, end) => {
        return await api.get(`/events`, {
            params: {
                "admin.id": adminId,
                "start[after]": start.getFullYear()+"-"+(start.getMonth()+1)+"-"+start.getDate(),
                "start[before]": end.getFullYear()+"-"+(end.getMonth()+1)+"-"+end.getDate(),
            },
            headers: {
                "Content-Type": "application/json"
            }
        });
    }

}()
