import EventsService from "../../services/events.service";
import {
    REQUEST_DELETE_EVENT_SUCCESS,
    REQUEST_EVENTS_ERROR,
    REQUEST_EVENTS_SUCCESS, REQUEST_INVALIDATE_EVENT_SUCCESS,
    REQUEST_NEW_EVENT_SUCCESS,
    REQUEST_PATCH_EVENT_SUCCESS,
    REQUEST_EVENTS_STARTED, REQUEST_VALIDATE_EVENT_SUCCESS, REQUEST_EVENTS_TO_CUSTOMER_SUCCESS
} from "./eventsTypes";

export const newEvent = (event, info) => async (dispatch) => {
    try {
        dispatch(requestStarted());
        const response = await EventsService.addEvent(event);
        const calendarApi = info.view.calendar;
        // const newEvent = response.data;
        // calendarApi.addEvent({
        //     id: newEvent.id,
        //     title: newEvent.user.profile.lastname + ' ' + newEvent.user.profile.firstname,
        //     start: newEvent.start,
        //     end: newEvent.end,
        //     color: newEvent.color.color,
        //     user: newEvent.user,
        //     address: newEvent.address,
        //     isValidated: newEvent.isValidated,
        // });
        calendarApi.refetchEvents();
        dispatch({
            type: REQUEST_NEW_EVENT_SUCCESS,
            payload: transformData(response.data),
        });
        info.event.remove();
    } catch (err) {
        dispatch(requestError(err.message));
    }
}

export const getAdminEvents = (adminId) => async (dispatch) => {
    try {
        dispatch(requestStarted());
        const response = await EventsService.getAdminEvents(adminId);
        dispatch(requestEventsSuccess(response));
    } catch (err) {
        dispatch(requestError(err.message));
    }

}

export const getEventsCustomer = (customerId) => async (dispatch) => {
    try {
        dispatch(requestStarted());
        const response = await EventsService.getEventsCustomer(customerId)
        dispatch({
            type: REQUEST_EVENTS_TO_CUSTOMER_SUCCESS,
            payload: response.data['hydra:member']

        })
    } catch (err) {
        dispatch(requestError(err.message));
    }
}

export const patchEvent = (id, data) => async (dispatch) => {
    try {
        dispatch(requestStarted());
        const response = await EventsService.patchEvent(id, data);
        dispatch(requestPatchedEventSuccess(transformData(response.data)));
    } catch (err) {
        dispatch(requestError(err.message));
    }
}

export const deleteEvent = (id) => async (dispatch) => {
    try {
        dispatch(requestStarted());
        await EventsService.deleteEvent(id);
        dispatch(requestDeleteEventSuccess(parseInt(id)));
    } catch (err) {
        dispatch(requestError(err.message));
    }
}

export const validateEvent = (eventId) => async (dispatch) => {
    try {
        dispatch(requestStarted());
        const response = await EventsService.getValidateEvent(eventId);
        dispatch(requestValidateEventSuccess(transformData(response.data)));
    } catch (e) {
        dispatch(requestError(e.message));
    }
}

export const invalidateEvent = (eventId) => async (dispatch) => {
    try {
        dispatch(requestStarted());
        const response = await EventsService.getInvalidateEvent(eventId);
        dispatch(requestInvalidateEventSuccess(transformData(response.data)));
    } catch (e) {
        dispatch(requestError(e.message));
    }
}


function requestStarted() {
    return {
        type: REQUEST_EVENTS_STARTED,
    };
}

function requestError(error) {
    return {
        type: REQUEST_EVENTS_ERROR,
        payload: error,
    };
}

function requestEventsSuccess(events) {
    return {
        type: REQUEST_EVENTS_SUCCESS,
        payload: events,
    };
}

function requestValidateEventSuccess(events) {
    return {
        type: REQUEST_VALIDATE_EVENT_SUCCESS,
        payload: events,
    };
}

function requestInvalidateEventSuccess(events) {
    return {
        type: REQUEST_INVALIDATE_EVENT_SUCCESS,
        payload: events,
    };
}

function requestPatchedEventSuccess(event) {
    return {
        type: REQUEST_PATCH_EVENT_SUCCESS,
        payload: event,
    };
}

function requestDeleteEventSuccess(id) {
    return {
        type: REQUEST_DELETE_EVENT_SUCCESS,
        payload: id,
    };
}

function transformData(data) {
    return {
        id: data.id,
        title: data.user.profile.lastname + ' ' + data.user.profile.firstname,
        start: data.start,
        end: data.end,
        color: data.color.color,
        user: data.user,
        address: data.address,
        isValidated: data.isValidated,
    }
}
