import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {apiGetCustomer} from "../../../../redux/customers/customersAction";
import Loading from "../../../../components/Loading/Loading";
import LoadingError from "../../../../components/Loading/LoadingError";
import {Box, Button, Typography} from "@mui/material";
import Modal from "../../../../components/Modal/ModalComponent";
import FormNewCustomer from "../FormCustomers/FormNewCustomer";
import useModal from "../../../../Hooks/useModal/useModal";
import useAuth from "../../../../Hooks/useAuth/useAuth";
import {useNavigate} from "react-router-dom";

const ButtonNewCustomerComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const {isOpen, toggle} = useModal();
    const {authId} = useAuth()
    const customersData = useSelector((state) => state.customersReducer)

    useEffect(() => {
        customersData.customers.length === 0 && dispatch(apiGetCustomer())
    }, [])


    return (
        <>
            {customersData.isLoading ? (
                <Loading/>
            ) : customersData.error ? (
                <LoadingError error={customersData.error} txt={"Section Liste clients"}/>
            ) : customersData.customers && (
                <>
                    {customersData.customers.length === 0 ? (
                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <Typography variant={"title1"}>
                                Créez votre premier client
                            </Typography>
                            <Button variant={"empty"} color={"purple"} onClick={() => {
                                // toggle();
                                navigate(`/admin/customers/new`)
                            }}>Nouveau client</Button>
                        </Box>
                    ) : (
                        <Box>
                            <Button variant={"empty"} color={"purple"} onClick={() => {
                                // toggle();
                                navigate(`/admin/customers/new`)
                            }}>Nouveau client</Button>
                        </Box>
                    )}
                </>
            )}
            <Modal hide={toggle} isOpen={isOpen} header={"Clients : Nouveau client"}>
                <FormNewCustomer adminId={authId} toggle={toggle}/>
            </Modal>
        </>
    );
};

export default ButtonNewCustomerComponent;
