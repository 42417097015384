import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
    REFRESH_TOKEN,
    REQUEST_AUTH_PROFILE_PATCH_SUCCESS,
    REQUEST_AUTH_PROFILE_SUCCESS, REQUEST_AUTH_ADDRESS_SUCCESS
} from "./authTypes";
import AuthService from "../../services/auth.service";
import {apiGetCustomer} from "../customers/customersAction";
import CustomersService from "../../services/customers.service";
import AddressService from "../../services/address.service";

export const register = (userData) => async (dispatch) => {
    return AuthService.register(userData)
        .then((data) => {
            dispatch({
                type: REGISTER_SUCCESS,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: "Registration successful",
            });
            return Promise.resolve();
        })
        .catch((err) => {
            dispatch({
                type: REGISTER_FAIL,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: err.message,
            });
            return Promise.reject();
        });
};

export const login = (email, password, remember) => {
    return async (dispatch) => {
        try {
            const data = await AuthService.login(email, password, remember)
            dispatch({
                type: LOGIN_SUCCESS,
                payload: {user: data},
            });
            dispatch(apiGetCustomer())
            dispatch(apiGetAuthProfile(remember))
        } catch (err) {
            console.log(err);
            dispatch({
                type: LOGIN_FAIL,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: err.response.data.message,
            });
        }
    }
};

export const logout = () => {
    AuthService.logout();
    localStorage.removeItem("profile");
    sessionStorage.removeItem("profile");
    return {
        type: LOGOUT,
    };
};

export const refreshToken = (accessToken) => (dispatch) => {
    dispatch({
        type: REFRESH_TOKEN,
        payload: accessToken,
    });
};

export const apiAuthProfilePatch = (id, data) => {
    return async (dispatch) => {
        try {
            const res = await CustomersService.patchProfileUser(id, data)
            const profile = {
                id: res.data.id,
                firstname: res.data.firstname,
                lastname: res.data.lastname,
                fixNumber: res.data.fixNumber,
                mobileNumber: res.data.mobileNumber,
                price: res.data.price,
                email: res.data.email
            }
            dispatch({
                type: REQUEST_AUTH_PROFILE_PATCH_SUCCESS,
                payload: profile
            })
        } catch (err) {
            dispatch({
                type: SET_MESSAGE,
                payload: err.message,
            });
        }
    };
};

export const apiGetAuthProfile = (remember) => {
    return async (dispatch) => {
        try {
            const res = await CustomersService.getUser()
            if (remember) {
                localStorage.setItem("profile", JSON.stringify(res.data.profile));
            } else {
                sessionStorage.setItem("profile", JSON.stringify(res.data.profile));
            }
            dispatch({
                type: REQUEST_AUTH_PROFILE_SUCCESS,
                payload: res.data.profile
            })
        } catch (err) {
            dispatch({
                type: SET_MESSAGE,
                payload: err.message,
            });
        }
    };
}

export const apiPatchAuthAddress = (id, data) => {
    return async (dispatch) => {
        try {
            const addrRes = await AddressService.patchAddress(id, data)
            const address = {
                id: addrRes.data.id,
                address: addrRes.data.address,
                addressComplement: addrRes.data.addressComplement,
                city: addrRes.data.city,
                country: addrRes.data.country,
                zip: addrRes.data.zip,
                isPrincipal: addrRes.data.isPrincipal
            }

            if (localStorage.getItem("profile")) {
                const storage = JSON.parse(localStorage.getItem("profile"))
                storage.addresses[0] = address
                localStorage.setItem("profile", JSON.stringify(storage));
            } else if (sessionStorage.getItem("profile")) {
                const storage = JSON.parse(sessionStorage.getItem("profile"))
                storage.addresses[0] = address
                sessionStorage.setItem("profile", JSON.stringify(storage));
            }
            dispatch({
                type: REQUEST_AUTH_ADDRESS_SUCCESS,
                payload: address
            })
        } catch (err) {
            dispatch({
                type: SET_MESSAGE,
                payload: err.message,
            });
        }
    }
}
