import React from 'react';
import {Typography} from "@mui/material";

const H1 = ({children, color="#000000"}) => {
    return (
        <Typography variant={'H1'} shadow={'true'} color={color} component={'h1'}>
            {children}
        </Typography>
    );
};

export default H1;