import React from 'react';
import {Box} from "@mui/material";

const Main = ({children}) => {
    return (
        <Box component={'main'} minHeight={'85vh'} paddingTop={{mw0: '180px', mw780: '200px'}} paddingBottom={5} sx={{maxWidth: '1650px', marginX: 'auto'}}>
                {children}
        </Box>
    )
}

export default Main