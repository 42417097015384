import React, {useEffect} from 'react';
import TemplatePage from "../../Template/TemplatePage";
import {Box, Stack, Typography} from "@mui/material";
import H2 from "../../Components/Typographies/H2";
import H3 from "../../Components/Typographies/H3";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Container from "./Container";
import Title from "./Title";
import Paragraph from "./Paragraph";

const AssistanceAutonomyPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <TemplatePage>
                <Container title={'Assistance à Domicile'}>
                    <Title>
                        <H2 color={'pink.light'}>Aide à l'autonomie</H2>
                    </Title>
                    <Paragraph>
                        Mon rôle est de contribuer au bien être des personnes au sein de leur foyer en respectant leur dignité, leur intégrité, leur vie privée et leur sécurité. Je fais preuve de délicatesse afin de créer un lien de confiance avec la personne aidée.
                    </Paragraph>
                    <Paragraph>
                        Pour permettre aux personnes âgées ou malades, aux personnes en situation de handicap de maintenir leur autonomie et de contribuer à vivre à domicile, je les aide en mettant en oeuvre les gestes techniques appropriés dans le respect de l’ intimité et l’ écoute de la personne.
                    </Paragraph>
                    <Paragraph>
                        J’informe la personne aidée et son entourage des aménagements possibles contribuant au maintien de son autonomie.
                    </Paragraph>
                    <Title>
                        <H3 color={'blueGrey.dark'}>
                            <Stack direction={'row'} alignItems={'center'} component={'span'} lineHeight={'25px'}>
                                <FiberManualRecordIcon sx={{verticalAlign: 'middle', marginRight: 1, fontSize: '16px'}} />
                                Aide au lever et au coucher
                            </Stack>
                        </H3>
                    </Title>
                    <Paragraph>
                        L’ aide au lever et au coucher,  s’ adresse aux personnes dont la perte d’ autonomie les empêche d’ effectuer seules un ou plusieurs mouvements qui permettent de se coucher dans un lit ou de s en lever.
                    </Paragraph>
                    <Paragraph>
                        L’aide au lever et au coucher est une opération délicate nécessitant des compétences professionnelles. Je suis formée à l’accompagnement des personnes âgées ou handicapées (gestes et postures) et à l’utilisation de matériel médical (lit médicalisé).
                        Les transferts sont effectués avec bienveillance et en toute sécurité pour respecter l’intégrité physique de la personne aidée.
                    </Paragraph>
                    <Title>
                        <H3 color={'blueGrey.dark'}>
                            <Stack direction={'row'} alignItems={'center'} component={'span'} lineHeight={'25px'}>
                                <FiberManualRecordIcon sx={{verticalAlign: 'middle', marginRight: 1, fontSize: '16px'}} />
                                Aide à l’ habillage
                            </Stack>
                        </H3>
                    </Title>

                    <Paragraph>
                        Je sollicite la participation de la personne aidée en fonction de son degré d’autonomie et de ses capacités du moment : j’ajuste l’aide apportée.<br/>
                        Je respecte les règles d’ hygiènes , les habitudes et les goûts vestimentaires de la personne. Je veille à ma propre sécurité et à celle de la personne aidée.
                    </Paragraph>
                    <Title>
                        <H3 color={'blueGrey.dark'}>
                            <Stack direction={'row'} alignItems={'center'} component={'span'} lineHeight={'25px'}>
                                <FiberManualRecordIcon sx={{verticalAlign: 'middle', marginRight: 1, fontSize: '16px'}} />
                                Aide à la toilette et aux changes
                            </Stack>
                        </H3>
                    </Title>
                    <Paragraph>
                        L’aide à la toilette  a lieu de préférence le matin. La toilette peut s’effectuer au lavabo, dans la baignoire, sous la douche ou dans dans un lit médicalisé. Elle implique le déshabillage et l’habillage de la personne aidée.
                        J’aide la personne à faire sa toilette, en veillant à son confort et dans le respect de son intimité. Je tiens compte de son rythme, de ses habitudes et de ses capacités. Je reste bienveillante et veille à sa sécurité. Je sollicite la participation de la personne aidée en fonction de son degré d'autonomie et de ses capacités du moment et j’ajuste l’aide apportée.
                        Une fois la toilette de la personne aidée terminée, je nettoie la salle de bain. Le tout afin de garder un cadre de vie propre et agréable.
                    </Paragraph>
                    <Paragraph>
                        <Box component={'span'} color={'blueGrey.medium'}>Aide à la toilette lorsque la personne est quasi autonome :<br/></Box>
                        La personne aidée garde la maîtrise de son hygiène tout en bénéficiant d’une aide bienveillante pour certains gestes. L’objectif est que les personnes aidées se débrouillent seules autant que possible, tant pour préserver leur intimité que pour stimuler leur autonomie.<br/>
                        J’accompagne la personne aidée en la guidant. Je reste dans la pièce pour surveiller, tendre la serviette, ou l’aider en cas de difficulté.<br/>
                        Ma présence est une sécurité.
                    </Paragraph>
                    <Typography component={'p'} align={'justify'} lineHeight={'25px'} marginBottom={3} color={'blueGrey.primary'}>
                        <Box component={'span'} color={'blueGrey.medium'}>Aide à la toilette lorsque la personne est peu autonome :<br/></Box>
                        Les personnes en perte d’autonomie peuvent éprouver des difficultés à réaliser certains gestes d’hygiène, les conduisant peu à peu à négliger leur toilette.
                        Dans ce cas, j’ai un rôle actif en plus de mon rôle de sécurité. Mon intervention permet de garder une hygiène corporelle irréprochable et de rester à domicile le plus longtemps possible. Idéalement, il faudra prévoir l’aménagement de la salle de bain (siège dans la douche ou dans la baignoire, barres de maintien etc).
                    </Typography>
                </Container>
            </TemplatePage>
        </div>
    )
}

export default AssistanceAutonomyPage