import axiosInstance, {otherApi} from "./api";
import TokenService from "./token.service";
import {refreshToken} from "../redux/auth/authAction";


const setup = store => {
    axiosInstance.interceptors.request.use(
        config => {
            const token = TokenService.getLoclAccessToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );
    const {dispatch} = store;
    axiosInstance.interceptors.response.use(
        response => {
            return response;
        },
        async error => {
            const originalConfig = error.config;

            if (originalConfig.url !== "/login" && error.response) {
                if (error.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;
                    try {
                        const rs = await otherApi.post("/token/refresh", {
                            refresh_token: TokenService.getLoclRefreshToken(),
                        });
                        const accessToken = rs.data.token;
                        dispatch(refreshToken(accessToken));
                        TokenService.updateLocalAccessToken(accessToken);
                        return axiosInstance(originalConfig);
                    } catch (e) {
                        return Promise.reject(e);
                    }

                }
            }
            return Promise.reject(error);
        }
    );

};

export default setup;
