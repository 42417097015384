import api from "./api";
import TokenService from "./token.service";
import AddressService from "./address.service";
import userService from "./customers.service";

class AuthService {
    login(uuid, password, remember) {
        return api
            .post(`/login`, {
                uuid,
                password,
            })
            .then((response) => {
                if (response.data.token) {
                    response.data.user = JSON.parse(response.data.user);
                    response.data.user.profile = JSON.parse(response.data.user.profile);
                    TokenService.setUser(response.data, remember);
                }
                return response.data;
            })
    }

    logout() {
        TokenService.removeUser();
    }

    async register(user) {
        const resp = await api.post(`/users`, user);
        const addresses = user.addresses;
        for (let address in addresses) {
            await AddressService.addAddress(
                addresses[address],
                resp.data["@id"]
            ).then((r) => {
                console.log(r);
            });
        }
    }
}

export default new AuthService();
