import {
    REQUEST_REPORT_ERROR, REQUEST_GET_REPORT_YEAR_ALL_EVENTS_SUCCESS,
    REQUEST_GET_REPORT_YEAR_EVENTS_NOT_VALIDATED_SUCCESS,
    REQUEST_GET_REPORT_YEAR_EVENTS_VALIDATED_SUCCESS,
    REQUEST_REPORT_STARTED
} from "./reportsTypes";

const initialStateReports = {
    isLoading: false,
    reportsEventsValidated: [],
    reportsEventsNotValidated: [],
    reportsAllEvents: [],
    reportsEventsID: undefined,
    error: "",
};

const reportsReducer = (state = initialStateReports, action) => {
    switch (action.type) {
        case REQUEST_REPORT_STARTED:
            return {
                ...state,
                isLoading: true,
            };
        case REQUEST_REPORT_ERROR:
            return {
                ...state,
                isLoading: false,
                reportsEventsValidated: [],
                reportsEventsNotValidated: [],
                reportsAllEvents: [],
                reportsEventsID: [],
                error: action.payload,
            };
        case REQUEST_GET_REPORT_YEAR_EVENTS_VALIDATED_SUCCESS:
            return {
                ...state,
                isLoading: false,
                reportsEventsValidated: action.payload,
            }
        case REQUEST_GET_REPORT_YEAR_EVENTS_NOT_VALIDATED_SUCCESS:
            return {
                ...state,
                isLoading: false,
                reportsEventsNotValidated: action.payload,
            }
        case REQUEST_GET_REPORT_YEAR_ALL_EVENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                reportsAllEvents: action.payload,
            }
        default:
            return state;
    }
}

export default reportsReducer;