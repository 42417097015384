import React from 'react';
import Image from '../img/img-2-350x622.jpg'
import {Box} from "@mui/material";

const ImgBanner = ({children}) => {
    return (
        <Box sx={{
            display: {mw0: 'none', mw780: 'block'},
            position: 'relative',
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), url(${Image})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center left',
            backgroundSize: 'cover',
            height: '150px',
            width: {mw0: 'unset', mw780: '50%'},
        }}>
            {children}
        </Box>
    )
}

export default ImgBanner