import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {login} from "../../redux/auth/authAction";
import {useForm, FormProvider} from "react-hook-form";
import {Alert, Box, Button, Checkbox, FormControlLabel, Stack, TextField, Typography} from "@mui/material";
import FormField from "../../components/FormField/FormField";
import Loading from "../../components/Loading/Loading";


const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const auth = useSelector((state) => state.auth);
    const message = useSelector((state) => state.message);

    const [remember, setRemember] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (auth.isLoggedIn) {
            navigate("/admin/customers")
        }
    } , [auth.isLoggedIn, navigate])

    const form = useForm({
        defaultValues: {
            uuid: "",
            password: ""
        }
    })

    const handleCheckboxChange = (event) => {
        setRemember(!remember);
    }

    const handleSubmit = (data, event) => {
        event.preventDefault()
        setLoading(true)
        dispatch(login(data.uuid, data.password, remember)).then((test) => {
            // console.log(auth);
            // if (auth.isLoggedIn) {
            //     navigate("/admin/customers")
            // }
            setLoading(false)
        });
    };

    return (
        <>
            {loading ? (
                <Loading/>
            ) : (
                <Box width={'80vw'} maxWidth={'500px'} marginX={'auto'} marginTop={'50px'}>
                    <FormProvider {...form}>
                        <form onSubmit={form.handleSubmit(handleSubmit)}>
                            <Stack spacing={3}>
                                <Typography variant={"title1"} align={'center'}>Accès Espace Admin</Typography>
                                {message.message && (
                                    <Alert severity={"error"} sx={{mt: 3}}>{message.message}</Alert>
                                )}
                                <FormField
                                    Component={TextField}
                                    name={'uuid'}
                                    label={'Identifiant'}
                                    rules={{required: "Veuillez renseigner votre identifiant"}}
                                />
                                <FormField
                                    Component={TextField}
                                    name={'password'}
                                    label={'Mode de passe'}
                                    type={'password'}
                                    rules={{required: "Veuillez renseigner votre mot de passe"}}
                                />
                                <FormControlLabel control={<Checkbox onChange={handleCheckboxChange}/>}
                                                  label="Se rappeler de moi"/>
                                <Stack direction={'row'} spacing={3} justifyContent={'center'}>
                                    <Button type={"submit"} variant="dark" color="purple">Connexion</Button>
                                    <Button variant="dark" color="purple" href={'/'}>Retour</Button>
                                </Stack>
                            </Stack>
                        </form>
                    </FormProvider>
                </Box>
            )}
        </>

    );
};

export default Login;
