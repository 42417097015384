import React from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {apiNewCustomerPost} from "../../../redux/customers/customersAction";
import {useDispatch} from "react-redux";
import {Button, InputAdornment, Stack, TextField} from "@mui/material";
import FormField from "../../../components/FormField/FormField";
import WrapperApp from "../Components/WrapperApp";
import ButtonNewCustomerComponent from "./Components/ButtonNewCustomerComponent";
import ListCustomerComponent from "./Components/ListCustomerComponent";
import FormNewCustomer from "./FormCustomers/FormNewCustomer";
import useAuth from "../../../Hooks/useAuth/useAuth";

const NewCustomerPage = () => {
    const {authId} = useAuth()
    return (
        <WrapperApp indexItemMenu={1}>
            <FormNewCustomer adminId={authId}/>
        </WrapperApp>
    );
};

export default NewCustomerPage;