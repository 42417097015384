import React from 'react';
import {Box} from "@mui/material";

const GradientText = ({children}) => {
    return (
        <Box component={'span'}
             sx={{
                 backgroundImage: 'linear-gradient(90deg, #ffffff, #546E7A 55%)',
                 WebkitBackgroundClip: 'text',
                 WebkitTextFillColor: 'transparent'
             }}
        >
            {children}
        </Box>
    )
}

export default GradientText