import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    apiCustomerEnabledPatch,
    apiGetCustomer
} from "../../../redux/customers/customersAction";
import {Link, useParams} from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import LoadingError from "../../../components/Loading/LoadingError";
import ActivitiesComponent from "./Components/ActivitiesComponent";
import BannerCustomerComponent from "./Components/BannerCustomerComponent";
import WrapperApp from "../Components/WrapperApp";

const ActivitiesCustomerPage = () => {
    const dispatch = useDispatch();
    const params = useParams()
    const customersData = useSelector((state) => state.customersReducer)

    let customer = {}
    let principalAddress = {}
    let secondaryAddress = []
    if (customersData.customers.length > 0) {
        customersData.customers.forEach(element => {
            if (element.id === parseInt(params.id)) {
                customer = element
            }
        })
        customer.profile.addresses.forEach(address => {
            if (address.isPrincipal === true) {
                principalAddress = address
            } else {
                secondaryAddress.push(address)
            }
        })
    }

    useEffect(() => {
        dispatch(apiGetCustomer())
    }, [])


    return (
        <>
            {customersData.loading ? (
                <Loading/>
            ) : customersData.error ? (
                <LoadingError error={customersData.error}/>
            ) : customersData && customer.id && (
                <WrapperApp indexItemMenu={1}>
                    <BannerCustomerComponent customer={customer} />
                    <ActivitiesComponent customerId={params.id}/>
                </WrapperApp>
            )}
        </>

    );
};

export default ActivitiesCustomerPage;
