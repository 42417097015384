import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import {Box, IconButton, Stack, Typography} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {grey} from "@mui/material/colors";

const overlay = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: '1040',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
}

const wrapper = {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1050,
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    outline: 0,
    display: 'flex',
    alignItems: 'center'
}

const modal = {
    zIndex: 100,
    background: '#fff',
    position: 'relative',
    margin: 'auto',
    borderRadius: 5,
    maxWidth: 700,
    width: '80%',
}

const headerStyle = {
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${grey[300]}`,
    paddingX: 3,
    paddingY: 1,
}

const Modal = ({isOpen, hide, header, isCloseButton = true, ...props}) =>

    isOpen ?
        ReactDOM.createPortal(
            <>
                <Box sx={overlay}>
                    <Box sx={wrapper}>
                        <Box sx={modal}>
                            <Stack direction={'row'} sx={headerStyle}>
                                <Box>
                                    <Typography variant={"title1"}>{header}</Typography>
                                </Box>
                                {isCloseButton && (
                                    <IconButton onClick={() => hide()}>
                                        <CloseIcon/>
                                    </IconButton>
                                )}
                            </Stack>
                            <Box sx={{padding: 3}}>{props.children}</Box>
                        </Box>
                    </Box>
                </Box>
            </>
            , document.body) : null;

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    hide: PropTypes.func.isRequired,
}

export default Modal;
