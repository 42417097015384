import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {apiGetCustomer} from "../../../redux/customers/customersAction";
import {useParams} from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import LoadingError from "../../../components/Loading/LoadingError";
import WorkAddressesComponent from "./Components/WorkAddressesComponent";
import {Grid} from "@mui/material";
import BannerCustomerComponent from "./Components/BannerCustomerComponent";
import WrapperApp from "../Components/WrapperApp";
import ContactComponent from "./Components/ContactComponent";

const CardCustomerPage = () => {
    const dispatch = useDispatch();
    const params = useParams()
    const customersData = useSelector((state) => state.customersReducer)

    let customer = {}
    let principalAddress = {}
    let secondaryAddress = []
    if (customersData.customers.length > 0) {
        customersData.customers.forEach(element => {
            if (element.id === parseInt(params.id)) {
                customer = element
            }
        })
        customer.profile.addresses.forEach(address => {
            if (address.isPrincipal === true) {
                principalAddress = address
            } else {
                secondaryAddress.push(address)
            }
        })
    }

    useEffect(() => {
        dispatch(apiGetCustomer())
    }, [])


    return (
        <>
            {customersData.loading ? (
                <Loading/>
            ) : customersData.error ? (
                <LoadingError error={customersData.error}/>
            ) : customersData && customer.id && (
                <WrapperApp indexItemMenu={1}>
                    <BannerCustomerComponent customer={customer}/>
                    <Grid container columnSpacing={{mw0: 2, mw1025: 4}}>
                        <Grid item mw0={12} mw780={7} mw1025={8}>
                            <ContactComponent customerId={params.id}/>
                        </Grid>
                        <Grid item mw0={12} mw780={5} mw1025={4}>
                            <WorkAddressesComponent customerId={params.id}/>
                        </Grid>
                    </Grid>
                </WrapperApp>
            )}
        </>

    );
};

export default CardCustomerPage;
