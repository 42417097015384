import React from 'react';
import {Box, Typography} from "@mui/material";
import {blueGrey, purple} from "@mui/material/colors";

const Text = ({children}) => {
    return (
            <Typography variant={'body1'} component={'p'} sx={{paddingLeft: 4, paddingY:1}} color={blueGrey[700]}>
                {children}
            </Typography>
    );
};

export default Text;