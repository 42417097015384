export default function useMoney() {

    const decimalToEuro = (decimalMoney) => {
        return new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR'
        }).format(decimalMoney)
    }

    return {decimalToEuro}
}