import api from "./api";
import generator from "generate-password-browser";

class CustomersService {
    async getUser() {
        return await api.get("/me");
    }

    async postUser(user) {
        user.password = generator.generate({
            length: 15,
            numbers: true,
            symbols: true,
            strict: true,
        });
        user.profile.color = {
            color: '#' + Math.floor(Math.random() * 16777215).toString(16)
        };
        return await api.post(`/users`, user)
    }

    async patchProfileUser(id, profile) {
        console.log(profile)
        return await api.patch(`/profiles/${id}`, profile, {
            headers: {
                "Content-Type": "application/merge-patch+json",
            },
        });
    }

    async patchUser(id, data) {
        return await api.patch(`/users/${id}`, data, {
            headers: {
                "Content-Type": "application/merge-patch+json",
            },
        });
    }

    async getUserReport(year, userId, isValidated = null) {
        return (await api.get(`/users/${userId}/report/${year}`, {
            params: {
                isValidated: isValidated,
            },
            headers: {
                "Content-Type": "application/json"
            }
        })).data
    }
}

export default new CustomersService();
