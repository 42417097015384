import React from 'react';
import {Link} from "react-router-dom";
import {Box, Grid, Stack, Typography} from "@mui/material";
import {purple} from "@mui/material/colors";

const Footer = () => {
    return (
        <Box maxWidth={'1650px'} component={'footer'} height={'14vh'} marginX={'auto'}>
            <Grid container justifyContent="center" sx={{borderTop: '1px solid', borderBottom: '1px solid',borderColor: 'purple.light', paddingY: 1}}>
                <Grid item mw0={12} mw780={3}>
                    <Stack alignItems={'center'}>
                        <Typography color={purple[800]} sx={{fontSize: '16px', paddingY: 1}}>Rubriques</Typography>
                        <Link to={"/"}><Typography  sx={{fontSize: '14px'}}>Accueil</Typography></Link>
                        <Link to={"/contact"}><Typography  sx={{fontSize: '14px'}}>Contact</Typography></Link>
                    </Stack>
                </Grid>
                <Grid item mw0={12} mw780={8} mw1025={6} mw1250={5}>
                    <Stack alignItems={'center'}>
                        <Grid container justifyContent="center">
                            <Grid item mw0={12}>
                                <Typography textAlign={'center'} color={purple[800]} sx={{fontSize: '16px', paddingY: 1}} >Mes services</Typography>
                            </Grid>
                            <Grid item mw0={12} mw780={6}>
                                <Stack alignItems={'center'}>
                                    <Link to={"/mes-services/aide-a-autonomie"}><Typography sx={{fontSize: '14px'}}>Aide à l'autonomie</Typography></Link>
                                    <Link to={"/mes-services/aide-a-entretien-du-domicile"}><Typography sx={{fontSize: '14px'}}>Aide à l'entretien du domicile</Typography></Link>
                                    <Link to={"/mes-services/aide-aux-repas"}><Typography sx={{fontSize: '14px'}}>Aide aux repas</Typography></Link>
                                    <Link to={"/mes-services/aide-aux-bien-etre"}><Typography sx={{fontSize: '14px'}}>Aide au bien être</Typography></Link>
                                </Stack>
                            </Grid>
                            <Grid item mw0={12} mw780={6}>
                                <Stack alignItems={'center'}>
                                    <Link to={"/mes-services/compagnie"}><Typography sx={{fontSize: '14px'}}>Lutte contre la solitude</Typography></Link>
                                    <Link to={"/mes-services/assistance-administrative"}><Typography sx={{fontSize: '14px'}}>Assistance administrative</Typography></Link>
                                    <Link to={"/mes-services/prise-en-charge-des-enfants-a-domicile"}><Typography sx={{fontSize: '14px'}}>Prise en charge des enfants à domicile</Typography></Link>
                                </Stack>
                            </Grid>
                        </Grid>



                    </Stack>
                </Grid>
            </Grid>
            <Stack alignItems={'center'} sx={{paddingY:1}}>
                <Link to={"/mentions-legales"}><Typography  sx={{fontSize: '14px'}}>Mention légales</Typography></Link>
            </Stack>
        </Box>
    );
};

export default Footer;