import React from 'react';
import {cyan, deepOrange, green} from "@mui/material/colors";
import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import {Link} from "react-router-dom";

const BannerCustomerComponent = ({customer}) => {
    return (
        <Grid container sx={{marginBottom: 2,px: 2, py: 1, backgroundColor: cyan[800]}} alignItems={"center"}>
            <Grid item xs={6} container spacing={2} alignItems={"center"}>
                <Grid item xs={12} md={6}>
                    <Typography
                        variant={"txt2"}>{customer.profile.lastname} {customer.profile.firstname}</Typography>
                    <Typography variant={"txt2"}>{customer.uuid}-{customer.id}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    {customer.enabled ? (
                        <Stack direction={"row"} spacing={1}
                               sx={{color: green["A200"], fontWeight: 700}}>
                            <HowToRegIcon/>
                            <Box>Client actif</Box>
                        </Stack>
                    ) : (
                        <Stack direction={"row"} spacing={1}
                               sx={{color: deepOrange[400], fontWeight: 700}}>
                            <HowToRegIcon/>
                            <span>Client archivé</span>
                        </Stack>
                    )}
                </Grid>
            </Grid>
            <Grid item container xs={6} justifyContent={"flex-end"}>
                <Link to={"/admin/customers"}>
                    <Button variant={"empty"} color={"green"}>Retour</Button>
                </Link>
            </Grid>
        </Grid>
    );
};

export default BannerCustomerComponent;