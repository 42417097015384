import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {logout} from "../../redux/auth/authAction";
import {resetStateUsers} from "../../redux/customers/customersAction";

const Logout = () => {
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (auth.isLoggedIn) {
            dispatch(logout());
            dispatch(resetStateUsers());
        }
        navigate("/");
    }, []);

    return (
        <div>
            <h1>Logout</h1>
        </div>
    );
};

export default Logout;
