import {
    REQUEST_CUSTOMERS_STARTED,
    REQUEST_CUSTOMERS_SUCCESS,
    REQUEST_CUSTOMERS_PATCH_SUCCESS,
    REQUEST_ERROR,
    RESET_STATE_USERS,
    REQUEST_CUSTOMERS_PROFILE_PATCH_SUCCESS,
    REQUEST_CUSTOMERS_ADDRESS_PATCH_SUCCESS,
    REQUEST_CUSTOMERS_POST_SUCCESS,
    REQUEST_CUSTOMERS_ADDRESS_POST_SUCCESS,
    REQUEST_CUSTOMERS_ADDRESS_DELETE_SUCCESS,
    REQUEST_CUSTOMERS_ADDRESS_SET_DEFAULT_SUCCESS,
} from "./customersTypes";

const initialStateUsers = {
    isLoading: false,
    customers: [],
    error: "",
};

const customersReducer = (state = initialStateUsers, action) => {
    switch (action.type) {
        case REQUEST_CUSTOMERS_STARTED:
            return {
                ...state,
                isLoading: true,
            };
        case REQUEST_ERROR:
            return {
                ...state,
                isLoading: false,
                customers: [],
                error: action.payload,
            };
        case RESET_STATE_USERS:
            return {
                ...state,
                isLoading: false,
                customers: [],
                error: "",
            };
        case REQUEST_CUSTOMERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                customers: action.payload,
                error: "",
            };
        case REQUEST_CUSTOMERS_POST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                customers: [...state.customers, action.payload],
                error: ""
            }
        case REQUEST_CUSTOMERS_PATCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                customers: state.customers.map(customer => customer.id === action.payload.id ? action.payload : customer)
            }
        case REQUEST_CUSTOMERS_PROFILE_PATCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                customers: state.customers.map(customer => {
                    if (customer.profile.id === action.payload.id) {
                        customer.profile = action.payload
                    }
                    return customer
                }),
                error: ""
            }
        case REQUEST_CUSTOMERS_ADDRESS_PATCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                customers: state.customers.map(customer => {
                    customer.profile.addresses.map((address, index) => {
                        if (address.id === action.payload.id) {
                            customer.profile.addresses[index] = action.payload
                        }
                    })
                    return customer
                }),
                error: ""
            }
        case REQUEST_CUSTOMERS_ADDRESS_POST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                customers: state.customers.map(customer => {
                    if (customer.profile["@id"] === action.payload.profile["@id"]) {
                        customer.profile.addresses.push(action.payload)
                    }
                    return customer
                }),
                error: ""
            }
        case REQUEST_CUSTOMERS_ADDRESS_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                customers: state.customers.map(customer => {
                    customer.profile.addresses.map((address, index) => {
                        if (address.id === action.payload) {
                            customer.profile.addresses.splice(index, 1)
                        }
                    })
                    return customer
                }),
            }
        case REQUEST_CUSTOMERS_ADDRESS_SET_DEFAULT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                customers: state.customers.map(customer => {
                    customer.profile.addresses.map((address, index) => {
                        if (address.id === action.payload.id) {
                            customer.profile.addresses[index].isDefault = true
                        } else {
                            customer.profile.addresses[index].isDefault = false
                        }
                    })
                    return customer
                }),
            }
        default:
            return state;
    }
};

export default customersReducer;
