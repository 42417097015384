import {useSelector} from "react-redux";

export default function useAuth() {
    const auth = useSelector((state) => state.auth);
    const authIsLoggedIn = auth.isLoggedIn
    const authProfile = auth.user !== null ? auth.user.user.profile : null
    const authFirstname = auth.user !== null ? auth.user.user.profile.firstname : null
    const authLastname = auth.user !== null ? auth.user.user.profile.lastname : null
    const authId = auth.user !== null ? auth.user.user.id : null

    let authRole = null
    if (auth.user !== null && auth.user.user.roles.includes('ROLE_ADMIN')) {
        authRole = 'ROLE_ADMIN'
    } else if (auth.user !== null && !auth.user.user.roles.includes('ROLE_ADMIN')) {
        authRole = 'ROLE_USER'
    }

    return {authIsLoggedIn, authRole, authProfile, authFirstname, authLastname, authId}
}