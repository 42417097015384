import React from "react";
import {createRoot} from "react-dom/client";
import App from "./App";
import {Provider} from "react-redux";
import setupInterceptors from "./services/setupInterceptors";
import store from "./redux/store";
import {BrowserRouter} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import {Theme} from "./style/theme";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

const root = createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <BrowserRouter>
            <ThemeProvider theme={Theme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <App/>
                </LocalizationProvider>
            </ThemeProvider>
        </BrowserRouter>
    </Provider>
    // </React.StrictMode>
);
setupInterceptors(store);
