import React from 'react';
import {Box, List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import Main from "../Main/Main";
import Header from "../Header/Header";
import Heading1 from "./Heading1";
import Heading2 from "./Heading2";
import Text from "./Text";
import Subtitle1 from "./Subtitle1";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import TemplatePage from "../Template/TemplatePage";
import Container from "../Template/Container";

const PrivacyPolicy = () => {

    return (
        <TemplatePage>
            <Container maxWidth={'1200px'}>
                <Box sx={{paddingX: 4}}>
                    <Box sx={{paddingBottom: 1}}>
                        <Heading1>Mentions légales</Heading1>
                        <Text>
                            Merci de lire avec attention les différentes modalités d’utilisation du présent site.<br/>
                            Il est porté à la connaissance des utilisateurs et visiteurs du site « christelle-jeandenans.fr » les présentes mentions légales.<br/>
                            En parcourant les différentes pages de ce site, vous acceptez,  sans réserves les présentes modalités.
                        </Text>
                    </Box>
                    <Box sx={{paddingBottom: 2}}>
                        <Heading2>Informations légales</Heading2>
                        <Text>
                            Conformément à l’article n°6 de la Loi n°2004-575 du 21 Juin 2004 pour la confiance dans l’économie numérique, les responsables du présent site internet christelle-jeandenans.fr sont :
                        </Text>
                        <Subtitle1>Site</Subtitle1>
                        <Text>
                            Christelle Jeandenans<br/>
                            Email de contact : contact@christelle-jeandenans.fr
                        </Text>
                    </Box>
                    <Box sx={{paddingBottom: 2}}>
                        <Subtitle1>Hébergement</Subtitle1>
                        <Text>
                            Hébergeur : o2switch<br/>
                            Adresse: 222-224 Boulevard Gustave Flaubert 63000 Clermont-Ferrand<br/>
                            Site Web : <a href={"https://www.o2switch.fr"} target={"_blank"}>https://www.o2switch.fr</a>
                        </Text>
                    </Box>
                    <Box sx={{paddingBottom: 2}}>
                        <Heading2>Accessibilité</Heading2>
                        <Subtitle1>Indisponibilté</Subtitle1>
                        <Text>
                            Le Site est par principe accessible aux utilisateurs 24/24h et 7/7j, sauf interruption, programmée ou non, pour des besoins de maintenance ou en cas de force majeure.En cas d’impossibilité d’accès au Site, le Site ne saurait être tenu pour responsable de tout dommage, quelle qu’en soit la nature, résultant de son indisponibilité.
                        </Text>
                        <Subtitle1>Navigateurs</Subtitle1>
                        <Text>
                            Ce site est édité avec différents langages web (HTML, HTML5, Javascript, CSS, etc…)<br/>
                            Pour un meilleur confort d'utilisation, nous vous préconisons d'utiliser des navigateurs modernes.<br/>
                            Dans le cas d'un disfonctionnement, le site ne saurait être tenu pour responsable de tout dommage, quelle qu’en soit la nature.
                        </Text>
                    </Box>
                    <Box sx={{paddingBottom: 2}}>
                        <Heading2>Conditions d'utilisations</Heading2>
                        <Subtitle1>Propriété intellectuelle</Subtitle1>
                        <Text>
                            Tout le contenu du site christelle-jeandenans, incluant les graphsimes, images, textes, logos, sont la propriété exclusives de l'éditeur.<br/>
                            Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite<br/>
                            Cette représentation ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle. Le non-respect de cette interdiction constitue une contrefaçon.<br/>
                        </Text>
                        <Subtitle1>Données personnelles</Subtitle1>
                        <Text>
                            Vous êtes informé de la collecte de données à caractère personnel par christelle-jeandenans.fr.<br/>
                            Vous autorisez christelle-jeandenans.fr, en qualité de responsable de traitement, à collecter les données à caractère personnel vous concernant lors de votre navigation sur le Site.
                        </Text>
                        <Text>
                            De manière générale, vous n’êtes pas tenu de nous communiquer vos données personnelles lorsque vous visitez notre site.
                            Néanmoins, vous pouvez être amenés à nous communiquer certaines informations telles que : votre nom, votre adresse électronique ou encore votre numéro de téléphone lors de l'utilisation du formulaire de contact. Vous pouvez refuser de fournir ces données. Dans ce cas vous ne pourrez pas utiliser le formulaire de contact.<br/>
                        </Text>
                        <Text>
                            Vos données sont traitées principalement pour les finalités suivantes :
                            <List>
                                <ListItem>
                                    <ListItemIcon><FiberManualRecordIcon/></ListItemIcon>
                                    <ListItemText primary={"Répondre à vos demandes"}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><FiberManualRecordIcon/></ListItemIcon>
                                    <ListItemText primary={"Envoyer des offres commerciales et des newsletters aux prospects/clients"}/>
                                </ListItem>
                            </List>
                        </Text>
                        <Subtitle1>Politique de cookies</Subtitle1>
                        <Text>
                            Un cookie est une information déposée sur votre disque dur par le serveur du site que vous visitez.<br/>
                            Il contient plusieurs données qui sont stockées sur votre ordinateur dans un simple fichier texte auquel un serveur accède pour lire et enregistrer des informations . Certaines parties de ce site ne peuvent être fonctionnelles sans l’acceptation de cookies.<br/>
                            Les cookies déposés sur le terminal de l’internaute sont conservés pendant une durée de 13 mois à compter de leur 1er dépôt dans l’équipement terminal de l’utilisateur (faisant suite à l’expression du consentement).
                        </Text>
                        <Subtitle1>Lien hypertextes</Subtitle1>
                        <Text>
                            Le site peut offrir des liens vers d’autres sites internet ou d’autres ressources disponibles sur Internet. christelle-jeandenans.fr ne dispose d'aucun moyen pour contrôler ces sites.<br/>
                            christelle-jeandenans.fr ne peut être tenue pour responsable de tout dommage, de quelque nature que ce soit, résultant du contenu de ces sites, et notamment des informations, produits ou services qu’ils proposent, ou de tout usage qui peut être fait de ces éléments.<br/>
                            Les risques liés à cette utilisation incombent pleinement à l’internaute, qui doit se conformer à leurs conditions d’utilisation.<br/>
                            Les utilisateurs, du site christelle-jeandenans.fr  ne peuvent pas mettre en place un hyperlien en direction de ce site sans l’autorisation expresse et préalable de christelle-jeandenans.fr.
                            Dans l’hypothèse où un utilisateur souhaiterait mettre en place un hyperlien en direction du site christelle-jeandenans.fr il lui appartiendra d’adresser un email accessible sur le site afin de formuler sa demande de mise en place d’un hyperlien à l'adresse de contact cité ci-dessus.
                            christelle-jeandenans.fr se réserve le droit d’accepter ou de refuser un hyperlien sans avoir à en justifier sa décision.
                        </Text>
                    </Box>
                    <Box sx={{paddingBottom: 2}}>
                        <Heading2>Juridiction</Heading2>
                        <Subtitle1>Déclaration CNIL</Subtitle1>
                        <Text>
                            Conformément à la loi 78-17 du 6 janvier 1978 (modifiée par la loi 2004-801 du 6 août 2004 relative à la protection des personnes physiques à l'égard des traitements de données à caractère personnel) relative à l'informatique, aux fichiers et aux libertés, ce site n'a pas fait l'objet d'une déclaration auprès de la Commission nationale de l'informatique et des libertés (www.cnil.fr).
                        </Text>
                        <Subtitle1>Vos droits relatifs à la collecte et au traitement de vos données à caractère personnel</Subtitle1>
                        <Text>
                            Vous disposez d’un droit d’accès, de rectification, d’effacement et à la portabilité de vos données que vous pouvez exercer auprès de christelle-jeandenans.fr par mail à contact@christelle-jeandenans.fr
                        </Text>
                        <Subtitle1>Litiges</Subtitle1>
                        <Text>
                            Toutes difficultés, réclamations ou litiges concernant le présent site sera régi par la loi française. En cas de litige, les tribunaux seront seuls compétents.
                        </Text>
                    </Box>
                    <Box sx={{paddingBottom: 2}}>
                        <Heading2>Contact</Heading2>
                        <Text>
                            Pour tout signalement de contenus ou d’activités illicites, l’Utilisateur peut contacter l’Éditeur à l’adresse suivante : contact@christelle-jeandenans.fr
                        </Text>
                    </Box>
                </Box>

            </Container>
        </TemplatePage>
    );
};

export default PrivacyPolicy;