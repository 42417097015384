import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {apiCustomerEnabledPatch, apiGetCustomer} from "../../../../redux/customers/customersAction";
import Loading from "../../../../components/Loading/Loading";
import LoadingError from "../../../../components/Loading/LoadingError";
import {
    FormControl, FormControlLabel, FormLabel, Menu, MenuItem,
    Paper, Radio, RadioGroup, Stack,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {cyan, green, red} from "@mui/material/colors";
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import GroupRemoveOutlinedIcon from '@mui/icons-material/GroupRemoveOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
const ListCustomerComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const customersData = useSelector((state) => state.customersReducer)
    const [list, setList] = useState([])
    const [value, setValue] = useState('enabled');
    const [radio, setRadio] = useState(false)

    const handleChangeRadio = (e) => {
        setValue(e.target.value);
        if (e.target.value === 'enabled') {
            setList(
                customersData.customers.filter((customer) => customer.enabled === true)
            )
        } else if (e.target.value === 'disabled') {
            setList(
                customersData.customers.filter((customer) => customer.enabled === false)
            )
        } else if (e.target.value === 'all') {
            setList(
                customersData.customers
            )
        }
    }

    const handleChangeList = () => {
        if (value === 'enabled') {
            setList(
                customersData.customers.filter((customer) => customer.enabled === true)
            )
        } else if (value === 'disabled') {
            setList(
                customersData.customers.filter((customer) => customer.enabled === false)
            )
        } else if (value === 'all') {
            setList(
                customersData.customers
            )
        }
    }

    const handleEnabled = (customerId, enabled) => {
        const data = {enabled: enabled};
        dispatch(apiCustomerEnabledPatch(customerId, data));
        handleChangeList()
    };

    const  Row = (props) => {
        const {customer, func} = props

        let addressPrincipal = {}
        customer.profile.addresses.forEach(address => {
            if (address.isPrincipal === true) {
                addressPrincipal = address
            }
        })

        return (
            <TableRow  key={customer.id} hover style={{cursor: "pointer"}}>
                <TableCell >
                   <Actions customer={customer} func={func} />
                </TableCell>
                <TableCell sx={{display: {mw0: 'none', mw1025: 'table-cell'}}}><Typography variant={'tableRows'}>{customer.uuid}</Typography></TableCell>
                <TableCell>
                    <Stack>
                        <Typography variant={'tableRows'}>{customer.profile.lastname}</Typography>
                        <Typography variant={'tableRows'}>{customer.profile.firstname}</Typography>
                    </Stack>
                </TableCell>
                <TableCell align={'center'}>
                    <Stack>
                        <Stack direction={'row'} alignItems={'center'} sx={{display: customer.profile.fixNumber ? 'flex' : 'none'}}>
                            <LocalPhoneOutlinedIcon fontSize={'13px'} sx={{color: cyan[800], marginRight: 1}}/>
                            <Typography variant={'tableRows'}>{customer.profile.fixNumber}</Typography>
                        </Stack>
                        <Stack direction={'row'} alignItems={'center'} sx={{display: customer.profile.mobileNumber ? 'flex' : 'none'}}>
                            <SmartphoneOutlinedIcon fontSize={'13px'} sx={{color: cyan[800], marginRight: 1}}/>
                            <Typography variant={'tableRows'}>{customer.profile.mobileNumber}</Typography>
                        </Stack>
                    </Stack>
                </TableCell>
                <TableCell sx={{display: {mw0: 'none', mw780: 'table-cell'}}}><Typography variant={'tableRows'}>{customer.profile.email}</Typography></TableCell>
                <TableCell sx={{display: {mw0: 'none', mw780: 'table-cell'}}}>
                    <Stack>
                        <Typography variant={'tableRows'}>{addressPrincipal.address}</Typography>
                        <Typography variant={'tableRows'}>{addressPrincipal.zip} {addressPrincipal.city}</Typography>
                    </Stack>
                </TableCell>
                <TableCell sx={{display: {mw0: 'none', mw780: 'table-cell'}}}><Typography variant={'tableRows'}>{customer.profile.price}&euro;</Typography></TableCell>
                <TableCell align={'center'}><Typography variant={'tableRows'} color={customer.enabled ? green[800] : red[800]}>{customer.enabled ? 'Oui' : 'Non'}</Typography></TableCell>
            </TableRow>
        )
    }

    const Actions = ({customer, func}) => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <>
                <KeyboardArrowDownIcon
                    id="demo-positioned-button"
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    fontSize={'small'}
                />
                <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => {navigate(`/admin/customers/${customer.id}`)}} dense divider><Stack direction={'row'} spacing={1}><EditOutlinedIcon fontSize={'small'}/><Typography fontSize={'14px'}>Voir</Typography></Stack></MenuItem>

                    {customer.enabled ? (
                        <MenuItem onClick={() => {
                            func(customer.id, !customer.enabled)
                        }} dense divider><Stack direction={'row'} spacing={1}><GroupRemoveOutlinedIcon fontSize={'small'}/><Typography fontSize={'14px'}>Archiver</Typography></Stack></MenuItem>
                    ) : (
                        <MenuItem onClick={() => {
                            func(customer.id, !customer.enabled)
                    }} dense divider><Stack direction={'row'} spacing={1}><GroupAddOutlinedIcon fontSize={'small'}/><Typography fontSize={'14px'}>Activer</Typography></Stack></MenuItem>
                    )}

                    <MenuItem onClick={() => {navigate(`/admin/customers/activities/${customer.id}`)}} dense divider><Stack direction={'row'} spacing={1}><WorkHistoryOutlinedIcon fontSize={'small'}/><Typography fontSize={'14px'}>Historique des activités</Typography></Stack></MenuItem>
                </Menu>
            </>
        )
    }

    useEffect(() => {
        customersData.customers.length === 0 && dispatch(apiGetCustomer())
    }, [])

    useEffect(() => {
        if (customersData.customers.filter((customer) => customer.enabled === true).length > 0 && customersData.customers.filter((customer) => customer.enabled === false).length > 0) {
            setRadio(true)
            if (customersData.customers.length > 0) {
               handleChangeList()
            }
        } else {
            setRadio(false)
            setValue('enabled')
            setList(
                customersData.customers
            )
        }


    }, [customersData.customers])

    return (
        <>
            {customersData.isLoading ? (
                <Loading/>
            ) : customersData.error ? (
                <LoadingError error={customersData.error} txt={"Section Liste clients"}/>
            ) : customersData.customers.length > 0 && list.length > 0 && (
                <>
                    <Paper sx={{ width: '100%' }}>
                        {radio === true && (
                            <FormControl sx={{padding: 2}}>
                                <FormLabel id="demo-controlled-radio-buttons-group"><Typography fontSize={'14px'} color={'blueGrey.dark'}>Affichage</Typography></FormLabel>
                                <RadioGroup
                                    row
                                    name="controlled-radio-buttons-group"
                                    value={value}
                                    onChange={handleChangeRadio}
                                >
                                    <FormControlLabel value="enabled" control={<Radio size={'small'}/>} label={<Typography fontSize={'14px'} color={'blueGrey.dark'}>Actif</Typography>} />
                                    <FormControlLabel value="disabled" control={<Radio />} label={<Typography fontSize={'14px'} color={'blueGrey.dark'}>Inactif</Typography>} />
                                    <FormControlLabel value="all" control={<Radio />} label={<Typography fontSize={'14px'} color={'blueGrey.dark'}>Tout</Typography>} />
                                </RadioGroup>
                            </FormControl>
                        )}
                        <TableContainer>
                            <Table stickyHeader size={"small"}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell sx={{display: {mw0: 'none', mw1025: 'table-cell'}}}><Typography  variant={'tableHead'}>N° de client</Typography></TableCell>
                                        <TableCell><Typography  variant={'tableHead'}>Nom</Typography></TableCell>
                                        <TableCell><Typography  variant={'tableHead'}>Téléphone</Typography></TableCell>
                                        <TableCell sx={{display: {mw0: 'none', mw780: 'table-cell'}}}><Typography  variant={'tableHead'}>Mail</Typography></TableCell>
                                        <TableCell sx={{display: {mw0: 'none', mw780: 'table-cell'}}}><Typography  variant={'tableHead'}>Adresse</Typography></TableCell>
                                        <TableCell sx={{display: {mw0: 'none', mw780: 'table-cell'}}}><Typography  variant={'tableHead'}>Taux horaire</Typography></TableCell>
                                        <TableCell style={{paddingLeft: 0}} align={'center'}><Typography  variant={'tableHead'}>Actif</Typography></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {list.map(customer => {
                                        return (
                                            <Row key={customer.id} customer={customer} func={handleEnabled}/>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </>
            )}
        </>
    );
};
export default ListCustomerComponent;
