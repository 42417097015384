const data = [
    {
        id: 4,
        title: "Stimuler, Encourager, Valoriser",
        content: [
            "Je stimule,  j’encourage dans toutes les tâches quotidiennes la personne aidée tout en étant vigilante à sa sécurité.",
            "Valoriser, préserver l’autonomie des personnes améliore le confort de vie et favorise le maintien à domicile."
        ]
    },
    {
        id: 6,
        title: "Bienveillance, Bientraitance",
        content: [
            "J'accompagne les personnes aidées avec respect, douceur, patience et une attention positive.",
            "Je préserve l'intimité des personnes aidées."
        ]
    },
    {
        id: 2,
        title: 'Suivi personalisé',
        content: [
            "Pour garantir une transmission de l'information optimale et assurer un accompagnement de qualité adapté aux évolutions des besoins, je mets en place un cahier de liaison ce qui permet d’ échanger avec les proches, les autres prestataires de services et les professionnels de santé.",
            "A chaque fin d’intervention chez la personne aidée, je rend compte  de mon travail oralement ou par écrit sur le cahier de liaison"
        ]
    },
    {
        id: 1,
        title: 'Qualité de service',
        content: [
            "J'adapte et j'anticipe mes activités en fonctions du degré de dépendances des personnes aidées et du matériel",
            "Je respecte les opinions, les croyances et les habitudes de vie des personnes aidées"
        ]
    },
    {
        id: 3,
        title: 'Ecoute',
        content: ["Je tiens compte des attentes, des besoins, des consignes de la personne aidée et de leur entourage."]
    },
    {
        id: 5,
        title: "Discretion professionnelle",
        content: [
            "Je ne divulgue pas les informations d'ordre privé et médical des personnes aidées."
        ]
    }
]

export default data