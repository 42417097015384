import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {apiGetCustomer, setDefaultAddress} from "../../../../redux/customers/customersAction";
import Loading from "../../../../components/Loading/Loading";
import LoadingError from "../../../../components/Loading/LoadingError";
import useModal from "../../../../Hooks/useModal/useModal";
import {
    Box,
    Button,
    Grid, IconButton,
    Paper, Stack,
    Typography
} from "@mui/material";
import Modal from "../../../../components/Modal/ModalComponent";
import StopIcon from "@mui/icons-material/Stop";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarIcon from '@mui/icons-material/Star';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import FormAddressPatch from "../FormCustomers/FormAddressPatch";
import FormAddressAdd from "../FormCustomers/FormAddressAdd";
import {yellow} from "@mui/material/colors";
const CardAddress = ({address}) => {
    const [editAddress, setEditAddress]=useState(false)
    const dispatch = useDispatch();
    const handleEditAddress = () => {
        setEditAddress(!editAddress)
    }
    const handleIsDefault = (address, e) => {
        dispatch(setDefaultAddress(address.id));
    }
    return (
        <>
            {address && (
                <Grid item mw0={12}>
                    <Paper sx={{padding: 1}} elevation={2}>
                        <Box paddingX={2} marginBottom={2} sx={{'&:hover':{'& .iconButton': {visibility: 'visible'}}}}>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <Stack direction={'row'}>
                                    <LocationOnIcon fontSize={'small'} sx={{marginRight: 1, color: 'lightGreen.dark'}}/><Typography variant={"title2"}>Adresse-{address.id}</Typography>
                                </Stack>
                                <Stack direction={'row'}>
                                    <IconButton sx={{visibility: 'hidden', display: editAddress ? 'none' : address.isDefault ? 'none' : 'flex' }} className={'iconButton'} onClick={ () => handleIsDefault(address) }>
                                        <StarOutlineIcon fontSize={'small'}/>
                                    </IconButton>
                                    <IconButton sx={{visibility: 'hidden', display: editAddress ? 'none' : 'flex' }} className={'iconButton'} onClick={handleEditAddress}>
                                        <CreateOutlinedIcon fontSize={'small'}/>
                                    </IconButton>
                                </Stack>
                            </Stack>
                            <Typography  variant={"txt1"} sx={{display: editAddress ? 'none' : 'block' }}>
                                {address.address}
                            </Typography>
                            <Typography variant={"txt1"} sx={{display: editAddress ? 'none' : 'block' }}>
                                {address.addressComplement}
                            </Typography>
                            <Stack direction={"row"} spacing={1}>
                                <Typography variant={"txt1"} sx={{display: editAddress ? 'none' : 'block' }}>
                                    {address.zip}
                                </Typography>
                                <Typography variant={"txt1"} sx={{display: editAddress ? 'none' : 'block' }}>
                                    {address.city}
                                </Typography>
                            </Stack>
                            <Stack marginY={2} direction={'row'} sx={{display: editAddress ? 'none' : 'flex' }}>
                                <StarIcon fontSize={'small'} sx={{display: address.isDefault ? 'flex' : 'none' ,marginRight: 1, color: yellow[700]}}/>
                                <Typography variant={"addrDefault"} sx={{display: editAddress ? 'none' : 'block' }}>
                                    {address.isDefault && 'Adresse par défaut'}
                                </Typography>
                            </Stack>
                            <Box sx={{display: editAddress ? 'block' : 'none' }}>
                                <FormAddressPatch address={address} handleEditAddress={handleEditAddress} />
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            )}
        </>
    );
};
const WorkAddressesComponent = ({customerId}) => {
    const dispatch = useDispatch();
    const {isOpen, toggle} = useModal();
    const customersData = useSelector((state) => state.customersReducer)
    const [customer, setCustomer] = useState([])
    const [secondaryAddress, setSecondaryAddress] = useState([])
    const handleCustomer = (data) => {
        if (data.customers.length > 0) {
            data.customers.forEach(element => {
                if (element.id === parseInt(customerId)) {
                    setCustomer(element)
                    handleSecondaryAddress(element)
                }
            })
        }
    }
    const handleSecondaryAddress = (customer) => {
        let array = []
        if (customer.profile) {
            customer.profile.addresses.forEach(address => {
                if (address.isPrincipal === false) {
                    array.push(address)
                }
            })
            setSecondaryAddress(array)
        }
    }

    useEffect(() => {
        if (customersData.customers.length === 0) {
            dispatch(apiGetCustomer())
        } else if (customersData.customers.length > 0) {
            handleCustomer(customersData)
        }
    }, [])

    useEffect(() => {
        handleCustomer(customersData)
    }, [customersData])

    return (
        <>
            {customersData.loading ? (
                <Loading/>
            ) : customersData.error ? (
                <LoadingError error={customersData.error} txt={"Section adresses de travail"}/>
            ) : customer.profile && (
                <>
                    <Grid container>
                        <Grid item mw0={12}>
                            <Stack direction={'row'} alignItems={'center'}>
                                <StopIcon fontSize={'small'} sx={{color: 'purple.light'}} /><Typography variant={"cardTitle"}>Adresses de travail</Typography>
                            </Stack>
                            <Box sx={{my: 1}}>
                                <Button variant={"empty"} color={"purple"} onClick={() => {toggle()}}>Ajouter une adresse</Button>
                            </Box>
                        </Grid>
                        {secondaryAddress.sort((a,b)=> {
                            if (a.isDefault < b.isDefault) {
                                return 1
                            }
                            if (a.isDefault > b.isDefault) {
                                return -1
                            }
                        }).map(address => {
                            return (
                                <CardAddress address={address}/>
                            )
                        })}
                    </Grid>
                    <Modal hide={toggle} isOpen={isOpen} header={"Nouvelle adresse"}>
                        <FormAddressAdd idProfile={customer.profile.id} toggle={toggle}/>
                    </Modal>
                </>
            )}
        </>
    );
};

export default WorkAddressesComponent;
