export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const LOGOUT = "LOGOUT";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const REQUEST_AUTH_PROFILE_PATCH_SUCCESS = "REQUEST_AUTH_PROFILE_PATCH_SUCCESS"
export const REQUEST_AUTH_PROFILE_SUCCESS = "REQUEST_AUTH_PROFILE_SUCCESS"
export const REQUEST_AUTH_ADDRESS_SUCCESS = "REQUEST_AUTH_ADDRESS_SUCCESS"
