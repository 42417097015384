class TokenService {
  constructor() {
    this.remember = !!localStorage.getItem("user");
    this.user = JSON.parse(
      localStorage.getItem("user") ?? sessionStorage.getItem("user")
    );
  }
  getLoclRefreshToken() {
    return this.user?.refresh_token;
  }
  getLoclAccessToken() {
    return this.user?.token;
  }

  updateLocalAccessToken(token) {
    this.user.token = token;
    if(this.remember) {
        localStorage.setItem("user", JSON.stringify(this.user));
    } else {
        sessionStorage.setItem("user", JSON.stringify(this.user));
    }
  }

  getUser() {
    return this.user;
  }

  setUser(user, remember) {
    this.user = user;
    this.remember = remember;
    if (remember) {
      localStorage.setItem("user", JSON.stringify(this.user));
    } else {
      sessionStorage.setItem("user", JSON.stringify(this.user));
    }
  }

  removeUser() {
    localStorage.removeItem("user");
    sessionStorage.removeItem("user");
  }
}

export default new TokenService();
