import React from 'react';
import {Typography} from "@mui/material";

const H2 = ({children, color="#000000"}) => {
    return (
        <Typography variant={'H2'} color={color} component={'h2'}>
            {children}
        </Typography>
    );
};

export default H2;