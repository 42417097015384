import React, {useEffect} from 'react';
import TemplatePage from "../../Template/TemplatePage";
import Container from "./Container";
import Title from "./Title";
import H2 from "../../Components/Typographies/H2";
import H3 from "../../Components/Typographies/H3";
import {Stack} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Paragraph from "./Paragraph";

const AssistanceAdministrativePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <TemplatePage>
            <Container title={'Assistance à Domicile'}>
                <Title>
                    <H2 color={'pink.light'}>Assitance administrative</H2>
                </Title>
                <Title>
                    <H3 color={'blueGrey.dark'}>
                        <Stack direction={'row'} alignItems={'center'} component={'span'} lineHeight={'25px'}>
                            <FiberManualRecordIcon sx={{verticalAlign: 'middle', marginRight: 1, fontSize: '16px'}} />
                            Démarche administrative
                        </Stack>
                    </H3>
                </Title>
                <Paragraph>
                    J’aide et suit la personne âgée à établir un dossier auprès d'une administration, gestion des démarches d'obtention d'aides financières comme l'APA et la constitution de dossiers administratifs.
                </Paragraph>
                <Title>
                    <H3 color={'blueGrey.dark'}>
                        <Stack direction={'row'} alignItems={'center'} component={'span'} lineHeight={'25px'}>
                            <FiberManualRecordIcon sx={{verticalAlign: 'middle', marginRight: 1, fontSize: '16px'}} />
                            Rédaction du courier
                        </Stack>
                    </H3>
                </Title>
                <Paragraph>
                    Je peux  aider à l'ouverture du courrier quotidien. Je me charge, quand ceci n'est plus possible, d'y répondre avec le bénéficiaire.
                </Paragraph>
                <Title>
                    <H3 color={'blueGrey.dark'}>
                        <Stack direction={'row'} alignItems={'center'} component={'span'} lineHeight={'25px'}>
                            <FiberManualRecordIcon sx={{verticalAlign: 'middle', marginRight: 1, fontSize: '16px'}} />
                            Prise de rendez-vous
                        </Stack>
                    </H3>
                </Title>
                <Paragraph>
                    Je prends des rendez-vous médicaux ou de loisirs et j’aide à la gestion du planning.
                </Paragraph>
            </Container>
        </TemplatePage>
    )
}

export default AssistanceAdministrativePage