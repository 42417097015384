import React from 'react';
import {Box} from "@mui/material";

const Container = ({maxWidth="100%", component='div', children}) => {

    return (
        <Box component={component} sx={{maxWidth: maxWidth, marginX: 'auto'}}>
            {children}
        </Box>
    );
};

export default Container;