import React from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {Button, Stack, TextField} from "@mui/material";
import {apiCustomerAddressPatch} from "../../../redux/customers/customersAction";
import FormField from "../../../components/FormField/FormField";
import {apiPatchAuthAddress} from "../../../redux/auth/authAction";

const FormAddress = ({address, fetch, idProfile, toggle}) => {
    const dispatch = useDispatch()

    const form = useForm({
        defaultValues: {
            address: address.address,
            addressComplement: address.addressComplement,
            city: address.city,
            country: address.country,
            zip: address.zip,
        }
    })
    const formSubmit = (data, e) => {
        e.preventDefault()
        dispatch(apiPatchAuthAddress(address.id, data));
        toggle();
    }
    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(formSubmit)}>
                <Stack spacing={3}>
                    <FormField
                        Component={TextField}
                        name={"address"}
                        label={'Adresse'}
                        rules={{required: "Veuillez renseigner l'adresse"}}
                    />
                    <FormField
                        Component={TextField}
                        name={"addressComplement"}
                        label={"Complément d'adresse"}
                    />
                    <FormField
                        Component={TextField}
                        name={"zip"}
                        label={'Code postal'}
                        rules={{
                            pattern: {
                                value: /^[0-9]{5}$/i,
                                message: "Veuillez saisir un code postal valide",
                            },
                            required: "Veuillez renseigner le code postal",
                        }}
                        inputProps={{maxLength: 5}}
                    />
                    <FormField
                        Component={TextField}
                        name={"city"}
                        label={'Ville'}
                        rules={{required: "Veuillez renseigner la ville"}}
                    />
                    <FormField
                        Component={TextField}
                        name={"country"}
                        label={"Pays"}
                    />
                    <div>
                        <Button type={"submit"} variant="dark" color="purple">Enregistrer</Button>
                    </div>
                </Stack>
            </form>
        </FormProvider>
    );
};

export default FormAddress;
