import React from 'react';
import {Box} from "@mui/material";
import Image from '../img/img-2-350x622.jpg'

const Banner = ({children}) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: {mw0: 'column', mw780: 'row'},
            backgroundColor: '#FFFFFF',
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
            position: 'relative',
            height: {mw0: '130px', mw780: '150px'},
            '&:before': {
                mw0: {
                    content: '""',
                    position: 'absolute',
                    width: '100%',
                    height: '130px',
                    backgroundImage: `url(${Image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center left',
                    backgroundSize: 'cover',
                    opacity: 0.4
                },
                mw780: {
                    display: 'none'
                }
            }
        }}>
            {children}
        </Box>
    )
}

export default Banner