import React, {useState} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {apiNewCustomerPost} from "../../../../redux/customers/customersAction";
import {useDispatch} from "react-redux";
import {
    Alert,
    Box,
    Button,
    Grid,
    InputAdornment,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import FormField from "../../../../components/FormField/FormField";
import PersonIcon from "@mui/icons-material/Person";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import EuroIcon from "@mui/icons-material/Euro";
import CheckIcon from '@mui/icons-material/Check';
import CheckboxGroup from "../../../../components/CheckboxGroup/CheckboxGroup";
import Modal from "../../../../components/Modal/ModalComponent";
import useModal from "../../../../Hooks/useModal/useModal";
import {useNavigate} from "react-router-dom";

const BoxChield = ({children}) => {
    return (
        <Box paddingBottom={2}>
            {children}
        </Box>
    );
};

const FormNewCustomer = ({adminId}) => {
    const dispatch = useDispatch()
    const {isOpen, toggle} = useModal()
    const navigate = useNavigate()
    const [similarAddress, setSimilarAddress] = useState(false)
    console.log(similarAddress)
     const handleSimilarAddress = () => {
        setSimilarAddress(!similarAddress)
     }
    const form = useForm({
        defaultValues: {
            profile: {
                price: 0,
                fixNumber: "",
                mobileNumber: "",
                email: "",
                firstname: "",
                lastname: "",

            },
            address: {
                country: "France",
                address: "",
                addressComplement: "",
                city: "",
                zip: "",
                isPrincipal: true,
                isDefault: false
            },
            workAddress: {
                country: "France",
                address: "",
                addressComplement: "",
                city: "",
                zip: "",
                isPrincipal: false,
                isDefault: true
            }
        }
    })
    const onSubmit = (data, e) => {
        if (similarAddress) {
            data.workAddress.address = data.address.address
            data.workAddress.addressComplement = data.address.addressComplement
            data.workAddress.zip = data.address.zip
            data.workAddress.city = data.address.city
            data.workAddress.country = data.address.country
        }
        e.preventDefault()
        data.profile.price = parseFloat(data.profile.price)
        console.log(data)
        dispatch(apiNewCustomerPost(data, adminId))
        toggle()
    };
    return (
        <FormProvider {...form}>
            <Paper sx={{padding:2}}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Grid container columnSpacing={3}>
                        <Grid item mw0={12} mw780={6} mw1025={4}>
                            <BoxChield>
                                <Stack direction={'row'} marginBottom={1}>
                                    <PersonIcon fontSize={'small'} sx={{marginRight: 1, color: 'lightGreen.dark'}}/><Typography variant={"title2"}>Nom</Typography>
                                </Stack>
                                <Stack rowGap={1}>
                                    <FormField
                                        Component={TextField}
                                        name={"profile.lastname"}
                                        label={'Nom'}
                                        rules={{required: "Veuillez renseigner le nom du client"}}
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                fontSize: '13px',
                                            },
                                            '& .MuiFormLabel-root': {
                                                fontSize: '13px',
                                            },
                                            width: '100%'
                                        }}
                                    />
                                    <FormField
                                        Component={TextField}
                                        name={"profile.firstname"}
                                        label={'Prénom'}
                                        rules={{required: "Veuillez renseigner le prénom du client"}}
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                fontSize: '13px',
                                            },
                                            '& .MuiFormLabel-root': {
                                                fontSize: '13px',
                                            },
                                            width: '100%'
                                        }}
                                    />
                                </Stack>
                            </BoxChield>
                            <BoxChield>
                                <Stack direction={'row'} marginBottom={1}>
                                    <LocationOnIcon fontSize={'small'} sx={{marginRight: 1, color: 'lightGreen.dark'}}/><Typography variant={"title2"}>Adresse de facturation</Typography>
                                </Stack>
                                <Stack rowGap={1}>
                                    <FormField
                                        Component={TextField}
                                        name={"address.address"}
                                        label={'Adresse'}
                                        rules={{required: "Veuillez renseigner une adresse"}}
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                fontSize: '13px',
                                            },
                                            '& .MuiFormLabel-root': {
                                                fontSize: '13px',
                                            },
                                            width: '100%'
                                        }}
                                    />
                                    <FormField
                                        Component={TextField}
                                        name={"address.addressComplement"}
                                        label={"Complément d'adresse"}
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                fontSize: '13px',
                                            },
                                            '& .MuiFormLabel-root': {
                                                fontSize: '13px',
                                            },
                                            width: '100%'
                                        }}
                                    />
                                    <FormField
                                        Component={TextField}
                                        name={"address.zip"}
                                        label={'Code postal'}
                                        rules={{
                                            pattern: {
                                                value: /^[0-9]{5}$/i,
                                                message: "Veuillez saisir un code postal valide",
                                            },
                                            required: "Veuillez renseigner un code postal",
                                        }}
                                        inputProps={{maxLength: 5}}
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                fontSize: '13px',
                                            },
                                            '& .MuiFormLabel-root': {
                                                fontSize: '13px',
                                            },
                                            width: '100%'
                                        }}
                                    />
                                    <FormField
                                        Component={TextField}
                                        name={"address.city"}
                                        label={'Ville'}
                                        rules={{required: "Veuillez renseigner la ville"}}
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                fontSize: '13px',
                                            },
                                            '& .MuiFormLabel-root': {
                                                fontSize: '13px',
                                            },
                                            width: '100%'
                                        }}
                                    />
                                    <FormField
                                        Component={TextField}
                                        name={"address.country"}
                                        label={"Pays"}
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                fontSize: '13px',
                                            },
                                            '& .MuiFormLabel-root': {
                                                fontSize: '13px',
                                            },
                                            width: '100%'
                                        }}
                                    />
                                </Stack>
                            </BoxChield>
                        </Grid>
                        <Grid item mw0={12} mw780={6} mw1025={4}>
                            <BoxChield>
                                <Stack direction={'row'} marginBottom={1}>
                                    <ContactMailIcon fontSize={'small'} sx={{marginRight: 1, color: 'lightGreen.dark'}}/><Typography variant={"title2"}>Email</Typography>
                                </Stack>
                                <FormField
                                    Component={TextField}
                                    name={"profile.email"}
                                    label={'email'}
                                    rules={{
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Adresse Mail invalide",
                                        }
                                    }}
                                    type={"email"}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            fontSize: '13px',
                                        },
                                        '& .MuiFormLabel-root': {
                                            fontSize: '13px',
                                        },
                                        width: '100%'
                                    }}
                                />
                            </BoxChield>
                            <BoxChield>
                                <Stack direction={'row'} marginBottom={2}>
                                    <ContactPhoneIcon fontSize={'small'} sx={{marginRight: 1, color: 'lightGreen.dark'}}/><Typography variant={"title2"}>Téléphones</Typography>
                                </Stack>
                                <Stack rowGap={1}>
                                    <FormField
                                        Component={TextField}
                                        name={"profile.fixNumber"}
                                        label={'Télephone fixe'}
                                        rules={{
                                            pattern: {
                                                value: /^[0-9]{10}$/i,
                                                message: "Veuillez saisir un numéro de téléphone valide"
                                            }
                                        }}
                                        inputProps={{maxLength: 10}}
                                        type={"tel"}
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                fontSize: '13px',
                                            },
                                            '& .MuiFormLabel-root': {
                                                fontSize: '13px',
                                            },
                                            width: '100%'
                                        }}
                                    />
                                    <FormField
                                        Component={TextField}
                                        name={"profile.mobileNumber"}
                                        label={'Télephone portable'}
                                        rules={{
                                            pattern: {
                                                value: /^[0-9]{10}$/i,
                                                message: "Veuillez saisir un numéro de téléphone valide",
                                            }
                                        }}
                                        inputProps={{maxLength: 10}}
                                        type={"tel"}
                                        sx={{
                                            "& .MuiInputBase-root": {
                                                fontSize: '13px',
                                            },
                                            '& .MuiFormLabel-root': {
                                                fontSize: '13px',
                                            },
                                            width: '100%'
                                        }}
                                    />
                                </Stack>

                            </BoxChield>
                            <BoxChield>
                                <Stack direction={'row'} marginBottom={2}>
                                    <EuroIcon fontSize={'small'} sx={{marginRight: 1, color: 'lightGreen.dark'}}/><Typography variant={"title2"}>Taux horaire</Typography>
                                </Stack>
                                <FormField
                                    Component={TextField}
                                    name={"profile.price"}
                                    label={'Taux horaire'}
                                    rules={{
                                        required: "Veuillez renseigner un taux horaire",
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">&euro;</InputAdornment>,
                                    }}
                                    inputProps={{step: 0.01}}
                                    type={"number"}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            fontSize: '13px',
                                        },
                                        '& .MuiFormLabel-root': {
                                            fontSize: '13px',
                                        },
                                        width: '100%'
                                    }}
                                />
                            </BoxChield>
                        </Grid>
                        <Grid item mw0={12} mw1025={4}>
                            <BoxChield>
                                <Stack direction={'row'} marginBottom={1} alignItems={'center'}>
                                    <LocationOnIcon fontSize={'small'} sx={{marginRight: 1, color: 'lightGreen.dark'}}/><Typography variant={"title2"}>Adresse de travail</Typography>
                                </Stack>
                                <CheckboxGroup
                                    name="similarAddress"
                                    row={false}
                                    items={[
                                        { name: "Identique à l'adresse de facturation", value: '1' },
                                    ]}
                                    sxCheckBox={{fontSize: '13px', color: 'blueGrey.medium'}}
                                    onChange={()=> {
                                        handleSimilarAddress()
                                    }}
                                />
                                {!similarAddress && (
                                    <Stack rowGap={1}>
                                        <FormField
                                            Component={TextField}
                                            name={"workAddress.address"}
                                            label={'Adresse'}
                                            rules={{required: "Veuillez renseigner une adresse"}}
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    fontSize: '13px',
                                                },
                                                '& .MuiFormLabel-root': {
                                                    fontSize: '13px',
                                                },
                                                width: '100%'
                                            }}

                                        />
                                        <FormField
                                            Component={TextField}
                                            name={"workAddress.addressComplement"}
                                            label={"Complément d'adresse"}
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    fontSize: '13px',
                                                },
                                                '& .MuiFormLabel-root': {
                                                    fontSize: '13px',
                                                },
                                                width: '100%'
                                            }}
                                        />
                                        <FormField
                                            Component={TextField}
                                            name={"workAddress.zip"}
                                            label={'Code postal'}
                                            rules={{
                                                pattern: {
                                                    value: /^[0-9]{5}$/i,
                                                    message: "Veuillez saisir un code postal valide",
                                                },
                                                required: "Veuillez renseigner un code postal",
                                            }}
                                            inputProps={{maxLength: 5}}
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    fontSize: '13px',
                                                },
                                                '& .MuiFormLabel-root': {
                                                    fontSize: '13px',
                                                },
                                                width: '100%'
                                            }}
                                        />
                                        <FormField
                                            Component={TextField}
                                            name={"workAddress.city"}
                                            label={'Ville'}
                                            rules={{required: "Veuillez renseigner la ville"}}
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    fontSize: '13px',
                                                },
                                                '& .MuiFormLabel-root': {
                                                    fontSize: '13px',
                                                },
                                                width: '100%'
                                            }}
                                        />
                                        <FormField
                                            Component={TextField}
                                            name={"workAddress.country"}
                                            label={"Pays"}
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    fontSize: '13px',
                                                },
                                                '& .MuiFormLabel-root': {
                                                    fontSize: '13px',
                                                },
                                                width: '100%'
                                            }}
                                        />
                                    </Stack>
                                )}
                            </BoxChield>
                        </Grid>
                        <Grid item>
                            <Stack spacing={2}>
                                <Button type={"submit"} variant="dark" color="purple">Créer</Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
            <Modal hide={toggle} isOpen={isOpen} isCloseButton={false}>
                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                    Le client a été créé avec succes
                </Alert>
                <Button onClick={() => {navigate('/admin/customers')}}>Fermer</Button>
            </Modal>
        </FormProvider>
    );
};

export default FormNewCustomer;
