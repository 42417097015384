import React from 'react';
import {Typography} from "@mui/material";

const H3 = ({children, color="#000000"}) => {
    return (
        <Typography variant={'H3'} color={color} component={'h3'}>
            {children}
        </Typography>
    );
};

export default H3;