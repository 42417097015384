import React from 'react';
import {Typography} from "@mui/material";
import {blueGrey} from "@mui/material/colors";

const Subtitle1 = ({children}) => {
    return (
        <Typography variant={'h6'} component={'h5'} sx={{paddingY: 1, paddingLeft: 2}} color={blueGrey[900]}>
            {children}
        </Typography>
    );
};

export default Subtitle1;