import React, {useEffect, useState} from "react";
import {RESET_STATE_EVENTS_CUSTOMER} from "../../../../redux/events/eventsTypes";
import {getEventsCustomer, invalidateEvent, validateEvent} from "../../../../redux/events/eventsActions";
import {useDispatch, useSelector} from "react-redux";
import LoadingError from "../../../../components/Loading/LoadingError";
import Loading from "../../../../components/Loading/Loading";
import {
    Alert,
    AlertTitle,
    Box,
    FormControl, Grid,
    MenuItem,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import useDateTime from "../../../../Hooks/useDateTime/useTime";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import FinancialReportComponent from "./FinancialReportComponent";
import InvoicesComponent from "./InvoicesComponent";
const ActivitiesComponent = ({customerId}) => {
    const currentDate = new Date()
    const dispatch = useDispatch();
    const {toMonthName} = useDateTime()
    const eventsCustomer = useSelector((state) => state.eventsReducer)
    const [yearsArray, setYearsArray] = useState([])
    const [eventsArray, setEventArray] = useState([])
    const [yearSelected, setYearSelected] = useState(currentDate.getFullYear())
    const [monthSelected, setMonthSelected] = useState(currentDate.getMonth())

    const dateFilter = (events, year, month) => {
        let resultFilterYear = events.filter(el => {
            return new Date(el.start).getFullYear() === parseInt(year)
        })

        return resultFilterYear.filter(el => {
            return new Date(el.start).getMonth() + 1 === parseInt(month)
        })
    }

    const handleValidEvent = async (id) => {
            dispatch(validateEvent(id));
    }
    const handleInvalidEvent = async (id) => {
            dispatch(invalidateEvent(id));
    }


    useEffect(() => {
        dispatch({
            type: RESET_STATE_EVENTS_CUSTOMER
        })
        dispatch(getEventsCustomer(customerId))
    }, [])

    useEffect(() => {
        if (eventsCustomer.eventsCustomer.length !== 0) {
            let array = []
            eventsCustomer.eventsCustomer.map(event => {
                let startDate = new Date(event.start)
                let year = startDate.getFullYear()
                if (!array.hasOwnProperty(year)) {
                    array[year] = [startDate.getMonth() + 1]
                } else if (array.hasOwnProperty(year)) {
                    if (!array[year].includes(startDate.getMonth() + 1)) {
                        array[year].push(startDate.getMonth() + 1)
                        array[year].sort(function (a, b) {
                            return a - b
                        })
                    }
                }
            })
            setYearsArray(array)
            if (array[currentDate.getFullYear()]) {
                if (array[currentDate.getFullYear()].includes(currentDate.getMonth() + 1)) {
                    setMonthSelected(currentDate.getMonth() + 1)
                    setEventArray(dateFilter(eventsCustomer.eventsCustomer, currentDate.getFullYear(), currentDate.getMonth() + 1))
                } else {
                    setMonthSelected(array[currentDate.getFullYear()][0])
                    setEventArray(dateFilter(eventsCustomer.eventsCustomer, currentDate.getFullYear(), array[currentDate.getFullYear()][0]))
                }
            } else {
                setMonthSelected((array[array.length - 1])[(array[array.length - 1]).length - 1])
                setYearSelected(Object.keys(array)[Object.keys(array).length - 1])
                setEventArray(dateFilter(eventsCustomer.eventsCustomer, Object.keys(array)[Object.keys(array).length - 1], (array[array.length - 1])[(array[array.length - 1]).length - 1]))
            }
        }
    }, [eventsCustomer.eventsCustomer])

    useEffect(() => {
        setEventArray(dateFilter(eventsCustomer.eventsCustomer, Number(yearSelected), Number(monthSelected)))
    }, [yearSelected, monthSelected])


    const handleChangeYear = (event) => {
        setYearSelected(Number(event.target.value))
        if (currentDate.getFullYear() === Number(event.target.value)) {
            setMonthSelected(currentDate.getMonth() + 1)
        } else {
            setMonthSelected(yearsArray[event.target.value][0])
        }
    }

    const handleChangeMonth = (event) => {
        setMonthSelected(event.target.value)
    }

    return (
        <>
            {eventsCustomer.loading ? (
                <Loading/>
            ) : eventsCustomer.error ? (
                <LoadingError error={eventsCustomer.error} txt={"Section Activités"}/>
            ) : yearsArray.length > 0 && eventsArray.length > 0 ? (
                <Grid container spacing={2} justifyContent={'center'}>
                    <Grid item mw0={12}>
                        <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={2}
                               sx={{marginBottom: 2}}>
                            <FormControl size={"small"}>
                                <Select
                                    value={yearSelected}
                                    onChange={handleChangeYear}
                                >
                                    {Object.keys(yearsArray).map(year => {
                                        return <MenuItem key={year} value={year}>{year}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl size={"small"}>
                                <Select
                                    value={monthSelected}
                                    onChange={handleChangeMonth}
                                >
                                    {yearsArray[yearSelected]?.map(month => {
                                        return <MenuItem key={month} value={month}>{toMonthName(month)}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Stack>
                    </Grid>
                    <Grid item mw0={12} mw780={6}>
                        <Typography variant={"cardTitle"}>Activités</Typography>
                        <Paper sx={{ width: '100%' }}>
                            <TableContainer>
                                <Table stickyHeader size={"small"}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><Typography variant={'tableHead'}>Date</Typography></TableCell>
                                            <TableCell><Typography variant={'tableHead'}>Horaire</Typography></TableCell>
                                            <TableCell  sx={{display: {mw0: 'none', mw1025: 'table-cell'}}}><Typography variant={'tableHead'}>Adresse</Typography></TableCell>
                                            <TableCell ><Typography variant={'tableHead'}>Effectué</Typography></TableCell>
                                            <TableCell><Typography variant={'tableHead'}>Validé</Typography></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {eventsArray.sort((a, b) => (a.start > b.start ? 1 : -1)).map(event => {
                                            const start = new Date(event.start.split('+')[0]);
                                            const end = new Date(event.end.split('+')[0]);
                                            const diff = new Date(end.getTime() - start.getTime());
                                            return (
                                                <TableRow key={event.id}>
                                                    <TableCell><Typography variant={'tableRows'}>{new Date(event.start).getDate()}</Typography></TableCell>
                                                    <TableCell><Typography variant={'tableRows'}>{start.getHours()}h{start.getMinutes() <= 9 ? '0' + start.getMinutes() : start.getMinutes()} - {end.getHours()}h{end.getMinutes() <= 9 ? '0' + end.getMinutes() : end.getMinutes()}</Typography></TableCell>
                                                    <TableCell  sx={{display: {mw0: 'none', mw1025: 'table-cell'}}}><Typography variant={'tableRows'}>{event.address.address} {event.address.addressComplement} {event.address.zip} {event.address.city}</Typography></TableCell>
                                                    <TableCell><Typography variant={'tableRows'}>{diff.getUTCHours()}h{diff.getUTCMinutes() <= 9 ? '0' + diff.getUTCMinutes() : diff.getUTCMinutes()}</Typography></TableCell>
                                                    <TableCell><Typography variant={'tableRows'}>{event.isValidated ? <CheckOutlinedIcon onClick={ () => {handleInvalidEvent(event.id)}} color={'success'}/> : <CloseOutlinedIcon onClick={() => {handleValidEvent(event.id)}} color={'error'}/>}</Typography></TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                    <Grid item container mw0={12} mw780={6}>
                        <Grid item mw0={12}>
                            <FinancialReportComponent year={yearSelected} month={monthSelected} customerId={customerId} />
                        </Grid>
                        <Grid item mw0={12}>
                            <InvoicesComponent year={yearSelected} month={monthSelected} customerId={customerId}/>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <div>
                    <Typography variant={"cardTitle"}>Activités</Typography>
                    <Box sx={{display: 'flex', justifyContent: 'start'}}>
                        <Alert severity="info">
                            <AlertTitle>Pas d'activités</AlertTitle>
                        </Alert>
                    </Box>
                </div>
            )}
        </>
    )
}

export default ActivitiesComponent
