import {createStore, combineReducers, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";
import auth from "./auth/authReducer";
import message from "./auth/messageReducer";
import customersReducer from "./customers/customersReducer";
import eventsReducer from "./events/eventsReducer";
import reportsReducer from "./reports/reportsReducer";
import {invoicesReducer} from "./invoices/invoicesReducer";

const rootReducer = combineReducers({
    auth,
    message,
    customersReducer,
    eventsReducer,
    reportsReducer,
    invoicesReducer,
});

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
);

export default store;
