import React, {useEffect} from 'react';
import TemplatePage from "../../Template/TemplatePage";
import Container from "./Container";
import Title from "./Title";
import H2 from "../../Components/Typographies/H2";
import Paragraph from "./Paragraph";
import {Box, List, ListItem, ListItemText, Typography} from "@mui/material";
const PeopleCompagnyPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <TemplatePage>
            <Container title={'Assistance à Domicile'}>
                <Title>
                    <H2 color={'pink.light'}>Lutte contre la solitude</H2>
                </Title>
                <Paragraph>
                    Prendre soin des personnes âgées à domicile ne peut pas se limiter aux actes dit techniques : ménage, toilette et repas.<br/>
                    La compagnie, les liens sociaux, l’ animation permettent à la personne aidée :
                    <Typography component={'span'} display={'block'} paddingLeft={5} marginY={'5px'}>- De vivre des moments riches et interressants</Typography>
                    <Typography component={'span'} display={'block'} paddingLeft={5} marginBottom={'5px'}>- De stimuler ses capacités physiques et intellectuelles</Typography>
                    <Typography component={'span'} display={'block'} paddingLeft={5} marginBottom={'8px'}>- De répondre à ses besoins fondamentaux</Typography>
                    Entretenir le lien social est primordial pour prévenir la solitude et éviter le repli sur soi. L’épanouissement de la personne âgée est au cœur de mon engagement.
                </Paragraph>

                <Paragraph>

                </Paragraph>
                <Paragraph>
                    Les activités doivent correspondre à un désir de la personne aidée mais aussi doivent permettre de développer ou entretenir certaines capacités. Souvent, les personnes agées n'ont plus envie de rien faire. Je les stimulent doucement. L’animation que je propose tient compte des habitudes, des goûts de la personne et de ses possibilités physiques et intellectuelles.
                </Paragraph>
                <Paragraph>
                    Mon intervention à domicile est là pour aider la personne aidée à favoriser sa réflexion et entretenir sa curiosité intellectuelle.
                </Paragraph>
                <Paragraph>
                    Les prestations que je peux proposer sont :
                    <Typography component={'span'} display={'block'} paddingLeft={5} marginY={'5px'}>- Stimulation intellectuel à base de mots (mots fléchés, mots mélés) et à base de chiffre (sudoku), jeu de mémoire, aide à l'informatique et internet</Typography>
                    <Typography component={'span'} display={'block'} paddingLeft={5} marginBottom={'5px'}>- Stimulation manuelle : patchwork, scrapbooking, cuisine, culture des plantes aromatiques ...</Typography>
                    <Typography component={'span'} display={'block'} paddingLeft={5} marginBottom={'5px'}>- Activité physique : Promenade et sortie</Typography>
                    <Typography component={'span'} display={'block'} paddingLeft={5} marginBottom={'8px'}>- Social : Recevoir des amis</Typography>
                </Paragraph>
                <Paragraph>
                    <Box component={'span'} color={'blueGrey.dark'}>Culture des plantes aromatiques.<br/></Box>
                    Cette activité est possible aussi bien en maison particulière ou en appartement (balcon, rebord d’ une fenêtre). Elle est adaptée à tous et plus particulièrement aux personnes les plus affaiblies. Cette activité demande peu d’investissement physique et stimule la vue, l’odorat, le toucher et le sentiment de la réalisation de soi.
                </Paragraph>
                <Paragraph>
                    <Box component={'span'} color={'blueGrey.dark'}>Recevoir des amis.<br/></Box>
                    Certaines personnes âgées n’ont plus la force d’inviter chez elles, car l’organisation peut paraître compliquée. En proposant cette activité, je stimule la fonction sociale de la personne aidée. J’aide la personne âgée à lancer ses invitations. Quelques jours avant, j’aide la personne aidée à préparer sa liste de course et nous effectuons les courses ensemble selon sa capacité physique.Le jour de l’invitation, je dresse la table, j’accueille les invités et aide à faire le service avec la personne âgée.Ensuite, je range et nettoie.
                </Paragraph>
                <Paragraph>
                    <Box component={'span'} color={'blueGrey.dark'}>Promenade et sortie.<br/></Box>
                    Une activité physique quotidienne au grand air est bénéfique pour une personne valide ou en perte d'autonomie. Mon accompagnement assure une tranquillité d'esprit et des sorties sans chute ou tout autre imprévu.
                </Paragraph>
                <Paragraph>
                    En faisant toutes ses animations, j’aide les personnes âgées à se sentir heureux.
                </Paragraph>
            </Container>
        </TemplatePage>
    )
}

export default PeopleCompagnyPage