import React from 'react';
import {Box} from "@mui/material";
import ImgLotus from "../Components/ImgLotus";
import NavBarHeader from "./NavBarHeader";
import Banner from "./Banner";
import ImgBanner from "../Components/ImgBanner";
import GradientText from "../Components/GradientText";
import BannerLinks from "./BannerLinks";
import {Link} from "react-router-dom";
import HeaderContainer from "./HeaderContainer";
import H1 from "../Components/Typographies/H1";

const Header = () => {
    return (
        <HeaderContainer>
            <ImgLotus/>
            <NavBarHeader/>
            <Banner>
                <BannerLinks>
                    <Link to={"/page-accueil"}>
                        <H1 color={'blueGrey.primary'}>
                            <GradientText>Christelle </GradientText> Jeandenans<br/>
                            Assistante de vie aux familles
                        </H1>
                        <Box sx={{display: {mw0: 'block', mw780: 'none'}}}>
                            <Box sx={{
                                position: 'absolute',
                                textAlign: {mw0: 'left', mw780: 'center'},
                                zIndex: 10,
                                top: {mw0: 80, mw780: 60},
                                width: '100%',
                            }}>
                                <Box color={'pink.primary'} component={'p'} fontSize={{mw0: '14px', mw780: '24px'}}>
                                    L'aide à Domicile Personalisée.<br/> Le bien être au quotidien
                                </Box>
                            </Box>

                        </Box>
                    </Link>
                </BannerLinks>
                <ImgBanner>
                    <Box sx={{
                        position: 'absolute',
                        textAlign: 'center',
                        zIndex: 10,
                        top: {mw0: 140, mw780: 40},
                        width: '100%',
                    }}>
                        <Box color={'pink.primary'} component={'p'} fontSize={{mw0: '22px', mw780: '24px'}}>
                            L'aide à Domicile Personalisée<br/>
                            Le bien être au quotidien
                        </Box>
                    </Box>
                </ImgBanner>
            </Banner>
        </HeaderContainer>
    );
}

export default Header;
