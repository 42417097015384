import React from 'react';
import {Box, Paper, Stack} from "@mui/material";
import H1 from "../../Components/Typographies/H1";

const Container = ({children, title}) => {
    return (
        <Box component={'section'} maxWidth={'1200px'} marginX={'auto'} paddingX={4}>
            <Paper component={'article'} elevation={12} sx={{padding: 3, marginTop: 2}}>
                <Stack direction={'row'} sx={{marginBottom: 5, paddingBottom: 1 ,borderBottom: '1px solid',borderColor: 'purple.light'}}>
                    <H1 color={'pink.light'}>{title}</H1>
                </Stack>
                {children}
            </Paper>
        </Box>
    );
};

export default Container;