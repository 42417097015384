import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    REFRESH_TOKEN,
    REQUEST_AUTH_PROFILE_PATCH_SUCCESS,
    REQUEST_AUTH_PROFILE_SUCCESS, REQUEST_AUTH_ADDRESS_SUCCESS
} from "./authTypes";

const user = localStorage.getItem("user") ?? sessionStorage.getItem("user");
const profile = localStorage.getItem("profile") ?? sessionStorage.getItem("profile");
const initialState = user
    ? {
        isLoggedIn: true,
        user: JSON.parse(user),
        profile: JSON.parse(profile)
    }
    : {
        isLoggedIn: false,
        user: null,
        profile: []
    };

export default function (state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case REGISTER_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
            };
        case REGISTER_FAIL:
            return {
                ...state,
                isLoggedIn: false,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        case REFRESH_TOKEN:
            return {
                ...state,
                user: {...state.user, token: payload},
            };
        case REQUEST_AUTH_PROFILE_PATCH_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    user: {
                        ...state.user.user,
                        profile: payload
                    }
                }
            };
        case REQUEST_AUTH_PROFILE_SUCCESS:
            return {
                ...state,
                profile: payload
            };
        case REQUEST_AUTH_ADDRESS_SUCCESS:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    addresses: state.profile.addresses = [payload]

                }
            };
        default:
            return state;
    }
}
