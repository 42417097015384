import React from 'react';
import {useForm, FormProvider} from "react-hook-form";
import {useDispatch} from "react-redux";
import {Button, Stack, TextField} from "@mui/material";
import FormField from "../../../components/FormField/FormField";
import {apiAuthProfilePatch} from "../../../redux/auth/authAction";


const FormAuthProfile = ({auth, toggle}) => {
    const dispatch = useDispatch()

    const form = useForm({
        defaultValues: {
            email: auth.email,
            lastname: auth.lastname,
            firstname: auth.firstname,
            fixNumber: auth.fixNumber,
            mobileNumber: auth.mobileNumber
        }
    })

    const formSubmit = (data, e) => {
        e.preventDefault()
        dispatch(apiAuthProfilePatch(auth.id, data));
        toggle();
    }
    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(formSubmit)}>
                <Stack spacing={3}>
                    <FormField
                        Component={TextField}
                        name={"lastname"}
                        label={'Nom'}
                        rules={{required: "Veuillez renseigner le nom du client"}}
                    />
                    <FormField
                        Component={TextField}
                        name={"firstname"}
                        label={'Prénom'}
                        rules={{required: "Veuillez renseigner le prénom du client"}}
                    />
                    <FormField
                        Component={TextField}
                        name={"email"}
                        label={'email'}
                        rules={{
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Adresse Mail invalide",
                            }
                        }}
                        type={"email"}
                    />
                    <FormField
                        Component={TextField}
                        name={"fixNumber"}
                        label={'Télephone fixe'}
                        rules={{
                            pattern: {
                                value: /^[0-9]{10}$/i,
                                message: "Veuillez saisir un numéro de téléphone valide"
                            }
                        }}
                        inputProps={{maxLength: 10}}
                        type={"tel"}
                    />
                    <FormField
                        Component={TextField}
                        name={"mobileNumber"}
                        label={'Télephone portable'}
                        rules={{
                            pattern: {
                                value: /^[0-9]{10}$/i,
                                message: "Veuillez saisir un numéro de téléphone valide",
                            }
                        }}
                        inputProps={{maxLength: 10}}
                        type={"tel"}
                    />
                    <div>
                        <Button type={"submit"} variant="dark" color="purple">Enregistrer</Button>
                    </div>
                </Stack>
            </form>
        </FormProvider>
    );
};

export default FormAuthProfile;
