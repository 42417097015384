import React from 'react';
import {Box} from "@mui/material";

const HeaderContainer = ({children}) => {
    return (
        <Box component={'header'} sx={{
            position: 'fixed',
            display: 'flex',
            flexDirection: 'column',
            background: '#ffffff',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 100,
            maxHeight: 200
        }}>
            <Box component={'div'} sx={{width: '100%', maxWidth: '1650px', marginX: 'auto', position: 'relative'}}>
                {children}
            </Box>
        </Box>
    )
}

export default HeaderContainer