import {
    REQUEST_INVOICES_DELETE_SUCCESS,
    REQUEST_INVOICES_ERROR,
    REQUEST_INVOICES_POST_SUCCESS,
    REQUEST_INVOICES_STARTED,
    REQUEST_INVOICES_SUCCESS, SET_USER_ID
} from "./invoicesTypes";

const initialState = {
    invoices: [],
    userId: null,
    loading: false,
    error: null,
}

export const invoicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_INVOICES_STARTED:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_INVOICES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case REQUEST_INVOICES_SUCCESS:
            return {
                ...state,
                loading: false,
                invoices: action.payload,
                error: "",
            };
        case REQUEST_INVOICES_POST_SUCCESS:
            return {
                ...state,
                loading: false,
                invoices: [...state.invoices.filter(invoice => invoice.id !== action.payload), action.payload],
                error: "",
            };
        case REQUEST_INVOICES_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                invoices: state.invoices.filter(invoice => invoice.id !== action.payload),
                error: "",
            };
        case SET_USER_ID:
            return {
                ...state,
                userId: action.payload,
            };
        default:
            return state;
    }
}
