import React, {useEffect, useState} from "react";
import {
    AppBar,
    Box,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Stack,
    Toolbar,
    Typography
} from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import {blueGrey, cyan} from "@mui/material/colors";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import SmartphoneOutlinedIcon from "@mui/icons-material/SmartphoneOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import Modal from "../../components/Modal/ModalComponent";
import useModal from "../../Hooks/useModal/useModal";
import FormAuthProfile from "./FormAuth/FormAuthProfile";
import {useSelector} from "react-redux";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {Link} from "react-router-dom";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import useMediaQuery from "../../Hooks/useMediaQuery/useMediaQuery";
import FormAuthAddress from "./FormAuth/FormAuthAddress";

const AuthProfile = () => {
    const auth = useSelector((state) => state.auth);
    const {isOpen, toggle} = useModal();
    const [selectedData, setSelectedData] = useState({fetch: "", addr: {}});
    const [mainAddress, setMainAddress] = useState([])
    const isMobile = useMediaQuery("(max-width: 600px)")
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = useState(false)
    const open = Boolean(anchorEl);

    const handleMainAddress = () => {
        setMainAddress(auth.profile.addresses[0])
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenMenu(!openMenu)
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpenMenu(!openMenu)
    };

    useEffect(() => {
        auth.profile && handleMainAddress()
    }, [auth.profile])

    return (

        <Box sx={{marginTop: 10, marginX: 2}} component={"main"}>
            <AppBar position="fixed" color={'white'}>
                <Toolbar>

                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        endIcon={isOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        sx={{color: blueGrey[700]}}
                    >
                        {isMobile ?
                            (
                                <div>{auth.user.user.profile.firstname.substring(0, 1).toUpperCase()}{auth.user.user.profile.lastname.substring(0, 1).toUpperCase()}</div>)
                            : (<div>{auth.user.user.profile.firstname} {auth.user.user.profile.lastname}</div>)
                        }
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handleClose}
                                  component={Link}
                                  to={"/logout"}
                        >
                            <LogoutOutlinedIcon fontSize={"small"} sx={{marginRight: 2}}/>
                            Déconnexion
                        </MenuItem>

                        <MenuItem onClick={handleClose}
                                  component={Link}
                                  to={"/admin/customers"}
                        >
                            <AdminPanelSettingsOutlinedIcon fontSize={"small"} sx={{marginRight: 2}}/>
                            Espace Admin
                        </MenuItem>

                    </Menu>

                </Toolbar>
            </AppBar>
            <Typography variant={"cardTitle"}>Profile</Typography>
            <Paper variant={"outlined"} sx={{borderRadius: 3, p: 2}}>
                <Stack spacing={4}>
                    <Box>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography variant={"title2"}>Infos de contact</Typography>
                            <IconButton color={"secondary"} onClick={() => {
                                setSelectedData({fetch: "profile", addr: {}})
                                toggle();
                            }}>
                                <BorderColorOutlinedIcon/>
                            </IconButton>
                        </Stack>
                        <Stack spacing={2}>
                            <Stack direction={"row"} spacing={2}>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <Person2OutlinedIcon sx={{color: cyan[800]}}/>
                                    <Typography variant={"txt1"}>
                                        {auth.user.user.profile.firstname}
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>

                                    <Typography variant={"txt1"}>
                                        {auth.user.user.profile.lastname}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                <VpnKeyOutlinedIcon sx={{color: cyan[800]}}/>
                                <Typography variant={"txt1"}>
                                    {auth.user.user.uuid}-{auth.user.user.id}
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                <ContactMailOutlinedIcon sx={{color: cyan[800]}}/>
                                <Typography variant={"txt1"}>
                                    {auth.user.user.profile.email === '' ? 'Inconnu' : auth.user.user.profile.email}
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} spacing={2}>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <LocalPhoneOutlinedIcon sx={{color: cyan[800]}}/>
                                    <Typography variant={"txt1"}>
                                        {auth.user.user.profile.fixNumber === '' ? 'Inconnu' : auth.user.user.profile.fixNumber}
                                    </Typography>
                                </Stack>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    <SmartphoneOutlinedIcon sx={{color: cyan[800]}}/>
                                    <Typography variant={"txt1"}>
                                        {auth.user.user.profile.mobileNumber === '' ? 'Inconnu' : auth.user.user.profile.mobileNumber}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                    <Box>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography variant={"title2"}>Adresse</Typography>
                            <IconButton color={"secondary"} onClick={() => {
                                setSelectedData({fetch: "patchAddress", addr: mainAddress})
                                toggle();
                            }}>
                                <BorderColorOutlinedIcon/>
                            </IconButton>
                        </Stack>
                        <Stack>
                            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                <HomeOutlinedIcon sx={{color: cyan[800]}}/>
                                <Typography variant={"txt1"}>{mainAddress.address}</Typography>
                            </Stack>
                            <Box sx={{ml: 4}}>
                                <Typography variant={"txt1"}>{mainAddress.addressComplement}</Typography>
                                <Stack direction={"row"} spacing={1}>
                                    <Typography variant={"txt1"}>{mainAddress.zip}</Typography>
                                    <Typography variant={"txt1"}>{mainAddress.city}</Typography>
                                </Stack>
                                <Typography variant={"txt1"}>{mainAddress.country}</Typography>
                            </Box>
                        </Stack>
                    </Box>

                </Stack>

            </Paper>
            <Modal hide={toggle} isOpen={isOpen}
                   header={selectedData.fetch === "profile" ? "AuthProfile : Modification des informations" : selectedData.fetch === "patchAddress" && "AuthProfile : Modification de l'adresse"}>
                {selectedData.fetch === "profile" ? (
                    <FormAuthProfile auth={auth.user.user.profile} toggle={toggle}/>
                ) : selectedData.fetch === "patchAddress" && (
                    <FormAuthAddress address={selectedData.addr} fetch={selectedData.fetch}
                                     toggle={toggle}/>
                )}
            </Modal>
        </Box>

    );
};

export default AuthProfile;
