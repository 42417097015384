import React from 'react';
import {Typography} from "@mui/material";

const Paragraph = ({children}) => {
    return (
        <Typography component={'p'} align={'justify'} lineHeight={'25px'} color={'blueGrey.primary'} marginBottom={3}>
            {children}
        </Typography>
    );
};

export default Paragraph;