import React from 'react';
import {Button, Stack, TextField} from "@mui/material";
import FormField from "../../../../components/FormField/FormField";
import {FormProvider, useForm} from "react-hook-form";
import {apiCustomerProfilePatch} from "../../../../redux/customers/customersAction";
import {useDispatch} from "react-redux";

const FormPhoneNumber = ({customer, handleEditPhoneNumber}) => {
    const dispatch = useDispatch();
    const form = useForm({
        defaultValues: {
            fixNumber: customer.profile.fixNumber,
            mobileNumber: customer.profile.mobileNumber
        }
    })

    const formSubmit = (data, e) => {
        e.preventDefault()
        dispatch(apiCustomerProfilePatch(customer.profile.id, data));
        handleEditPhoneNumber()
    }
    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(formSubmit)}>
                <Stack direction={'row'} justifyContent={'right'} marginBottom={1}>
                    <Button type={"submit"} size={'small'}>Enregistrer</Button>
                    <Button size={'small'} onClick={handleEditPhoneNumber}>Annuler</Button>
                </Stack>
                <Stack rowGap={2}>
                    <FormField
                        Component={TextField}
                        name={"fixNumber"}
                        label={'Télephone fixe'}
                        rules={{
                            pattern: {
                                value: /^[0-9]{10}$/i,
                                message: "Veuillez saisir un numéro de téléphone valide"
                            }
                        }}
                        inputProps={{maxLength: 10}}
                        type={"tel"}
                        sx={{
                            "& .MuiInputBase-root": {
                                fontSize: '13px',
                            },
                            '& .MuiFormLabel-root': {
                                fontSize: '13px',
                            },
                            width: '50%'
                        }}
                    />
                    <FormField
                        Component={TextField}
                        name={"mobileNumber"}
                        label={'Télephone portable'}
                        rules={{
                            pattern: {
                                value: /^[0-9]{10}$/i,
                                message: "Veuillez saisir un numéro de téléphone valide",
                            }
                        }}
                        inputProps={{maxLength: 10}}
                        type={"tel"}
                        sx={{
                            "& .MuiInputBase-root": {
                                fontSize: '13px',
                            },
                            '& .MuiFormLabel-root': {
                                fontSize: '13px',
                            },
                            width: '50%'
                        }}
                    />
                </Stack>
            </form>
        </FormProvider>
    );
};

export default FormPhoneNumber;