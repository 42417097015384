export const REQUEST_CUSTOMERS_STARTED = "REQUEST_CUSTOMERS_STARTED";
export const REQUEST_ERROR = "REQUEST_ERROR";
export const RESET_STATE_USERS = "RESET_STATE_USERS";
export const REQUEST_CUSTOMERS_SUCCESS = "REQUEST_CUSTOMERS_SUCCESS";
export const REQUEST_CUSTOMERS_PATCH_SUCCESS = "REQUEST_CUSTOMERS_PATCH_SUCCESS";
export const REQUEST_CUSTOMERS_POST_SUCCESS = "REQUEST_CUSTOMERS_POST_SUCCESS";
export const REQUEST_CUSTOMERS_PROFILE_PATCH_SUCCESS = "REQUEST_CUSTOMERS_PROFILE_PATCH_SUCCESS";
export const REQUEST_CUSTOMERS_ADDRESS_POST_SUCCESS = "REQUEST_CUSTOMERS_ADDRESS_POST_SUCCESS";
export const REQUEST_CUSTOMERS_ADDRESS_PATCH_SUCCESS = "REQUEST_CUSTOMERS_ADDRESS_PATCH_SUCCESS";
export const REQUEST_CUSTOMERS_ADDRESS_DELETE_SUCCESS = "REQUEST_CUSTOMERS_ADDRESS_DELETE_SUCCESS";
export const REQUEST_CUSTOMERS_ADDRESS_SET_DEFAULT_SUCCESS = "REQUEST_CUSTOMERS_ADDRESS_SET_DEFAULT_SUCCESS";

