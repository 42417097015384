import React, {useEffect} from 'react';
import TemplatePage from "../../Template/TemplatePage";
import Container from "./Container";
import Title from "./Title";
import H2 from "../../Components/Typographies/H2";
import Paragraph from "./Paragraph";
import H3 from "../../Components/Typographies/H3";
import {Stack} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const AssistanceEatingPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
       <TemplatePage>
           <Container title={'Assistance à Domicile'}>
               <Title>
                   <H2 color={'pink.light'}>Aide aux repas</H2>
               </Title>
               <Paragraph>
                   L’ aide aux repas à domicile s’ adresse aux personnes âgées ou handicapées ayant des difficultés à manger seules, soit pour des raisons de perte d’ autonomie physique, soit pour des raisons de perte d’ autonomie liée à des maladies dégénératives ( exemple alzheimer). L’irrégularité des repas est une problématique connue chez nos seniors.<br/>
                   Mon intervention permet de soulager les personnes en perte d’autonomie de tâches devenues difficiles pour elles.
               </Paragraph>
               <Paragraph>
                   Une alimentation saine et variée est essentielle pour préserver la santé et le moral des personnes fragiles. Je suis formée aux problématiques nutritionnelles que peuvent rencontrer les personnes fragiles. Je m’ adapte aux besoins et aux comportements alimentaires de chaque personne. Je veille à proposer des repas variés et équilibrés. Je suis à l’écoute des envies du bénéficiaire afin de lui proposer des repas appétissants et adaptés à ses envies ou aux régimes prescrits.
               </Paragraph>
               <Paragraph>
                   Je veille à ce que l’aide aux repas soit un moment agréable, convivial et plaisant. J’installe un climat de confiance avec le bénéficiaire afin que les repas se déroulent dans une ambiance sereine et apaisée.
               </Paragraph>
               <Paragraph>
                   Je nettoie la vaisselle, la cuisine et j'entretien le garde manger. Le tout afin de garder un cadre de vie propre et agréable. Je vérifier régulièrement, les dates de péremption des produits présents dans le réfrigérateur, congèlateur ou placard.
               </Paragraph>
               <Title>
                   <H3 color={'blueGrey.dark'}>
                       <Stack direction={'row'} alignItems={'center'} component={'span'} lineHeight={'25px'}>
                           <FiberManualRecordIcon sx={{verticalAlign: 'middle', marginRight: 1, fontSize: '16px'}} />
                           Aide aux courses
                       </Stack>
                   </H3>
               </Title>
               <Paragraph>
                   Selon le degré d’autonomie, l’aide peut prendre la forme d’un accompagnement aux courses. Dans ce cas, je  sécurise la personne âgée ou dépendante et prévient d’éventuelles chutes sur le trajet. Je pourrais au besoin lui donner le bras et l’ aider à prendre confiance en elle. J’ai été formée pour stimuler l’autonomie des personnes âgées tout en prévenant les risques de chute.<br/>
                   Ou bien, je peux effectuer les courses, si la personne dépendante n’est pas en mesure de se déplacer. Je prends en compte  les habitudes, besoins et envies des bénéficiaires tout en respectant les budgets définis par la famille. Au retour des courses, je restitue la monnaie et classent les tickets de caisse dans le cahier de liaison pour contrôle.
               </Paragraph>
               <Title>
                   <H3 color={'blueGrey.dark'}>
                       <Stack direction={'row'} alignItems={'center'} component={'span'} lineHeight={'25px'}>
                           <FiberManualRecordIcon sx={{verticalAlign: 'middle', marginRight: 1, fontSize: '16px'}} />
                           Aide à la préparation des repas, surveillance régime
                       </Stack>
                   </H3>
               </Title>
               <Paragraph>
                   J’assure la préparation des repas à domicile. Je peux préparer plusieurs repas qui seront ensuite réchauffés le soir ou le lendemain par la personne aidée. J’associe la personne aidée à la préparation des repas , j’ajuste sa participation en fonction de son degré d’autonomie et de ses capacités du moment.
               </Paragraph>
               <Paragraph>
                   En absence de régimes prescrits,  je veille à proposer des repas variés et équilibrés adaptés et faciles à manger pour la personne aidée (morceaux pré coupés , purée,  repas moulinés, le cas échéant afin d'éviter les fausses routes).
               </Paragraph>
               <Paragraph>
                   En cas de régime prescrit, je respecte les consignes des professionnels.
               </Paragraph>
               <Title>
                   <H3 color={'blueGrey.dark'}>
                       <Stack direction={'row'} alignItems={'center'} component={'span'} lineHeight={'25px'}>
                           <FiberManualRecordIcon sx={{verticalAlign: 'middle', marginRight: 1, fontSize: '16px'}} />
                           Aide à la prise des repas
                       </Stack>
                   </H3>
               </Title>
               <Paragraph>
                   Les personnes en perte d’autonomie peuvent éprouver des difficultés pour s’alimenter. L’appétit peut diminuer, et le plaisir de manger s’altérer.
               </Paragraph>
               <Paragraph>
                   J’accompagne  les personnes dans la prise de leur repas (même en cas d eportage de repas). Cet accompagnement est précieux car il permet aux bénéficiaires de s’alimenter correctement et de varier leurs repas. L’intervention d’une auxiliaire de vie favorise le maintien à domicile des personnes. La prise des repas étant sécurisée, les personnes aidées gardent ainsi leur autonomie et retrouvent confiance en elles.
               </Paragraph>

               <Paragraph>
                   Lors de la prise des repas, je vérifie que la consistance de la nourriture est facile à ingérer pour la personne âgée (morceaux pré coupés , purée, repas mixé si besoin afin d’ éviter les fausses routes). J’ai appris à réagir en cas de fausse route (lorsqu'une personne âgée ”avale de travers”). J’ai mon certificat de travail de secourisme que je renouvelle tous les 2 ans.
               </Paragraph>
               <Paragraph>
                   Je peux prendre le repas avec la personne aidée (j’apporte généralement mon repas). Cela donne un moment de convivialité. Cette action à généralement pour effet d’augmenter l’appétit de la personne. Les personnes aidées, comme tout le monde, mangent souvent mieux quand elles partagent leur repas.
               </Paragraph>
               <Title>
                   <H3 color={'blueGrey.dark'}>
                       <Stack direction={'row'} alignItems={'center'} component={'span'} lineHeight={'25px'}>
                           <FiberManualRecordIcon sx={{verticalAlign: 'middle', marginRight: 1, fontSize: '16px'}} />
                           Suivi à la prise des médicaments
                       </Stack>
                   </H3>
               </Title>
               <Paragraph>
                   Je peux vérifier que le traitement de la personne aidée est bien suivi et alerter les personnes référentes en cas de problème ou d’ anomalie.
               </Paragraph>
           </Container>
       </TemplatePage>
    )
}

export default AssistanceEatingPage