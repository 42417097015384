import {
    REQUEST_REPORT_ERROR, REQUEST_GET_REPORT_YEAR_ALL_EVENTS_SUCCESS,
    REQUEST_GET_REPORT_YEAR_EVENTS_NOT_VALIDATED_SUCCESS,
    REQUEST_GET_REPORT_YEAR_EVENTS_VALIDATED_SUCCESS,
    REQUEST_REPORT_STARTED
} from "./reportsTypes";
import EventsService from "../../services/events.service";

const requestReportsStarted = () => {
    return {
        type: REQUEST_REPORT_STARTED,
    };
};
const requestReportsError = (error) => {
    return {
        type: REQUEST_REPORT_ERROR,
        payload: error,
    };
};


export const apiGetEventsValidated = (adminId, year) => {
    return async (dispatch) => {
        try {
            dispatch(requestReportsStarted());
            const res = await EventsService.getYearReport(adminId, year, true)
            dispatch({
                type: REQUEST_GET_REPORT_YEAR_EVENTS_VALIDATED_SUCCESS,
                payload: res.data['hydra:member']
            })
        } catch (err) {
            dispatch(requestReportsError(err.message));
        }
    }
}

export const apiGetEventsNotValidated = (adminId, year) => {
    return async (dispatch) => {
        try {
            dispatch(requestReportsStarted());
            const res = await EventsService.getYearReport(adminId, year, false)
            dispatch({
                type: REQUEST_GET_REPORT_YEAR_EVENTS_NOT_VALIDATED_SUCCESS,
                payload: res.data['hydra:member']
            })
        } catch (err) {
            dispatch(requestReportsError(err.message));
        }
    }
}

export const apiGetAllEvents = (adminId, year) => {
    return async (dispatch) => {
        try {
            dispatch(requestReportsStarted());
            const res = await EventsService.getYearReport(adminId, year)
            dispatch({
                type: REQUEST_GET_REPORT_YEAR_ALL_EVENTS_SUCCESS,
                payload: res.data['hydra:member']
            })
        } catch (err) {
            dispatch(requestReportsError(err.message));
        }
    }
}

export const apiGetEvents = (adminId, year) => {
    return async (dispatch) => {
        try {
            dispatch(requestReportsStarted());
            const all = await EventsService.getYearReport(adminId, year)
            const valid = await EventsService.getYearReport(adminId, year, true)
            const notValid = await EventsService.getYearReport(adminId, year, false)
            dispatch({
                type: REQUEST_GET_REPORT_YEAR_ALL_EVENTS_SUCCESS,
                payload: all.data['hydra:member']
            })
            dispatch({
                type: REQUEST_GET_REPORT_YEAR_EVENTS_VALIDATED_SUCCESS,
                payload: valid.data['hydra:member']
            })
            dispatch({
                type: REQUEST_GET_REPORT_YEAR_EVENTS_NOT_VALIDATED_SUCCESS,
                payload: notValid.data['hydra:member']
            })
        } catch (err) {
            dispatch(requestReportsError(err.message));
        }
    }
}
