import React, {useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import useMediaQuery from "../../Hooks/useMediaQuery/useMediaQuery";
import useAuth from "../../Hooks/useAuth/useAuth";
import {Button, Menu, MenuItem, Typography} from "@mui/material";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {blueGrey} from "@mui/material/colors";

function UserMenu() {
    const {authIsLoggedIn, authFirstname, authLastname} = useAuth()
    const location = useLocation()
    const isMobile = useMediaQuery("(max-width: 780px)")
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpen, setIsOpen] = useState(false)
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setIsOpen(!isOpen)
    };
    const handleClose = () => {
        setAnchorEl(null);
        setIsOpen(!isOpen)
    };

    return (

        <div>
            {authIsLoggedIn ? (
                <>
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        endIcon={isOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        sx={{color: 'blueGrey.medium'}}
                    >
                        {isMobile ?
                            (
                                <Typography fontSize={'12px'}>{authFirstname.substring(0, 1).toUpperCase()}{authLastname.substring(0, 1).toUpperCase()}</Typography>)
                            : (<Typography fontSize={'12px'}>{authFirstname} {authLastname}</Typography>)
                        }
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handleClose}
                                  component={Link}
                                  to={"/logout"}
                        >
                            <LogoutOutlinedIcon fontSize={"small"} sx={{marginRight: '6px'}}/>
                            Déconnexion
                        </MenuItem>
                        <MenuItem onClick={handleClose}
                                  component={Link}
                                  to={"/auth-profile"}
                        >
                            <PermIdentityOutlinedIcon fontSize={"small"} sx={{marginRight: '6px'}}/>
                            Mon profile
                        </MenuItem>
                        {!location.pathname.includes('admin') && (
                            <MenuItem onClick={handleClose}
                                      component={Link}
                                      to={"/admin/customers"}
                            >
                                <AdminPanelSettingsOutlinedIcon fontSize={"small"} sx={{marginRight: '6px'}}/>
                                Espace Admin
                            </MenuItem>
                        )}
                    </Menu>
                </>
            ) : (
                <Button sx={{color: 'blueGrey.medium', paddingX: '6px'}} component={Link}
                        to={"/login"}><LoginOutlinedIcon fontSize={'small'} sx={{marginRight: {mw0: 0,mw780: '6px'}}}/>
                    <Typography fontSize={13} display={{mw0: 'none', mw780: 'block'}}>Connexion</Typography>
                </Button>
            )}

        </div>
    );
}

export default UserMenu;