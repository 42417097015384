import React from 'react';
import {Controller, useFormState} from "react-hook-form";

const FormField = ({
                       name,
                       label,
                       rules,
                       Component,
                       type = "text",
                       variant = "outlined",
                       size = "small",
                       InputProps = {},
                       inputProps = {},
                       ...restProps
                   }) => {
    const formState = useFormState()
    let nameArray = undefined
    if (name.indexOf('.') !== -1) {
        nameArray = name.split('.')
    }
    
    return (
        <Controller
            name={name}
            rules={rules}
            render={({field: {onChange, onBlur, value, ref}}) => (
                <Component
                    label={label}
                    variant={variant}
                    size={size}
                    type={type}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                    InputProps={InputProps}
                    inputProps={inputProps}

                    error={
                        nameArray ?
                            formState.errors[nameArray[0]]?.[nameArray[1]] ? true : false
                            : formState.errors[name] ? true : false
                    }
                    helperText={
                        nameArray ?
                            formState.errors[nameArray[0]] && formState.errors[nameArray[0]][nameArray[1]]?.message
                            : formState.errors[name] && formState.errors[name]?.message
                    }
                    {...restProps}
                />
            )}
        />
    );
};

export default FormField;
