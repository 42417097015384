import img3600 from "../../img/img-3-600.jpg";
import img4600 from "../../img/img-4-600.jpg";
import img5600 from "../../img/img-5-600.jpg";
import img6600 from "../../img/img-6-600.jpg";

const data = [
    {
        id: 1,
        img: img4600,
        title: "Aide à l'autonomie",
        content: ["Aide au lever et au coucher", "Aide à l'habillage" ,"Aide à la toilette et aux changes"],
        link: '/mes-services/aide-a-autonomie'
    },
    {
        id: 2,
        img: img3600,
        title: "Aide à l'entretien du domicile",
        content: ['Ménage', 'Entretien du linge'],
        link: '/mes-services/aide-a-entretien-du-domicile'
    },
    {
        id: 3,
        img: img5600,
        title: 'Aide aux repas',
        content: ["Aide aux courses", "Aide à la préparation des repas", "Aide à la prise des repas","Surveillance régime"],
        link: '/mes-services/aide-aux-repas'
    },
    {
        id: 4,
        img: img6600,
        title: 'Aide au bien être',
        content: ["Pédiluve, lavage des pieds", "Mise en beauté",],
        link: '/mes-services/aide-aux-bien-etre'
    },
    {
        id: 5,
        img: img6600,
        title: 'Lutte contre la solitude',
        content: ["Activités au domicile", "Promenade"],
        link: '/mes-services/compagnie'
    },
    {
        id: 6,
        img: img6600,
        title: 'Assistance administrative',
        content: ["Gestion du courrier", "Démarche administratives"],
        link: '/mes-services/assistance-administrative'
    },
    {
        id: 7,
        img: img6600,
        title: 'Prise en charge des enfants à domicile',
        content: ["Lever", "Toilette", "Repas", "Accompagnement aux devoirs", "Activités"],
        link: '/mes-services/prise-en-charge-des-enfants-a-domicile'
    }
]

export default data