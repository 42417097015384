import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import UserMenu from "../UserMenu/UserMenu";
import {AppBar, Box, IconButton, Menu, MenuItem, Stack, Tab, Tabs, Toolbar, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import MenuIcon from '@mui/icons-material/Menu';
const NavBar = (props) => {
    const navigation = useNavigate()
    const [indexItemMenu, setIndexItemMenu] = useState(0);
    const customersData = useSelector((state) => state.customersReducer)

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    useEffect(() => {
        setIndexItemMenu(props.indexItemMenu)
    }, [])

    return (
        <>
            <AppBar position="fixed" color={'white'} component={'div'}>
                <Toolbar>
                    <Box sx={{ flexGrow: 1, display: { mw0: 'flex', mw560: 'none' } }}>
                        <IconButton
                            size="large"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { mw0: 'block', mw560: 'none' },
                            }}
                        >
                            <MenuItem onClick={() => {navigation(`/admin/customers`)}} dense divider><Stack direction={'row'} spacing={1}><Typography fontSize={'14px'}>Clients</Typography></Stack></MenuItem>
                            <MenuItem disabled={customersData.customers.length === 0} onClick={() => {navigation(`/admin/calendar`)}} dense divider><Stack direction={'row'} spacing={1}><Typography fontSize={'14px'}>Planning</Typography></Stack></MenuItem>
                            <MenuItem disabled={customersData.customers.length === 0} onClick={() => {navigation(`/admin/financialReports`)}} dense divider><Stack direction={'row'} spacing={1}><Typography fontSize={'14px'}>Rapport Financier</Typography></Stack></MenuItem>
                        </Menu>
                    </Box>
                    <Tabs selectionFollowsFocus sx={{
                        "& button:hover": {transform: 'scale(1.15)'},
                        flexGrow: 1,
                        display: {mw0: 'flex', mw560: 'none'}
                    }}>
                        <Tab label={"Accueil"}  onClick={(event) => {
                            event.preventDefault()
                            navigation("/home")
                        }}/>
                    </Tabs>
                    <Tabs selectionFollowsFocus value={indexItemMenu} sx={{
                        "& button:hover": {transform: 'scale(1.15)'},
                        flexGrow: 1,
                        display: {mw0: 'none', mw560: 'flex'}
                    }}>
                        <Tab label={"Accueil"}  onClick={(event) => {
                            event.preventDefault()
                            navigation("/home")
                        }}/>
                        <Tab label={"Clients"} onClick={(event) => {
                            event.preventDefault();
                            navigation("/admin/customers")
                        }}/>
                        <Tab label={"Planning"} disabled={customersData.customers.length === 0} onClick={(event) => {
                            event.preventDefault();
                            navigation("/admin/calendar")
                        }}/>
                        <Tab label={"Rapport Financier"} disabled={customersData.customers.length === 0}
                             onClick={(event) => {
                                 event.preventDefault();
                                 navigation("/admin/financialReports")
                             }}/>
                    </Tabs>
                    <UserMenu/>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default NavBar;
