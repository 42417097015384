import InvoiceService from "../../services/invoice.service";
import {
    REQUEST_INVOICES_DELETE_SUCCESS,
    REQUEST_INVOICES_ERROR,
    REQUEST_INVOICES_POST_SUCCESS,
    REQUEST_INVOICES_STARTED,
    REQUEST_INVOICES_SUCCESS, SET_USER_ID
} from "./invoicesTypes";

export const generateInvoice = (invoice) => async (dispatch) => {
    try {
        dispatch(requestStarted());
        const response = await InvoiceService.postInvoice(invoice);
        dispatch(requestNewInvoiceSuccess(response.data));
    } catch (err) {
        dispatch(requestError(err.message));
    }
}

export const getInvoices = (userId) => async (dispatch) => {
    try {
        dispatch(requestStarted());
        const response = await InvoiceService.getInvoicesByUserId(userId);
        dispatch(setUserId(userId));
        dispatch(requestSuccess(response.data['hydra:member']));
    } catch (err) {
        dispatch(requestError(err.message));
    }
}

export const deleteInvoice = (id) => async (dispatch) => {
    try {
        dispatch(requestStarted());
        const response = await InvoiceService.deleteInvoice(id);
        dispatch(requestDeleteSuccess(response.data));
    } catch (err) {
        dispatch(requestError(err.message));
    }
}


function requestStarted() {
    return {
        type: REQUEST_INVOICES_STARTED,
    };
}

function requestSuccess(response) {
    return {
        type: REQUEST_INVOICES_SUCCESS,
        payload: response,
    };
}

function requestNewInvoiceSuccess(response) {
    return {
        type: REQUEST_INVOICES_POST_SUCCESS,
        payload: response,
    };
}

function requestError(error) {
    return {
        type: REQUEST_INVOICES_ERROR,
        payload: error,
    };
}
function requestDeleteSuccess(response) {
    return {
        type: REQUEST_INVOICES_DELETE_SUCCESS,
        payload: response,
    };
}

function setUserId(userId) {
    return {
        type: SET_USER_ID,
        payload: userId,
    };
}
