import {
    REQUEST_CUSTOMERS_STARTED,
    REQUEST_CUSTOMERS_SUCCESS,
    REQUEST_CUSTOMERS_PATCH_SUCCESS,
    REQUEST_ERROR,
    RESET_STATE_USERS,
    REQUEST_CUSTOMERS_PROFILE_PATCH_SUCCESS,
    REQUEST_CUSTOMERS_POST_SUCCESS,
    REQUEST_CUSTOMERS_ADDRESS_PATCH_SUCCESS,
    REQUEST_CUSTOMERS_ADDRESS_POST_SUCCESS,
    REQUEST_CUSTOMERS_ADDRESS_DELETE_SUCCESS, REQUEST_CUSTOMERS_ADDRESS_SET_DEFAULT_SUCCESS,
} from "./customersTypes";
import CustomersService from "../../services/customers.service";
import AddressService from "../../services/address.service"

export const resetStateUsers = () => {
    return {
        type: RESET_STATE_USERS,
    };
};

const requestCustomerStarted = () => {
    return {
        type: REQUEST_CUSTOMERS_STARTED,
    };
};

const requestCustomerError = (error) => {
    return {
        type: REQUEST_ERROR,
        payload: error,
    };
};

export const apiGetCustomer = () => {
    return async (dispatch) => {
        try {
            dispatch(requestCustomerStarted());
            const res = await CustomersService.getUser()
            //sort by alphabetical order
            res.data.customers.sort((a, b) => a.profile.lastname.localeCompare(b.profile.lastname))
            dispatch({
                type: REQUEST_CUSTOMERS_SUCCESS,
                payload: res.data.customers
            })
        } catch (err) {
            dispatch(requestCustomerError(err.message));
        }
    };
};

export const apiNewCustomerPost = (data, adminId) => {
    console.log(data)
    return async (dispatch) => {
        try {
            dispatch(requestCustomerStarted());
            const customer = await CustomersService.postUser({
                enabled: true,
                profile: data.profile,
                admin: `/api/users/${adminId}`,
            });
            data.address.profile = customer.data.profile['@id'];
            data.workAddress.profile = customer.data.profile['@id'];
            console.log(data)
            const addr = await AddressService.postAddress(data.address);
            const workAddr = await AddressService.postAddress(data.workAddress);
            customer.data.profile.addresses.push(addr.data);
            customer.data.profile.addresses.push(workAddr.data);
            dispatch({
                type: REQUEST_CUSTOMERS_POST_SUCCESS,
                payload: customer.data
            })
        } catch (err) {
            dispatch(requestCustomerError(err.message));
            console.log(err)
        }
    }
}

export const apiCustomerProfilePatch = (id, data) => {
    console.log(data)
    return async (dispatch) => {
        try {
            dispatch(requestCustomerStarted())
            const custRes = await CustomersService.patchProfileUser(id, data)
            dispatch({
                type: REQUEST_CUSTOMERS_PROFILE_PATCH_SUCCESS,
                payload: custRes.data
            })
        } catch (err) {
            dispatch(requestCustomerError(err.message));
        }
    };
};

export const apiCustomerAddressPatch = (id, data) => {
    return async (dispatch) => {
        try {
            dispatch(requestCustomerStarted())
            const addrRes = await AddressService.patchAddress(id, data)
            dispatch({
                type: REQUEST_CUSTOMERS_ADDRESS_PATCH_SUCCESS,
                payload: addrRes.data
            })
        } catch (err) {
            dispatch(requestCustomerError(err.message));
        }
    };
};

export const apiCustomerAddressPost = (data) => {
    return async (dispatch) => {
        try {
            dispatch(requestCustomerStarted())
            const addrRes = await AddressService.postAddress(data)
            dispatch({
                type: REQUEST_CUSTOMERS_ADDRESS_POST_SUCCESS,
                payload: addrRes.data
            })

        } catch (err) {
            dispatch(requestCustomerError(err.message));
        }
    }
}

export const apiCustomerAddressDelete = (id) => {
    return async (dispatch) => {
        try {
            dispatch(requestCustomerStarted())
            const deleteRes = await AddressService.deleteAddress(id)
            dispatch({
                type: REQUEST_CUSTOMERS_ADDRESS_DELETE_SUCCESS,
                payload: id
            })
        } catch (err) {
            dispatch(requestCustomerError(err.message));
        }
    }
}

export const apiCustomerEnabledPatch = (customerId, data) => {
    return async (dispatch) => {
        try {
            dispatch(requestCustomerStarted())
            const res = await CustomersService.patchUser(customerId, data)
            dispatch({
                type: REQUEST_CUSTOMERS_PATCH_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            dispatch(requestCustomerError(err.message));
        }
    };
};

export const setDefaultAddress = (addressId) => {
    return async (dispatch) => {
        try {
            dispatch(requestCustomerStarted())
            const res = await AddressService.setIsDefault(addressId)
            dispatch({
                type: REQUEST_CUSTOMERS_ADDRESS_SET_DEFAULT_SUCCESS,
                payload: res.data
            })
        } catch (err) {
            dispatch(requestCustomerError(err.message));
        }
    };
}

