import React from 'react';
import {Box, Grid} from "@mui/material";
import UserMenu from "../../../components/UserMenu/UserMenu";
import ButtonRdv from "../Components/ButtonRdv";

const NavBarHeader = () => {
    return (
        <Box component={'div'} sx={{
            display: 'flex',
            alignItems: 'center',
            pl: {mw0: '140px', mw780: '190px'},
            pr: '5px',
            height: '50px',
            backgroundColor: '#FFFFFF',
            borderBottom: '1px solid rgba(84, 110, 122, 0.2)'
        }}>
            <Grid container direction="row" alignItems={'center'}>
                <Grid item container mw0={10} mw780={9} mw1025={10} justifyContent="flex-end">
                    {/*<ButtonRdv/>*/}
                </Grid>
                <Grid item container mw0={2} mw780={3} mw1025={2} justifyContent="flex-end">
                    <UserMenu/>
                </Grid>
            </Grid>
        </Box>
    )
}

export default NavBarHeader