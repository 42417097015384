import React from 'react';
import {Button, Stack, TextField} from "@mui/material";
import FormField from "../../../../components/FormField/FormField";
import {FormProvider, useForm} from "react-hook-form";
import {apiCustomerProfilePatch} from "../../../../redux/customers/customersAction";
import {useDispatch} from "react-redux";

const FormMail = ({customer, handleEditMail}) => {
    const dispatch = useDispatch();
    const form = useForm({
        defaultValues: {
            email: customer.profile.email
        }
    })

    const formSubmit = (data, e) => {
        e.preventDefault()
        dispatch(apiCustomerProfilePatch(customer.profile.id, data));
        handleEditMail()
    }
    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(formSubmit)}>
                <Stack direction={'row'} justifyContent={'right'} marginBottom={1}>
                    <Button type={"submit"} size={'small'}>Enregistrer</Button>
                    <Button size={'small'} onClick={handleEditMail}>Annuler</Button>
                </Stack>
                <Stack direction={'row'}>
                    <FormField
                        Component={TextField}
                        name={"email"}
                        label={'email'}
                        rules={{
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Adresse Mail invalide",
                            }
                        }}
                        type={"email"}
                        sx={{
                            "& .MuiInputBase-root": {
                                fontSize: '13px',
                            },
                            '& .MuiFormLabel-root': {
                                fontSize: '13px',
                            },
                            width: '100%'
                        }}
                    />
                </Stack>
            </form>
        </FormProvider>
    );
};

export default FormMail;