import api from "./api";

class AddressService {
    async addAddress(address, user) {
        return await api.post("/addresses", {...address, user});
    }

    async postAddress(address) {
        return await api.post(`/addresses`, address)
    }

    async patchAddress(id, data) {
        return await api.patch(`/addresses/${id}`, data, {
            headers: {
                "Content-Type": "application/merge-patch+json",
            },
        });
    }

    async deleteAddress(id) {
        return await api.delete(`/addresses/${id}`)
    }

    setIsDefault = async (id) => {
        return await api.get(`/addresses/${id}/set_default`);
    }
}

export default new AddressService();
