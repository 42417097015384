import React, {useEffect} from 'react';
import TemplatePage from "../../Template/TemplatePage";
import Container from "./Container";
import Title from "./Title";
import H2 from "../../Components/Typographies/H2";
import H3 from "../../Components/Typographies/H3";
import {Box, Stack} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Paragraph from "./Paragraph";

const AssistanceHousekeepingPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
       <TemplatePage>
           <Container title={'Assistance à Domicile'}>
               <Title>
                   <H2 color={'pink.light'}>Aide à l'entretien du domicile</H2>
               </Title>
               <Title>
                   <H3 color={'blueGrey.dark'}>
                       <Stack direction={'row'} alignItems={'center'} component={'span'} lineHeight={'25px'}>
                           <FiberManualRecordIcon sx={{verticalAlign: 'middle', marginRight: 1, fontSize: '16px'}} />
                           Ménage
                       </Stack>
                   </H3>
               </Title>
               <Paragraph>
                   Je prends en compte les tâches à effectuer durant la prestation, dans le respect des consignes et des habitudes de la personne et en tenant compte de la configuration du logement.
               </Paragraph>
               <Paragraph>
                   J’entretient le mobilier et les équipements, dépoussière les sols par balayage ou aspiration, lave les sols et les vitres et désinfecte certaines surfaces.
               </Paragraph>
               <Paragraph>
                   Afin d’anticiper les risques d’accident, je veille à la sécurité de la personne et de sa famille pendant mon intervention. Je préserve ma propre sécurité en appliquant les gestes et les postures adaptés à mon activité.
               </Paragraph>
               <Paragraph>
                  J’intègre dans ma pratique les principes du développement durable.
               </Paragraph>
               <Paragraph>
                   J'organise mon travail de façon rationnelle en fonction des priorités et des imprévus. Mon rôle ne se limite pas qu' au ménage. J’installe  un dialogue et fais preuve de bienveillance avec la personne qui m'accompagne...
               </Paragraph>
               <Title>
                   <H3 color={'blueGrey.dark'}>
                       <Stack direction={'row'} alignItems={'center'} component={'span'} lineHeight={'25px'}>
                           <FiberManualRecordIcon sx={{verticalAlign: 'middle', marginRight: 1, fontSize: '16px'}} />
                           Entretien du linge
                       </Stack>
                   </H3>
               </Title>
               <Paragraph>
                   A partir des consignes, et afin de fournir une prestation selon la qualité attendue dans le temps imparti, j’organise les différentes tâches à accomplir en tenant compte des habitudes et des attentes de la personne aidée.
               </Paragraph>
               <Paragraph>
                   La personne aidée doit disposer du matériel nécessaire pour l’entretien du linge: un lave linge pour les lessives, un sèche linge ou étendoir à linge pour sécher le linge, un fer à repasser et de l’eau déminéralisée pour le repassage.
               </Paragraph>
               <Paragraph>
                   <Box component={'span'} color={'blueGrey.medium'}>Laver le linge :<br/></Box>
                   Je détache les habits avant de les laver. Je lave et fait sécher le linge en utilisant les matériels et les produits adéquats, conformément aux modes d’ emploi et en fonction du type de textile. Je change les draps et les linges de maison régulièrement.
               </Paragraph>
               <Paragraph>
                   <Box component={'span'} color={'blueGrey.medium'}>Repassage :<br/></Box>
                   Je repasse et plie le linge selon les habitudes de la personne aidée . Je range les vêtements dans les endroits appropriés.
               </Paragraph>
               <Paragraph>
                   <Box component={'span'} color={'blueGrey.medium'}>Travaux de couture simple :<br/></Box>
                   Je repère les travaux de couture simples nécessaires( recoudre un bouton par exemple), je le signale et je l’ effectue.
               </Paragraph>
               <Title>
                   <H3 color={'blueGrey.dark'}>
                       <Stack direction={'row'} alignItems={'center'} component={'span'} lineHeight={'25px'}>
                           <FiberManualRecordIcon sx={{verticalAlign: 'middle', marginRight: 1, fontSize: '16px'}} />
                           Matériel
                       </Stack>
                   </H3>
               </Title>
               <Paragraph>
                   J’utilise les matériels disponibles au domicile de la personne (je vérifie la conformité des appareils) et je respecte leur mode d’ emploi. Je me sert des produits d’entretien conformément aux prescriptions d’utilisation.<br/>
                   J’informe la personne aidée en cas de besoin de réapprovisionnement de produits ou de défaillance technique du matériel.
               </Paragraph>
           </Container>
       </TemplatePage>
    )
}

export default AssistanceHousekeepingPage