import React from "react";
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Logout from "./pages/Login/Logout";
import {useSelector} from "react-redux";
import "./style/App.scss";
import Calendar from "./pages/Admin/Calendar/Calendar";
import CustomerPage from "./pages/Admin/Customers/CustomerPage";
import CardCustomerPage from "./pages/Admin/Customers/CardCustomerPage";
import AuthProfile from "./pages/AuthProfile/AuthProfile";
import FinancialReportsPage from "./pages/Admin/FinancialReports/FinancialReportsPage";
import AssistanceAutonomyPage from "./pages/Home/Main/MyServices/AssistanceAutonomyPage";
import AssistanceHousekeepingPage from "./pages/Home/Main/MyServices/AssistanceHousekeepingPage";
import AssistanceEatingPage from "./pages/Home/Main/MyServices/AssistanceEatingPage";
import AssistanceWellBeingPage from "./pages/Home/Main/MyServices/AssistanceWellBeingPage";
import PeopleCompagnyPage from "./pages/Home/Main/MyServices/PeopleCompagnyPage";
import AssistanceAdministrativePage from "./pages/Home/Main/MyServices/AssistanceAdministrativePage";
import AssistanceChildrenPage from "./pages/Home/Main/MyServices/AssistanceChildrenPage";
import Contact from "./pages/Home/Contact/Contact";
import PrivacyPolicy from "./pages/Home/PrivacyPolicy/PrivacyPolicy";
import ActivitiesCustomerPage from "./pages/Admin/Customers/ActivitiesCustomerPage";
import NewCustomerPage from "./pages/Admin/Customers/NewCustomerPage";


const App = () => {
    const auth = useSelector((state) => state.auth);

    return (
        <>
            <Routes>
                <Route exat path="/" element={<Home/>}/>
                <Route exat path="/page-accueil" element={<Home/>}/>
                <Route exact path={"/contact"} element={<Contact/>}/>
                <Route exact path={"/mentions-legales"} element={<PrivacyPolicy/>} />
                <Route exact path="/mes-services/aide-a-autonomie" element={<AssistanceAutonomyPage/>}/>
                <Route exact path="/mes-services/aide-a-entretien-du-domicile" element={<AssistanceHousekeepingPage/>}/>
                <Route exact path="/mes-services/aide-aux-repas" element={<AssistanceEatingPage/>}/>
                <Route exact path="/mes-services/aide-aux-bien-etre" element={<AssistanceWellBeingPage/>}/>
                <Route exact path="/mes-services/compagnie" element={<PeopleCompagnyPage/>}/>
                <Route exact path="/mes-services/assistance-administrative" element={<AssistanceAdministrativePage/>}/>
                <Route exact path="/mes-services/prise-en-charge-des-enfants-a-domicile"
                       element={<AssistanceChildrenPage/>}/>
                <Route exact path="/login" element={<Login/>}/>
                <Route exact path={"/logout"} element={<Logout/>}/>
                <Route path={"/*"} element={<Home/>}/>
                {auth.isLoggedIn && (
                    <>
                        <Route exact path={"/auth-profile"} element={<AuthProfile/>}/>
                        <Route exact path={"/admin"} element={<CustomerPage/>}/>
                        <Route exact path={"/admin/calendar"} element={<Calendar/>}/>
                        <Route exact path={"/admin/customers"} element={<CustomerPage/>}/>
                        <Route exact path={"/admin/customers/new"} element={<NewCustomerPage/>}/>
                        <Route exact path={"/admin/customers/:id"} element={<CardCustomerPage/>}/>
                        <Route exact path={"/admin/customers/activities/:id"} element={<ActivitiesCustomerPage/>}/>
                        <Route exact path={"/admin/financialReports"} element={<FinancialReportsPage/>}/>
                    </>
                )}
                {/*<Route path={"/test"} element={<DialogTest/>}/>*/}
            </Routes>
        </>
    );
};

export default App;
