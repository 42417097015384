import React, {forwardRef, useEffect, useState} from "react";
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Typography} from "@mui/material";
import FormField from "../FormField/FormField";


const CheckboxGroup = forwardRef((props, ref) => (
    <FormField ref={ref} Component={CheckboxGroupImpl} {...props} />
));

const CheckboxGroupImpl = forwardRef(({
            label,
            name = "",
            variant,
            size,
            value,
            onChange,
            error,
            helperText,
            items = [],
            nameField = "name",
            valueField = "value",
            row = true,
            sxLabel,
            sxGroup,
            sxCheckBox
        },
        ref
    ) => {
        const [checkedItems, setCheckedItems] = useState({});
        useEffect(() => {
            let newState = {};
            if (value) {
                value.map((item) => (newState[item] = true));
                setCheckedItems(newState);
            }
        }, [value]);

        const handleClick = (e) => {
            let newState = { ...checkedItems };

            if (e.target.checked) {
                newState[e.target.value] = true;
            } else {
                delete newState[e.target.value];
            }
            setCheckedItems(newState);

            const items = Object.keys(newState);
            if (onChange) onChange(items.length === 0 ? null : items);
        };
        return (
            <FormControl variant={variant} size={size} error={error} sx={sxGroup} >
                <FormLabel sx={sxLabel}>{label}</FormLabel>
                <FormGroup row={row}>
                    {items.map((item) => (
                        <FormControlLabel
                            key={item[valueField]}
                            value={item[valueField]}
                            label={<Typography sx={sxCheckBox}>{item[nameField]}</Typography>}
                            control={
                                <Checkbox
                                    size={size}
                                    name={name}
                                    value={item[valueField]}
                                    onClick={handleClick}
                                />
                            }
                        />
                    ))}
                </FormGroup>
                <FormHelperText>{helperText}</FormHelperText>
            </FormControl>
        );
    }
);

export default CheckboxGroup;