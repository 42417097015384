import React from 'react';
import {Box} from "@mui/material";

const BannerLinks = ({children}) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            letterSpacing: '0.02em',
            px: '10px',
            pt: '10px',
            position: 'relative',
            zIndex: 10,
            width: {mw0: 'unset', mw780: '50%'}
        }}>
            {children}
        </Box>
    )
}

export default BannerLinks