import React from 'react';
import img1500 from "../img/img-1-500.png";
import {Box} from "@mui/material";

const ImgLotus = () => {
    return (
        <Box component={'img'} src={img1500} alt="lotus pourpre" sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'block',
            width: {mw0: 150, mw780: 200},
            zIndex: 1,
        }}/>
    )
}

export default ImgLotus