import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Grid, Stack,
    TextField,
    Typography
} from "@mui/material";
import FormField from "../../../components/FormField/FormField";
import {FormProvider, useForm} from "react-hook-form";
import CheckboxGroup from "../../../components/CheckboxGroup/CheckboxGroup";
import TemplatePage from "../Template/TemplatePage";
import Container from "../Template/Container";

const Contact = () => {
    const form = useForm({
        defaultValues: {
            phoneNumber: "",
            email: "",
            firstname: "",
            lastname: "",
            message: "",
        }
    })
    const [typeNeed, setTypeNeed ] = useState()
    const onSubmit = (data, e) => {
        e.preventDefault()
        console.log(data)
    };

    const selectChange = (e) => {
        setTypeNeed(e.target.value)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <TemplatePage>
            <Container maxWidth={"1000px"}>
                <Box component={"div"} sx={{paddingY: 3, paddingX: 4, marginBottom: 4}}>
                    <Box sx={{marginBottom: 3}}>
                        <Typography align="justify" component="p">
                            {'Si vous le souhaitez, vous pouvez me contacter via le formulaire ci-dessous en précisant l\'objet de votre demande.'}
                        </Typography>
                    </Box>

                    <FormProvider {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)}>
                            <Grid container spacing={4}>
                                <Grid item mw0={12} mw780={5}>
                                    <Stack spacing={3}>
                                        <FormField
                                            Component={TextField}
                                            name={"lastname"}
                                            label={'Nom'}
                                            rules={{required: "Veuillez renseigner votre nom"}}
                                            size={"Normal"}
                                        />
                                        <FormField
                                            Component={TextField}
                                            name={"firstname"}
                                            label={'Prénom'}
                                            rules={{required: "Veuillez renseigner votre prénom"}}
                                            size={"Normal"}
                                        />
                                        <FormField
                                            Component={TextField}
                                            name={"email"}
                                            label={'email'}
                                            rules={{
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "Adresse Mail invalide",
                                                }
                                            }}
                                            type={"email"}
                                            size={"Normal"}
                                        />
                                        <FormField
                                            Component={TextField}
                                            name={"phoneNumber"}
                                            label={'Télephone'}
                                            rules={{
                                                pattern: {
                                                    value: /^[0-9]{10}$/i,
                                                    message: "Veuillez saisir un numéro de téléphone valide"
                                                },
                                                required: "Veuillez renseigner votre numéro de téléphone"
                                            }}
                                            inputProps={{maxLength: 10}}
                                            type={"tel"}
                                            size={"Normal"}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item mw0={12} mw780={7}>
                                    <Stack spacing={3}>
                                        <CheckboxGroup
                                            name="typeNeed"
                                            rules={{ required: "Veuillez préciser votre besoin" }}
                                            label={"Quel est votre besoin ?"}
                                            row={false}
                                            sxLabel={{fontSize: "17px"}}
                                            sxGroup={{border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '4px' , padding: 2}}
                                            items={[
                                                { name: "Entretien du domicile", value: "1" },
                                                { name: "Aide à la personne", value: "2" },
                                                { name: "Prise en charge des enfants à domicile", value: "3" },
                                                { name: "Autre", value: "0" },
                                            ]}
                                        />
                                        <FormField
                                            Component={TextField}
                                            multiline
                                            rows={5}
                                            name={"message"}
                                            label={'Message'}
                                            rules={{required: "Veuillez détailler votre demande"}}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item mw0={12}>
                                    <CheckboxGroup
                                        name="acceptTerms"
                                        rules={{ required: "Required!" }}
                                        items={[
                                            { name: "En cochant cette case, j’accepte que les données saisies dans le formulaire ci-dessus soient utilisées pour me recontacter dans le cadre de ma demande, conformément à notre politique de confidentialité.", value: "acceptTerms" },
                                        ]}
                                        sxCheckBox={{fontSize: {mw0: '13px', mw780: '16px'}}}
                                    />
                                </Grid>
                                <Grid item mw0={12}>
                                    <Stack direction={"row"} justifyContent={"center"}>
                                        <Button type={"submit"} variant="dark" color="purple">Envoyer</Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </form>
                    </FormProvider>
                </Box>
            </Container>
        </TemplatePage>
    )
}

export default Contact