import api from "./api";

class InvoiceService {
    async getInvoices() {
        return await api.get("/invoices");
    }

    async getInvoice(id) {
        return await api.get(`/invoices/${id}`);
    }

    async getInvoicesByUserId(userId) {
        return await api.get(`/invoices?user=${userId}`);
    }

    async postInvoice(invoice) {
        return await api.post(`/invoices`, invoice)
    }

    async deleteInvoice(id) {
        return await api.delete(`/invoices/${id}`)
    }

}

export default new InvoiceService();
