import React from 'react';
import {Alert, AlertTitle, Box, Stack} from "@mui/material";

const LoadingError = ({error, txt}) => {
    return (
        <Box sx={{m: 2, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Alert severity="error">
                <AlertTitle>Erreur : {txt}</AlertTitle>
                <strong>{error}</strong>
            </Alert>
        </Box>
    );
};

export default LoadingError;
