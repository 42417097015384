import React from 'react';
import {Button, Stack, TextField} from "@mui/material";
import FormField from "../../../../components/FormField/FormField";
import {FormProvider, useForm} from "react-hook-form";
import {apiCustomerAddressPost} from "../../../../redux/customers/customersAction";
import {useDispatch} from "react-redux";

const FormAddressAdd = ({idProfile, toggle}) => {
    const dispatch = useDispatch();
    const form = useForm({
        defaultValues: {
            address: '',
            addressComplement: '',
            city: '',
            country: '',
            zip: '',
        }
    })

    const formSubmit = (data, e) => {
        e.preventDefault()
        data.profile = `api/profiles/${idProfile}`
        data.isPrincipal = false
        data.isDefault = false
        dispatch(apiCustomerAddressPost(data));
        toggle()
    }
    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(formSubmit)}>
                <Stack direction={'row'} justifyContent={'right'}>
                    <Button type={"submit"} size={'small'}>Enregistrer</Button>
                </Stack>
                <Stack rowGap={2}>
                    <FormField
                        Component={TextField}
                        name={"address"}
                        label={'Adresse'}
                        rules={{required: "Veuillez renseigner l'adresse"}}
                        sx={{
                            "& .MuiInputBase-root": {
                                fontSize: '13px',
                            },
                            '& .MuiFormLabel-root': {
                                fontSize: '13px',
                            },
                        }}
                    />
                    <FormField
                        Component={TextField}
                        name={"addressComplement"}
                        label={"Complément d'adresse"}
                        sx={{
                            "& .MuiInputBase-root": {
                                fontSize: '13px',
                            },
                            '& .MuiFormLabel-root': {
                                fontSize: '13px',
                            },
                        }}
                    />

                    <Stack direction={'row'}>
                        <FormField
                            Component={TextField}
                            name={"zip"}
                            label={'Code postal'}
                            rules={{
                                pattern: {
                                    value: /^[0-9]{5}$/i,
                                    message: "Veuillez saisir un code postal valide",
                                },
                                required: "Veuillez renseigner le code postal",
                            }}
                            inputProps={{maxLength: 5}}
                            sx={{
                                "& .MuiInputBase-root": {
                                    fontSize: '13px',
                                },
                                '& .MuiFormLabel-root': {
                                    fontSize: '13px',
                                },
                                width: '50%'
                            }}
                        />
                        <FormField
                            Component={TextField}
                            name={"city"}
                            label={'Ville'}
                            rules={{required: "Veuillez renseigner la ville"}}
                            sx={{
                                "& .MuiInputBase-root": {
                                    fontSize: '13px',
                                },
                                '& .MuiFormLabel-root': {
                                    fontSize: '13px',
                                },
                                width: '50%'
                            }}
                        />
                    </Stack>
                    <FormField
                        Component={TextField}
                        name={"country"}
                        label={"Pays"}
                        sx={{
                            "& .MuiInputBase-root": {
                                fontSize: '13px',
                            },
                            '& .MuiFormLabel-root': {
                                fontSize: '13px',
                            },
                        }}
                    />
                </Stack>
            </form>
        </FormProvider>
    );
};

export default FormAddressAdd;