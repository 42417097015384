import React, {useEffect} from "react";
import MyServices from "./Main/MyServices/MyServices";
import MyCommitments from "./Main/MyCommitments/MyCommitments";
import TemplatePage from "./Template/TemplatePage";

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <TemplatePage>
                <MyServices/>
                <MyCommitments/>
        </TemplatePage>
    );
};

export default Home;
