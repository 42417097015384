import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {generateInvoice, getInvoices} from "../../../../redux/invoices/invoicesActions";
import Loading from "../../../../components/Loading/Loading";
import LoadingError from "../../../../components/Loading/LoadingError";
import {Button, Grid, Paper, Typography} from "@mui/material";

const InvoicesComponent = (props) => {
    const invoices = useSelector((state) => state.invoicesReducer.invoices);
    const isLoading = useSelector((state) => state.invoicesReducer.loading);
    const error = useSelector((state) => state.invoicesReducer.error);
    const dispatch = useDispatch();
    const {year, month, customerId} = props;

    const [currentInvoice, setCurrentInvoice] = useState(year);

    useEffect(() => {
        if(invoices.userId === null || invoices.userId !== customerId){
            dispatch(getInvoices(customerId));
        }
    }, []);

    useEffect(() => {
        setCurrentInvoice(invoices.filter(invoice => invoice.year === year && invoice.month === month)[0]);
    }, [year, month, invoices]);

    const generatePDF = () => {
        dispatch(generateInvoice({year: year, month: month, user: '/api/users/'+customerId}));
    }

    const onDownload = () => {
        const link = document.createElement("a");
        //get from currentInvoice
        link.download = `download.pdf`
        link.href = "https://manager.thrallweb.fr/pdf/"+currentInvoice.filename;
        link.target = "_blank";
        link.click();
    };

    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : error ? (
                <LoadingError error={error} txt={"Facture"}/>
            ) : (
                <>
                    <Typography variant={"cardTitle"}>Facture</Typography>
                    <Paper sx={{padding: 1, width: '100%'}}>
                        <Grid container direction={'row'} justifyContent={'center'}>
                            <Grid item mw0={6} mw780={5} mw1025={4}>
                                <Button color={'secondary'} size={'small'} onClick={generatePDF}>Générer Facture</Button>
                            </Grid>
                            <Grid item mw0={6} mw780={5} mw1025={4}>
                                {
                                    currentInvoice ? (
                                        <Button color={'secondary'} size={'small'} onClick={onDownload}>Télécharger facture</Button>
                                    ) : (
                                        <Typography color={"error"}>Aucune facture pour cette période</Typography>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                </>
            )
            }
        </>
    );
}

export default InvoicesComponent;
