import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    apiGetEvents,
} from "../../../../redux/reports/reportsAction";
import useAuth from "../../../../Hooks/useAuth/useAuth";
import useDateTime from "../../../../Hooks/useDateTime/useTime";
import useMoney from "../../../../Hooks/useMoney/useMoney";
import Loading from "../../../../components/Loading/Loading";
import LoadingError from "../../../../components/Loading/LoadingError";
import {
    Alert, AlertTitle,
    Box, Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import PropTypes from "prop-types";
import customersService from "../../../../services/customers.service";

const FinancialReportComponent = (props) => {
    const year = props.year
    const month = props.month
    const customerId = props.customerId
    const dispatch = useDispatch();
    const {authId} = useAuth();
    const {decimalTimeToHours, toMonthName} = useDateTime()
    const {decimalToEuro} = useMoney()
    const report = useSelector((state) => state.reportsReducer)
    const [financialReport, setFinancialReport] = useState([])
    const [customerReport, setCustomerReport] = useState({
        reportsEventsValidated: [],
        reportsEventsNotValidated: [],
        reportsAllEvents: [],
        isLoading: false
    })

    const filterByMonth = (report, month) => {
        if (report !== undefined) {
            let resultFilter = report.filter(el => {
                return parseInt(el.month) === month
            })
            return resultFilter
        }

    }

    const dataCompiled = (all, validated, notValidated) => {

        const dataCompiled = []
        all !== undefined && all.map(allData => {
            const month = allData.month
            const durationTotal = decimalTimeToHours(allData.duration)
            const priceTotal = decimalToEuro(allData.total)
            let validatedDuration = undefined
            let validatedPrice = undefined
            let notValidatedDuration = undefined
            let notValidatedPrice = undefined


            validated !== undefined && validated.map(validatedData => {
                if (validatedData.month === month) {
                    validatedDuration = decimalTimeToHours(validatedData.duration)
                    validatedPrice = decimalToEuro(validatedData.total)
                }
            })

            notValidated !== undefined && notValidated.map(notValidatedData => {
                if (notValidatedData.month === month) {
                    notValidatedDuration = decimalTimeToHours(notValidatedData.duration)
                    notValidatedPrice = decimalToEuro(notValidatedData.total)
                }
            })
            const reportLine = {
                month: toMonthName(month),
                durationTotal: durationTotal,
                priceTotal: priceTotal,
                validatedDuration: validatedDuration,
                validatedPrice: validatedPrice,
                notValidatedDuration: notValidatedDuration,
                notValidatedPrice: notValidatedPrice
            }

            dataCompiled.push(reportLine)
        })
        return dataCompiled
    }

    const getReport = async () => {
        setCustomerReport({
            reportsEventsValidated: (await customersService.getUserReport(year, customerId, true))['hydra:member'],
            reportsEventsNotValidated: (await customersService.getUserReport(year, customerId, false))['hydra:member'],
            reportsAllEvents: (await customersService.getUserReport(year, customerId))['hydra:member'],
            isLoading: false
        })
    }

    useEffect(() => {
        if (customerId) {
            setCustomerReport({...customerReport, isLoading: true})
            getReport()
        } else {
            dispatch(apiGetEvents(authId, year))
        }
    }, [year])

    useEffect(() => {
        if (customerId) {
            if (month) {
                setFinancialReport(dataCompiled(filterByMonth(customerReport.reportsAllEvents, month), filterByMonth(customerReport.reportsEventsValidated, month), filterByMonth(customerReport.reportsEventsNotValidated, month)))
            } else (
                setFinancialReport(dataCompiled(customerReport.reportsAllEvents, customerReport.reportsEventsValidated, customerReport.reportsEventsNotValidated))
            )
        } else {
            if (month) {
                setFinancialReport(dataCompiled(filterByMonth(report.reportsAllEvents, month), filterByMonth(report.reportsEventsValidated, month), filterByMonth(report.reportsEventsNotValidated, month)))
            } else (
                setFinancialReport(dataCompiled(report.reportsAllEvents, report.reportsEventsValidated, report.reportsEventsNotValidated))
            )
        }
    }, [report.reportsAllEvents, month, customerReport])

    return (
        <>
            {report.isLoading || customerReport.isLoading ? (
                <Loading/>
            ) : report.error ? (
                <LoadingError error={report.error} txt={"Section Rapport financier"}/>
            ) : (!customerId && report.reportsAllEvents.length > 0) || (customerId && customerReport.reportsAllEvents.length > 0) ? (
                <>
                    <Grid item mw0={12}>
                        <Typography component={'div'} variant={"cardTitle"}>Rapport financier</Typography>
                    </Grid>
                        {financialReport.length !== 0 && (
                            <>
                                {financialReport.map(report => {
                                    return (
                                        <Grid item>
                                            <Paper sx={{width: '100%'}}>
                                                <TableContainer>
                                                    <Table size={"small"}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell><Typography color={'purple.dark'}>{report.month}</Typography></TableCell>
                                                                <TableCell><Typography variant={'tableHead'}>Nombre d'heure</Typography></TableCell>
                                                                <TableCell><Typography variant={'tableHead'}>Montant</Typography></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>

                                                            <TableRow>
                                                                <TableCell><Typography variant={'tableHead'}>Activités validées</Typography></TableCell>
                                                                <TableCell><Typography variant={'tableRows'}>{report.validatedDuration !== undefined ? report.validatedDuration : ""}</Typography></TableCell>
                                                                <TableCell><Typography variant={'tableRows'}>{report.validatedPrice !== undefined ? report.validatedPrice : ""}</Typography></TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell><Typography variant={'tableHead'}>Activités à valider</Typography></TableCell>
                                                                <TableCell><Typography variant={'tableRows'}>{report.notValidatedDuration !== undefined ? report.notValidatedDuration : "-"}</Typography></TableCell>
                                                                <TableCell><Typography variant={'tableRows'}>{report.notValidatedPrice !== undefined ? report.notValidatedPrice : "-"}</Typography></TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell><Typography variant={'tableHead'}>Total</Typography></TableCell>
                                                                <TableCell><Typography variant={'tableRows'}>{report.durationTotal !== undefined ? report.durationTotal : "-"}</Typography></TableCell>
                                                                <TableCell><Typography variant={'tableRows'}>{report.priceTotal !== undefined ? report.priceTotal : "-"}</Typography></TableCell>
                                                            </TableRow>

                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                        </Grid>

                                    )
                                })}
                            </>
                        )}
                </>
            ) : (!customerId && report.reportsAllEvents.length === 0) || (customerId && customerReport.reportsAllEvents.length === 0) && (
                <div>
                    <Typography variant={"cardTitle"}>Rapport financier</Typography>
                    <Box sx={{display: 'flex', justifyContent: 'start'}}>
                        <Alert severity="info">
                            <AlertTitle>Pas d'activités en {year}</AlertTitle>
                        </Alert>
                    </Box>
                </div>
            )}
        </>
    );
};

FinancialReportComponent.propTypes = {
    year: PropTypes.number.isRequired,
    month: PropTypes.number,
    customerId: PropTypes.string
}

export default FinancialReportComponent;