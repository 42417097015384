import React from 'react';
import MyServicesCard from "./MyServicesCard";
import contentCards from './MyServicesData'
import {Box, Grid, Stack} from "@mui/material";
import H1 from "../../Components/Typographies/H1";

function MyServices() {
    return (
        <Box component={"section"} sx={{paddingY: 3, paddingX: 4, marginBottom: 4}}>
            <Stack direction={'row'} sx={{marginBottom: 5, paddingBottom: 1 ,borderBottom: '1px solid',borderColor: 'purple.light'}}>
                <H1 color={'pink.light'}>Assistance à Domicile</H1>
            </Stack>
            <Grid container spacing={2} alignItems={'stretch'} justifyContent={'center'}
            >
                {contentCards.map(data => <MyServicesCard key={data.id} data={data}/>)}
            </Grid>
        </Box>
    );
}

export default MyServices;